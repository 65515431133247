import { Injectable } from '@angular/core';
import {PlanifSlicerService} from "@app/ui/screens/planif/planif-slicer.service";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";
import {SelectTibiaService} from "@app/shared/component/select-tibia/select-tibia.service";
import {MathUtils} from "three";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";

/**
 * Service that contains methods to manipulate (rotation and translation) the tibia in the 3 views
 */
@Injectable({
  providedIn: 'root'
})
export class TibiaAdjustmentService {

  constructor(private planifSlicerService: PlanifSlicerService, public tibiaMortaiseService: TibiaMortaiseService,
              private selectTibiaService: SelectTibiaService, private planifViewManagerService: PlanifViewManagerService,
              private planifViewReferencesService: PlanifViewReferencesService) { }

  /**
   * method that returns the value of internalExternalRotation
   */
  getRotationInternalExternal() {
    let angle = this.tibiaMortaiseService.internalExternalRotation
    if (this.selectTibiaService.getAxe().value=="akagi") {
      angle+= this.selectTibiaService.getRef().value=="akagi" ? 0 : Math.round(this.tibiaMortaiseService.angleAkagiPosterior*(-this.tibiaMortaiseService.sideModifier))
      return angle;
    }
    else { //"plateau_posterior"
      const akagiAxis = this.planifViewReferencesService.getAkagiAxis()
      const posteriorAxis = this.planifViewReferencesService.getPosteriorPLateAxis()
      angle += Math.round(MathUtils.radToDeg(akagiAxis.angleTo(posteriorAxis)))
      angle+= this.selectTibiaService.getRef().value=="akagi" ? 0 : Math.round(this.tibiaMortaiseService.angleAkagiPosterior*(-this.tibiaMortaiseService.sideModifier))
      return angle;
    }
  }

  /**
   * method that returns the absolute value of internalExternalRotation
   */
  getAbsoluteRotationInternalExternal() {
    return Math.abs(this.getRotationInternalExternal())
  }

  /**
   * method that decrease the value of internalExternalRotation by 1
   */
  minusRotationInternalExternal() {
    this.tibiaMortaiseService.internalExternalRotation-= (this.tibiaMortaiseService.sideModifier);
    this.planifSlicerService.updateTibiaCut()
  }

  /**
   * method that increase the value of internalExternalRotation by 1
   */
  plusRotationInternalExternal() {
    this.tibiaMortaiseService.internalExternalRotation+= (this.tibiaMortaiseService.sideModifier);
    this.planifSlicerService.updateTibiaCut()
  }

  /**
   * method that return the value of varusValgus
   */
  getVarusValgus() {
    return this.tibiaMortaiseService.varusValgus;
  }

  /**
   * method that return the absolute value of varusValgus
   */
  getAbsoluteVarusValgus() {
    return Math.abs(this.getVarusValgus());
  }


  /**
   * method that decrease the value of varusValgus by 1, can not go under -5°
   */
  minusVarusValgus() {
    if (this.tibiaMortaiseService.varusValgus*(this.tibiaMortaiseService.sideModifier)>-5) {
      this.tibiaMortaiseService.varusValgus -= (this.tibiaMortaiseService.sideModifier);
    }
    this.planifSlicerService.updateTibiaCut()
  }

  /**
   * method that increase the value of varusValgus by 1, can not go above 5°
   */
  plusVarusValgus() {
    if (this.tibiaMortaiseService.varusValgus*(this.tibiaMortaiseService.sideModifier)<5) {
      this.tibiaMortaiseService.varusValgus += (this.tibiaMortaiseService.sideModifier);
    }
    this.planifSlicerService.updateTibiaCut()
  }

  /**
   * method that return the value of proximalResection
   */
  getResectionTibial() {
    return this.tibiaMortaiseService.tibialResection;
  }

  /**
   * method that decrease the value of proximalResection by 1
   */
  minusResectionTibial() {
    this.tibiaMortaiseService.tibialResection -= 1;
    this.planifSlicerService.updateTibiaCut()
  }

  /**
   * method that increase the value of proximalResection by 1
   */
  plusResectionTibial() {
    this.tibiaMortaiseService.tibialResection += 1;
    this.planifSlicerService.updateTibiaCut()
  }

  /**
   * method that return the value of tibialSlope
   */
  getTibialSlope() {
    return this.tibiaMortaiseService.tibialSlope;
  }

  /**
   * method that return the absolute value of tibialSlope
   */
  getAbsoluteTibialSlope() {
    return Math.abs(this.getTibialSlope());
  }

  /**
   * method that decrease the value of tibialSlope by 1
   */
  minusTibialSlope() {
    if (this.tibiaMortaiseService.tibialSlope>0) {
      this.tibiaMortaiseService.tibialSlope -= 1;
    }
    this.planifSlicerService.updateTibiaCut()
  }

  /**
   * method that increase the value of tibialSlope by 1
   */
  plusTibialSlope() {
    if (this.tibiaMortaiseService.tibialSlope<12) {
      this.tibiaMortaiseService.tibialSlope += 1;
    }
    this.planifSlicerService.updateTibiaCut()
  }
}

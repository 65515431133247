import { Component, OnInit } from '@angular/core';
import { TibiaAdjustmentService } from './tibia-adjustment.service';
import { ViewsChoiceService } from '../../../services/planif/views-choice.service';
import { GlobalService } from '../../../services/global/global.service';

/**
 * Component used on the planification page to apply rotations and transitions to tibial view
 */

@Component({
  selector: 'app-tibia-adjustment',
  templateUrl: './tibia-adjustment.component.html',
  styleUrls: ['./tibia-adjustment.component.scss']
})
export class TibiaAdjustmentComponent implements OnInit {

  constructor( public tibiaAdjustmentService: TibiaAdjustmentService, public viewsChoiceService: ViewsChoiceService,
               public globalService: GlobalService ) {
  }

  ngOnInit(): void {
  }

}

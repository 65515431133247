import {Injectable} from '@angular/core';
import {Bone} from '../class/bone';
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {ManagerService} from "@app/services/three/manager.service";
import {MaterialService} from "@app/services/three/material.service";
import * as THREE from "three";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";

@Injectable({
  providedIn: 'root'
})

/**
 * Service used to load the tibia bone
 */
export class TibiaService extends Bone {

  private mBaseTibiaToWorldBase: THREE.Matrix3

  constructor(dataSymfonyService: DataSymfonyService, manager: ManagerService,
              public materialService: MaterialService, private planifViewReferencesService: PlanifViewReferencesService) {
    super(manager, dataSymfonyService);
    this.name = 'tibia';
    this.url = `${location.origin}/${this.dir}/${this.name}${this.addSimplifiee}.obj`;
    this.material = this.materialService.createBoneMaterial()
  }

  /**
   * Set the transition matrix between Tibia reference to World reference, the World reference is the femur reference
   * @param {THREE.Scene} scene The current scene
   */
  setMatrixTibiaToWorld(scene) {
    const tibiaAxeZ = this.planifViewReferencesService.getTibiaMecaAxis(scene)
    const m = new THREE.Matrix3()
    m.set(tibiaAxeZ.z, tibiaAxeZ.x, tibiaAxeZ.y,
      tibiaAxeZ.y, tibiaAxeZ.z, tibiaAxeZ.x,
      tibiaAxeZ.x, tibiaAxeZ.y, tibiaAxeZ.z)
    this.mBaseTibiaToWorldBase = m
  }

  /**
   * Get the transition matrix between Tibia reference to World reference
   * @return {THREE.Matrix3} The transition matrix
   */
  getTibiaMatrix() {
    return this.mBaseTibiaToWorldBase
  }

  /**
   * This method returns a measure along the Z axis of the tibia reference (mechanical axis)
   * @param {THREE.Vector3} pointA
   * @param {THREE.Vector3} pointB
   * @return {number} Measure along the Tibia Z axis
   */
  measureInTibiaWorldZ(pointA: THREE.Vector3, pointB: THREE.Vector3) {
    pointA.applyMatrix3(this.getTibiaMatrix())
    pointB.applyMatrix3(this.getTibiaMatrix())
    return pointB.z-pointA.z
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommentService} from "@app/shared/component/comment/comment.service";
import { GlobalService } from '@app/services/global/global.service';
import { MatDialog } from '@angular/material/dialog';
import { ResetComponent } from '@app/ui/dialog/reset/reset.component';
import { ValidComponent } from '@app/ui/dialog/valid/valid.component';

/**
 * Middle component of the toolbar, presents actions buttons (measure, comment, screenshot) and navigation buttons
 * (cancel, reset, return)
 */


@Component({
  selector: 'app-toolbar-middle',
  templateUrl: './toolbar-middle.component.html',
  styleUrls: ['./toolbar-middle.component.scss']
})
export class ToolbarMiddleComponent implements OnInit {

  constructor( public commService: CommentService, public globalService: GlobalService, public dialog: MatDialog, public router: Router ) { }

  ngOnInit(): void {
  }

  measureIsActive = false;

  /**
   * A function used to open the Reset pop-up after pushing the reset button
   */
  openReset() {
    const dialogRef = this.dialog.open( ResetComponent, {
      backdropClass: 'dialog-valid'
    } );
  }

  /**
   * A function used to open the Valid pop-up after pushing the valid button
   */
  openValid() {
    const dialogRef = this.dialog.open( ValidComponent, {
      backdropClass: 'dialog-valid'
    } );
  }

  isPreop() {
    return this.router.url == "/preop";
  }

}

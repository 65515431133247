import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlPanelRightComponent} from "./pl-panel-right.component";
import {FemurViewDistalComponent} from "./femur-view-distal/femur-view-distal.component";
import {FemurViewSagittalComponent} from "./femur-view-sagittal/femur-view-sagittal.component";
import {FemurViewFrontComponent} from "./femur-view-front/femur-view-front.component";
import {SelectsContainerComponent} from "./selects-container/selects-container.component";
import {TibiaViewProximalComponent} from "./tibia-view-proximal/tibia-view-proximal.component";
import {TibiaViewTransversalComponent} from "./tibia-view-transversal/tibia-view-transversal.component";
import {TibiaViewFrontComponent} from "./tibia-view-front/tibia-view-front.component";
import {ViewsChoiceComponent} from "./views-choice/views-choice.component";
import {SelectFemurComponent} from "@app/shared/component/select-femur/select-femur.component";
import {SelectTibiaComponent} from "@app/shared/component/select-tibia/select-tibia.component";
import {SelectPatellaComponent} from "@app/shared/component/select-patella/select-patella.component";
import {FemurAdjustmentComponent} from "@app/shared/component/femur-adjustment/femur-adjustment.component";
import {PatellaAdjustmentComponent} from "@app/shared/component/patella-adjustment/patella-adjustment.component";
import {TibiaAdjustmentComponent} from "@app/shared/component/tibia-adjustment/tibia-adjustment.component";
import {MatSelectModule} from "@angular/material/select";
import {LoaderComponent} from '@app/shared/component/loader/loader.component';
import {FemurAnterioPosteriorDisplayPipe} from "@app/shared/pipe/femur-anterio-posterior-display.pipe";
import {PatellaAnterioPosteriorDisplayPipe} from "@app/shared/pipe/patella-anterio-posterior-display.pipe";
import {FemurExternalInternalDisplayPipe} from "@app/shared/pipe/femur-external-internal-display.pipe";
import { FemurVarusValgusDisplayPipe } from '@app/shared/pipe/femur-varus-valgus-display.pipe';
import { FemurFlexionExtensionDisplayPipe } from '@app/shared/pipe/femur-flexion-extension-display.pipe';
import { PatellaFlexionExtensionDisplayPipe } from '@app/shared/pipe/patella-flexion-extension-display.pipe';
import { PatellaExternalInternalDisplayPipe } from '@app/shared/pipe/patella-external-internal-display.pipe';
import { TibiaExternalInternalDisplayPipe } from '@app/shared/pipe/tibia-external-internal-display.pipe';
import { TibiaVarusValgusDisplayPipe } from '@app/shared/pipe/tibia-varus-valgus-display.pipe';
import { ButtonRightDisplayPipe } from '@app/shared/pipe/button-right-display.pipe';
import { ButtonLeftDisplayPipe } from '@app/shared/pipe/button-left-display.pipe';

@NgModule({
  declarations: [PlPanelRightComponent, FemurViewDistalComponent, FemurViewFrontComponent, FemurViewSagittalComponent, SelectsContainerComponent, TibiaViewProximalComponent, TibiaViewFrontComponent, TibiaViewTransversalComponent, ViewsChoiceComponent, SelectFemurComponent, SelectTibiaComponent, SelectPatellaComponent, TibiaAdjustmentComponent, PatellaAdjustmentComponent, FemurAdjustmentComponent,
    LoaderComponent,
    FemurAnterioPosteriorDisplayPipe,
    PatellaAnterioPosteriorDisplayPipe,
    FemurExternalInternalDisplayPipe,
    FemurVarusValgusDisplayPipe,
    FemurFlexionExtensionDisplayPipe,
    PatellaFlexionExtensionDisplayPipe,
    PatellaExternalInternalDisplayPipe,
    TibiaExternalInternalDisplayPipe,
    TibiaVarusValgusDisplayPipe,
    ButtonRightDisplayPipe,
    ButtonLeftDisplayPipe
  ],
  imports: [
    CommonModule,
    MatSelectModule
  ],
  exports: [
    PlPanelRightComponent,
    FemurAnterioPosteriorDisplayPipe,
    PatellaAnterioPosteriorDisplayPipe,
    FemurExternalInternalDisplayPipe,
    FemurVarusValgusDisplayPipe,
    FemurFlexionExtensionDisplayPipe,
    PatellaFlexionExtensionDisplayPipe,
    PatellaExternalInternalDisplayPipe,
    TibiaExternalInternalDisplayPipe,
    TibiaVarusValgusDisplayPipe,
    ButtonRightDisplayPipe,
    ButtonLeftDisplayPipe
  ]
})
export class PlPanelRightModule {
}

export const ELEMENTS_TIBIA = ['akagi_anterior',
  'akagi_posterior',
  'tibia_medial_plateau_anterior_edge',
  'tibia_medial_plateau_posterior_edge',
  'tibia_spine_center',
  'cup_base_external',
  'cup_base_internal',
  'tibia_shaft',
  'tibia_anatomical_projected_axis',
  'malleoli_center',
  'tibia_plateau_posterior_external',
  'tibia_plateau_posterior_internal'
]

export const ELEMENTS_TIBIA_2_DISPLAY = [
  'cup_base_external',
  'cup_base_internal',
]

export const ELEMENTS_TIBIA_EPIPHYSIS = [
  'akagi_anterior',
  'akagi_posterior',
  'tibia_medial_plateau_anterior_edge',
  'tibia_medial_plateau_posterior_edge',
  'tibia_spine_center',
  'cup_base_external',
  'cup_base_internal',
  'tibia_anatomical_projected_axis',
  'tibia_plateau_posterior_external',
  'tibia_plateau_posterior_internal'
]

export const TIBIA_COLOR = 0x04a4a4

import {Injectable} from "@angular/core";
import * as THREE from "three";
import {Utils} from "@app/services/class/Utils";
import {FemurViewDistalService} from "@app/ui/screens/planif/pl-panel-right/femur-view-distal/femur-view-distal.service";
import {MathUtils} from "three";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import { CSG } from 'three-csg-ts';
import {FemurViewFrontService} from "@app/ui/screens/planif/pl-panel-right/femur-view-front/femur-view-front.service";
import {FemurViewSagittalService} from "@app/ui/screens/planif/pl-panel-right/femur-view-sagittal/femur-view-sagittal.service";
import {OverviewService} from "@app/ui/screens/planif/pl-panel-left/overview/overview.service";
import {TibiaViewProximalService} from "@app/ui/screens/planif/pl-panel-right/tibia-view-proximal/tibia-view-proximal.service";
import {TibiaViewFrontService} from "@app/ui/screens/planif/pl-panel-right/tibia-view-front/tibia-view-front.service";
import {TibiaViewTransversalService} from "@app/ui/screens/planif/pl-panel-right/tibia-view-transversal/tibia-view-transversal.service";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {FEMUR_REF, PATELLA_REF} from "@app/shared/constant/ref_name.constant";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";
import {PlanifMeasuresService} from "@app/ui/screens/planif/planif-measures.service";


@Injectable({
  providedIn: 'root'
})

/**
 * Service used to update the cuts of the bones on every view, after the calculation of the new cut on one view
 */
export class PlanifSlicerService {


  constructor(private femurViewDistalService: FemurViewDistalService, private femurViewFrontService: FemurViewFrontService,
              private femurViewSagittalService: FemurViewSagittalService, private overviewService: OverviewService,
              private tibiaViewProximalService: TibiaViewProximalService, private tibiaViewFrontService: TibiaViewFrontService,
              private tibiaViewTransversalService: TibiaViewTransversalService, private planifViewManagerService: PlanifViewManagerService,
              private femurMortaiseService: FemurMortaiseService, private tibiaMortaiseService: TibiaMortaiseService,
              private patellaMortaiseService: PatellaMortaiseService, private planifViewReferencesService: PlanifViewReferencesService,
              private planifMeasureService: PlanifMeasuresService) {

  }

  /**
   * This method takes the cut as input and add it in each view where the femur is displayed
   * @param {THREE.Mesh} mesh The cut femur
   */
  addCutToFemoralViews(mesh: THREE.Mesh) {
    const name = 'femur';
    const nameCut = 'femur_cut'
    this.addCutToView(mesh.clone(), this.femurViewDistalService.scene, name, nameCut)
    this.addCutToView(mesh.clone(), this.femurViewFrontService.scene, name, nameCut)
    this.addCutToView(mesh.clone(), this.femurViewSagittalService.scene, name, nameCut)
    this.addCutToView(mesh.clone(), this.overviewService.scene, name, nameCut)
  }

  /**
   * This method takes the cut as input and add it in each view where the patella is displayed
   * @param {THREE.Mesh} mesh The cut patella
   */
  addCutToPatellaViews(mesh: THREE.Mesh) {
    const name = 'patella';
    const nameCut = 'patella_cut'
    this.addCutToView(mesh.clone(), this.femurViewDistalService.scene, name, nameCut)
    this.addCutToView(mesh.clone(), this.femurViewFrontService.scene, name, nameCut)
    this.addCutToView(mesh.clone(), this.femurViewSagittalService.scene, name, nameCut)
    this.addCutToView(mesh.clone(), this.overviewService.scene, name, nameCut)
  }

  /**
   * This method takes the cut as input and add it in each view where the tibia is displayed
   * @param {THREE.Mesh} mesh The cut tibia
   */
  addCutToTibialViews(mesh: THREE.Mesh) {
    const name = 'tibia';
    const nameCut = 'tibia_cut'
    this.addCutToView(mesh.clone(), this.tibiaViewProximalService.scene, name, nameCut)
    this.addCutToView(mesh.clone(), this.tibiaViewFrontService.scene, name, nameCut)
    this.addCutToView(mesh.clone(), this.tibiaViewTransversalService.scene, name, nameCut)
    this.addCutToView(mesh.clone(), this.overviewService.scene, name, nameCut)
  }

  /**
   * This method set the new geometry of the cut bone on the displayed cut.
   * @param {THREE.Mesh} meshCut The cut bone
   * @param {THREE.Scene} scene The scene on which the cut is displayed
   * @param {string} name The original name of the bone to hide if shown
   * @param {string} nameCut The name of the cut object
   */
  addCutToView(meshCut, scene, name, nameCut) {
    Utils.getMeshByName(scene, nameCut).geometry = (meshCut.geometry)
    Utils.getMeshByName(scene, name).visible = false
  }

  /**
   * This method updates position, rotation and measures of the femur cuts and implant. Called if the user change a
   * resection, angle, or reference for the femur
   */
  updateFemurCut() {
    //Call the mortaise and the points attached to it
    let meshMortaise = this.femurMortaiseService.mesh
    //Call every condyle and the points attached
    let meshCondyleOverview = Utils.getMeshByName(this.overviewService.scene, 'condyle')
    let meshCondyleDistal = Utils.getMeshByName(this.femurViewDistalService.scene, 'condyle')
    let meshCondyleFront = Utils.getMeshByName(this.femurViewFrontService.scene, 'condyle')
    let meshCondyleSagittal = Utils.getMeshByName(this.femurViewSagittalService.scene, 'condyle')
    const meshCondyleMortaise = [meshMortaise, meshCondyleDistal, meshCondyleSagittal, meshCondyleFront, meshCondyleOverview]
    //Call the femur
    const meshFemur = Utils.getMeshByName(this.overviewService.scene, 'femur')

    //update position/rotation
    this.femurMortaiseService.updateFemurPosition(meshCondyleMortaise, this.overviewService.scene)
    this.femurMortaiseService.updateFemurRotation(meshCondyleMortaise, this.femurViewDistalService.scene)
    meshMortaise.updateMatrix();

    //update first measure
    this.planifMeasureService.updateFemurResectionDistal(
      Utils.getPositionByObjectName(this.femurViewSagittalService.scene, 'condyle_distal_internal_mortaise'),
      Utils.getPositionByObjectName(this.femurViewSagittalService.scene, 'condyle_distal_internal'),
      Utils.getPositionByObjectName(this.femurViewSagittalService.scene, 'condyle_distal_external_mortaise'),
      Utils.getPositionByObjectName(this.femurViewSagittalService.scene, 'condyle_distal_external'))

    //update second measure
    this.planifMeasureService.updateFemurResectionPosterior(
      Utils.getPositionByObjectName(this.femurViewSagittalService.scene, 'condyle_posterior_internal_mortaise'),
      Utils.getPositionByObjectName(this.femurViewSagittalService.scene, 'condyle_posterior_internal'),
      Utils.getPositionByObjectName(this.femurViewSagittalService.scene, 'condyle_posterior_external_mortaise'),
      Utils.getPositionByObjectName(this.femurViewSagittalService.scene, 'condyle_posterior_external'))

    //update third measure
    this.planifMeasureService.updateMeasurePatellaFemurCut( Utils.getMeshByName(this.overviewService.scene, 'anterior_plane'),
                                                            Utils.getPositionByObjectName(this.overviewService.scene, 'anterior_plane'),
                                                            Utils.getPositionByObjectName(this.overviewService.scene, 'patella_mid'))
   //update cut mesh
    const femurCut = CSG.subtract(meshFemur, meshMortaise)
    this.addCutToFemoralViews(femurCut)
  }

  /**
   * This method updates position, rotation and measures of the patella cuts and implant. Called if the user changes a
   * resection, angle, or reference for the patella
   */
  updatePatellaCut() {
    let meshMortaise = Utils.getMeshByName(this.overviewService.scene, 'mortaise_patella')
    //Call every patella implant and the points attached
    let meshImplantOverview = Utils.getMeshByName(this.overviewService.scene, 'patella_implant')
    let meshImplantDistal = Utils.getMeshByName(this.femurViewDistalService.scene, 'patella_implant')
    let meshImplantFront = Utils.getMeshByName(this.femurViewFrontService.scene, 'patella_implant')
    let meshImplantSagittal = Utils.getMeshByName(this.femurViewSagittalService.scene, 'patella_implant')
    const meshImplantMortaise = [meshMortaise, meshImplantOverview, meshImplantDistal, meshImplantFront, meshImplantSagittal]
    const meshPatella = Utils.getMeshByName(this.overviewService.scene, 'patella')

    this.patellaMortaiseService.updatePatellaPosition(meshImplantMortaise, this.overviewService.scene)
    this.patellaMortaiseService.updatePatellaRotation(meshImplantMortaise, this.overviewService.scene)

    this.planifMeasureService.updatePatellaResection(
      Utils.getPositionByObjectName(this.overviewService.scene, 'patella_mid'),
      Utils.getPositionByObjectName(this.overviewService.scene, PATELLA_REF.REF.ANTERIOR.point ),
      Utils.getPositionByObjectName(this.overviewService.scene, PATELLA_REF.REF.POSTERIOR.point))

    meshMortaise.updateMatrix();
    this.planifMeasureService.updateMeasurePatellaFemurCut( Utils.getMeshByName(this.overviewService.scene, 'anterior_plane'),
                                                            Utils.getPositionByObjectName(this.overviewService.scene, 'anterior_plane'),
                                                            Utils.getPositionByObjectName(this.overviewService.scene, 'patella_mid'))
    const patellaCut = CSG.subtract(meshPatella , meshMortaise)

    this.addCutToPatellaViews(patellaCut)
  }

  /**
   * This method updates position, rotation and measures of the tibia cuts and implant. Called if the user changes a
   * resection, angle, or reference for the tibia
   */
  updateTibiaCut() {
    let meshMortaise = Utils.getMeshByName(this.overviewService.scene, 'mortaise_tibia_ptg')
    let meshPlateOverview = this.overviewService.scene.getObjectByName('plate')
    let meshPlateProximal = this.tibiaViewProximalService.scene.getObjectByName('plate')
    let meshPlateFront = this.tibiaViewFrontService.scene.getObjectByName('plate')
    let meshPlateTranseversal = this.tibiaViewTransversalService.scene.getObjectByName('plate')
    const meshPlateMortaise = [meshMortaise, meshPlateOverview, meshPlateProximal, meshPlateFront, meshPlateTranseversal]
    const meshTibia = Utils.getMeshByName(this.overviewService.scene, 'tibia')

    this.tibiaMortaiseService.updateTibiaPosition(meshPlateMortaise, this.overviewService.scene)
    meshMortaise.updateMatrix();
    this.tibiaMortaiseService.updateTibiaRotation (meshPlateMortaise, this.overviewService.scene)

    meshMortaise.updateMatrix();
    const tibiaCut = CSG.subtract(meshTibia, meshMortaise)

    this.addCutToTibialViews(tibiaCut)
    this.planifMeasureService.updateTibiaResection(
      Utils.getPositionByObjectName(this.overviewService.scene, 'cup_base_internal_mortaise'),
      Utils.getPositionByObjectName(this.overviewService.scene, 'cup_base_internal'),
      Utils.getPositionByObjectName(this.overviewService.scene, 'cup_base_external_mortaise'),
      Utils.getPositionByObjectName(this.overviewService.scene, 'cup_base_external'))
  }
}

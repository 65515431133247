import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsComponent } from '../../dialog/settings/settings.component';
import { ReturnComponent } from '../../dialog/return/return.component';

/**
 * Right component of the toolbar, presents help button and settings button
 */


@Component({
  selector: 'app-toolbar-right',
  templateUrl: './toolbar-right.component.html',
  styleUrls: ['./toolbar-right.component.scss']
})
export class ToolbarRightComponent implements OnInit {

  constructor( public dialog: MatDialog ) { }

  ngOnInit(): void {
  }

  /**
   * A function that opens the settings panel after pushing on the settings button
   */
  openSettings() {
    const dialogRef = this.dialog.open( SettingsComponent, {
      backdropClass: 'dialog-settings'
    } );
  }

  /**
   * A function used to open the Return pop-up after pushing the return button
   */
  openReturn() {
    const dialogRef = this.dialog.open( ReturnComponent, {
      backdropClass: 'dialog-valid'
    } );
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PlanifComponent} from "./planif.component";
import {PlPanelLeftModule} from "./pl-panel-left/pl-panel-left.module";
import {PlPanelRightModule} from "./pl-panel-right/pl-panel-right.module";

@NgModule({
  declarations: [PlanifComponent],
  imports: [
    CommonModule,
    PlPanelRightModule,
    PlPanelLeftModule
  ]
})
export class PlanifModule { }

export const FEMUR_BUTTON = {
  RE: 'Rotation externe',
  RI: 'Rotation interne',
  NA: 'Notching antérieur',
  RP: 'Résection postérieure',
  VAR: 'Varus',
  VAL: 'Valgus',
  RD: 'Résection distale',
  FL: 'Flessum',
  EX: 'Recurvatum'
}

export const TIBIA_BUTTON = {
  RE: 'Rotation externe',
  RI: 'Rotation interne',
  VAR: 'Varus',
  VAL: 'Valgus',
  RT: 'Résection tibiale',
  PT: 'Pente tibiale'
}

export const PATELLA_BUTTON = {
  RE: 'Rotation externe',
  RI: 'Rotation interne',
  RP: 'Résection postérieure',
  RW: 'Epaisseur restante',
  FL: 'Flessum',
  EX: 'Recurvatum'
}

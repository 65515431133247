<div id="po_view_left">
  <div style="text-align:center;">VUE 1</div>
  <div class="pov-value-container" id="pvc_mldfa">
    <div class="tt-toggle" [class.active]="tt.mldfa" (click)=" tt.mldfa = !tt.mldfa ">
      <div class="tt-toggle-indicator">
        <i class="icon icon-info"></i>
      </div>
      mLDFA {{ renderMLDFA() }}
    </div>
    <div class="tt-content tt-arrow-top">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis. Donec luctus urna eu ullamcorper mollis. Curabitur nec pulvinar sem, at luctus tellus.
    </div>
  </div>
  <div class="pov-value-container" id="pvc_mpta">
    <div class="tt-toggle" [class.active]="tt.mpta" (click)=" tt.mpta = !tt.mpta ">
      <div class="tt-toggle-indicator">
        <i class="icon icon-info"></i>
      </div>
      MPTA {{ renderMPTA() }}
    </div>
    <div class="tt-content tt-arrow-bottom">
      Angle entre l’axe mécanique du tibia et l’axe passant par les centres (médial et latéral) des plateaux tibiaux, dans le plan frontal du tibia. Cet angle est mesuré sur le côté du genou opéré.
    </div>
  </div>
  <div class="pov-value-container" id="pvc_hks">
    <div class="tt-toggle" [class.active]="tt.hks" (click)=" tt.hks = !tt.hks ">
      <div class="tt-toggle-indicator">
        <i class="icon icon-info"></i>
      </div>
      HKS {{ renderHKS() }}
    </div>
    <div class="tt-content tt-arrow-top">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis. Donec luctus urna eu ullamcorper mollis. Curabitur nec pulvinar sem, at luctus tellus.
    </div>
  </div>
  <div class="pov-value-container" id="pvc_varus">
    <div class="tt-toggle" [class.active]="tt.varus" (click)=" tt.varus = !tt.varus ">
      <div class="tt-toggle-indicator">
        <i class="icon icon-info"></i>
      </div>
      Varus {{ renderVarus() }}
    </div>
    <div class="tt-content tt-arrow-bottom">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis. Donec luctus urna eu ullamcorper mollis. Curabitur nec pulvinar sem, at luctus tellus.
    </div>
  </div>
  <!--mldfa: {{tt.mldfa}}<br>
  mpta: {{tt.mpta}}<br>
  hks: {{tt.hks}}<br>
  varus: {{tt.varus}}-->
</div>

import {Injectable} from '@angular/core';
import * as THREE from 'three';

@Injectable({
  providedIn: 'root'
})

// TODO Design pattern la fabrique
export class RendererService {

  constructor() {
  }

  createAnonymous(canvas: HTMLCanvasElement): THREE.WebGLRenderer {
    const renderer = new THREE.WebGLRenderer({
      canvas,
      alpha: true,
      antialias: true,
      preserveDrawingBuffer: true
    });
    renderer.localClippingEnabled = true;
    renderer.setSize(canvas.parentElement.parentElement.offsetWidth, canvas.parentElement.parentElement.offsetHeight);
    renderer.shadowMap.enabled = true;
    return renderer;
  }
}

import { Injectable } from '@angular/core';
import {ELEMENTS_FEMUR_2_DISPLAY, FEMUR_COLOR} from "@app/shared/constant/femur.constant";
import {Utils} from "@app/services/class/Utils";
import {ELEMENTS_TIBIA_2_DISPLAY, TIBIA_COLOR} from "@app/shared/constant/tibia.constant";
import {ELEMENTS_PATELLA_2_DISPLAY, PATELLA_COLOR} from "@app/shared/constant/patella.constant";
import * as THREE from "three";
import {MaterialService} from "@app/services/three/material.service";
import {FEMUR_REF, PATELLA_REF, TIBIA_REF} from "@app/shared/constant/ref_name.constant";


@Injectable({
  providedIn: 'root'
})

/**
 * Service used to manage the references of the different views (display and storage of references)
 */
export class PlanifViewReferencesService {
  private epicondyleAnatomicalAxis: THREE.Vector3
  private epicondyleSurgicalAxis: THREE.Vector3
  private condylePosteriorAxis: THREE.Vector3
  private whitesideAxis: THREE.Vector3
  private akagiAxis: THREE.Vector3
  private posteriorPlateAxis: THREE.Vector3
  private currentTibiaMeasureAxis= TIBIA_REF.MEASURE.AKAGI.value
  private currentFemurMeasureAxis= FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.value
  currentFemurRotationAxis= FEMUR_REF.ROT.MECA.value
  private currentPatellaRef: string
  private currentFemurRef: string

  constructor(private materialService: MaterialService) { }

  /**
   * This method displays the femur points to be displayed (set in the array ELEMENTS_FEMUR_2_DISPLAY)
   * @param {THREE.Scene} scene The current femur scene
   */
  displayFemurPoint(scene) {
    for (let point of ELEMENTS_FEMUR_2_DISPLAY) {
      Utils.getMeshByName(scene, point).material = this.materialService.createFemurPointMaterial()
    }
  }

  /**
   * This method displays the tibia points to be displayed (set in the array ELEMENTS_TIBIA_2_DISPLAY)
   * @param {THREE.Scene} scene The current tibia scene
   */
  displayTibiaPoint(scene) {
    for (let point of ELEMENTS_TIBIA_2_DISPLAY) {
      Utils.getMeshByName(scene, point).material = this.materialService.createTibiaPointMaterial()
    }
  }

  /**
   * This method displays the patella points to be displayed (set in the array ELEMENTS_PATELLA_2_DISPLAY)
   * @param {THREE.Scene} scene The current femur scene
   */
  displayPatellaPoint(scene) {
    for (let point of ELEMENTS_PATELLA_2_DISPLAY) {
      Utils.getMeshByName(scene, point).material = this.materialService.createPatellaPointMaterial()
    }
  }

  /**
   * This method returns the mechanical axis of the femur
   * @param {THREE.Scene} scene The current scene
   * @return {THREE.Vector3} A normalized vector describing the femur mechanical axis orientation
   */
  getFemurMecaAxis(scene) {
    const vectHip = Utils.getPointPositionByName(scene, 'hip_head')
    const vectFemur = Utils.getPointPositionByName(scene, 'femur_mid')
    const femurAxis = vectHip.clone().sub(vectFemur)
    return femurAxis.normalize()
  }

  /**
   * This method display the mechanical axis of the femur for help view TODO make the line over all object
   * @param {THREE.Scene} scene The current scene
   */
  displayFemurMecaAxis(scene) {
    const vectHip = Utils.getPointPositionByName(scene, 'hip_head')
    const vectFemur = Utils.getPointPositionByName(scene, 'femur_mid')

    const material = new THREE.LineBasicMaterial({
      color: 0x0000ff,
    });

    const points = [];
    points.push(vectHip);
    points.push(vectFemur);

    const geometry = new THREE.BufferGeometry().setFromPoints( points );

    const line = new THREE.Line( geometry, material );
    scene.add( line );
  }

  /**
   * This method returns the mechanical axis of the tibia
   * @param {THREE.Scene} scene The current scene
   * @return {THREE.Vector3} A normalized vector describing the tibia mechanical axis orientation
   */
  getTibiaMecaAxis(scene) {
    const vectTibia = Utils.getPointPositionByName(scene, 'tibia_spine_center')
    const vectAnkle = Utils.getPointPositionByName(scene, 'malleoli_center')
    const tibiaAxis = vectTibia.clone().sub(vectAnkle)
    return tibiaAxis.normalize()
  }

  /**
   * This method display the mechanical axis of the tibia for help view TODO make the line over all object
   * @param {THREE.Scene} scene The current scene
   */
  displayTibiaMecaAxis(scene) {
    const vectTibia = Utils.getPointPositionByName(scene, 'tibia_spine_center')
    const vectAnkle = Utils.getPointPositionByName(scene, 'malleoli_center')

    const material = new THREE.LineBasicMaterial({
      color: 0xff0000,
    });

    const points = [];
    points.push(vectTibia);
    points.push(vectAnkle);

    const geometry = new THREE.BufferGeometry().setFromPoints( points );

    const line = new THREE.Line( geometry, material );
    line.renderOrder= 1
    scene.add( line );
  }

  /**
   * This method sets the anatomical epicondyle axis for rotation and display it
   * @param {THREE.Scene} scene The current scene
   */
  setEpicondyleAnatomicalAxis(scene) {
    const vectEpicondyleInt = Utils.getPositionByObjectName(scene, FEMUR_REF.MEASURE.EPICONDYLE_ANATOMICAL.pointA)
    const vectEpicondyleExt = Utils.getPositionByObjectName(scene, FEMUR_REF.MEASURE.EPICONDYLE_ANATOMICAL.pointB)
    vectEpicondyleInt.z=-50
    vectEpicondyleExt.z=-50
    let femurAxis = new THREE.Vector3(vectEpicondyleInt.x-vectEpicondyleExt.x, vectEpicondyleInt.y-vectEpicondyleExt.y, vectEpicondyleInt.z-vectEpicondyleExt.z)
    femurAxis.normalize()
    this.epicondyleAnatomicalAxis = femurAxis
    const name =FEMUR_REF.MEASURE.EPICONDYLE_ANATOMICAL.value
    scene.add( this.createFemurLine(vectEpicondyleInt, vectEpicondyleExt, name));
  }

  /**
   * @return {THREE.Vector3} A normalized vector describing the anatomical epicondyle axis
   */
  getEpicondyleAnatomicalAxis() {
    return this.epicondyleAnatomicalAxis
  }

  /**
   * This method returns the surgical epicondyle axis for rotation and display it on the scene
   * @param {THREE.Scene} scene The current scene
   */
  setEpicondyleSurgicalAxis(scene) {
    const vectEpicondyleInt = Utils.getPositionByObjectName(scene, FEMUR_REF.MEASURE.EPICONDYLE_SURGICAL.pointA)
    const vectEpicondyleExt = Utils.getPositionByObjectName(scene, FEMUR_REF.MEASURE.EPICONDYLE_SURGICAL.pointB)
    vectEpicondyleInt.z=-50
    vectEpicondyleExt.z=-50
    const femurAxis = vectEpicondyleInt.clone().sub(vectEpicondyleExt)
    femurAxis.normalize()
    this.epicondyleSurgicalAxis = femurAxis
    const name = FEMUR_REF.MEASURE.EPICONDYLE_SURGICAL.value
    scene.add( this.createFemurLine(vectEpicondyleInt, vectEpicondyleExt, name));
  }

  /**
   * @return {THREE.Vector3} A normalized vector describing the surgical epicondyle axis
   */
  getEpicondyleSurgicalAxis() {
    return this.epicondyleSurgicalAxis
  }

  /**
   * This method returns the posterior condyle axis for rotation
   * @param {THREE.Scene} scene The current scene
   */
  setCondylePosteriorAxis(scene) {
    const vectCondyleInt = Utils.getPositionByObjectName(scene, FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.pointA)
    const vectCondyleExt = Utils.getPositionByObjectName(scene, FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.pointB)
    vectCondyleInt.z=-50
    vectCondyleExt.z=-50
    const femurAxis = vectCondyleInt.clone().sub(vectCondyleExt)
    femurAxis.normalize()
    this.condylePosteriorAxis = femurAxis
    const name = FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.value
    scene.add(this.createFemurLine(vectCondyleInt, vectCondyleExt, name));
  }

  /**
   * @return {THREE.Vector3} A normalized vector describing the posterior condyle axis
   */
  getCondylePosteriorAxis() {
    return this.condylePosteriorAxis
  }

  /**
   * This method returns the whiteside line axis, which is orthogonal to the surgical epicondyle line, and display it
   * @param {THREE.Scene} scene The current scene
   */
  setAPWhitesideAxis(scene) {
    const vectTrochlea = Utils.getPositionByObjectName(scene, FEMUR_REF.MEASURE.WHITESIDE.pointA)
    const vectNotch = Utils.getPositionByObjectName(scene, FEMUR_REF.MEASURE.WHITESIDE.pointB)
    vectTrochlea.z=-50
    vectNotch.z=-50
    const femurAxis = vectNotch.clone().sub(vectTrochlea)
    femurAxis.normalize()
    this.whitesideAxis = femurAxis
    const name = FEMUR_REF.MEASURE.WHITESIDE.value
    scene.add(this.createFemurLine(vectTrochlea, vectNotch, name));
  }

  /**
   * @return {THREE.Vector3} A normalized vector describing the whiteside line axis
   */
  getAPWhitesideAxis() {
    return this.whitesideAxis
  }

  /**
   * Set the new reference point for the anterio/posterior translation of the condyle
   * @param {string} newRef The new condyle reference point
   * @param {THREE.Scene} scene The femur sagittal view
   */
  setCurrentFemurRefPoint(newRef: string, scene){
    const pointNew =  newRef==FEMUR_REF.REF.POSTERIOR.value ? FEMUR_REF.REF.POSTERIOR.point : FEMUR_REF.REF.ANTERIOR.point
    const pointOld =  this.currentFemurRef==FEMUR_REF.REF.POSTERIOR.value ? FEMUR_REF.REF.POSTERIOR.point : FEMUR_REF.REF.ANTERIOR.point
    // @ts-ignore
    scene.getObjectByName(pointOld).material.color.setHex(FEMUR_COLOR)
    this.currentFemurRef=newRef
    // @ts-ignore
    scene.getObjectByName(pointNew).material.color.setHex(0x00ff00)
  }

  /**
   * Set the new axis for the femur rotation measure (internal/external), choosen by the user
   * @param {string} newCurrent The new femur rotation axis
   * @param {THREE.Scene} scene The femur distal view
   */
  setCurrentFemurMeasureAxis (newCurrent: string, scene: THREE.Scene){
    // @ts-ignore
    scene.getObjectByName(this.currentFemurMeasureAxis).material.color.setHex(FEMUR_COLOR)
    this.currentFemurMeasureAxis=newCurrent
    // @ts-ignore
    scene.getObjectByName(this.currentFemurMeasureAxis).material.color.setHex(0x00ff00)
  }

  /**
   * Set the new rotation axis for the femur, choosen by the user
   * @param {string} newCurrent The new femur rotation axis
   * @param {THREE.Scene} scene The femur distal view
   */
  setCurrentFemurRotationAxis (newCurrent: string, scene: THREE.Scene){
    const oldPoint = this.currentFemurRotationAxis===FEMUR_REF.ROT.MECA.value ? FEMUR_REF.ROT.MECA.point: FEMUR_REF.ROT.POST.point
    const newPoint = newCurrent===FEMUR_REF.ROT.MECA.value ? FEMUR_REF.ROT.MECA.point: FEMUR_REF.ROT.POST.point
    // @ts-ignore
    scene.getObjectByName(oldPoint).material.color.setHex(FEMUR_COLOR)
    this.currentFemurRotationAxis=newCurrent
    // @ts-ignore
    scene.getObjectByName(newPoint).material.color.setHex(0x00ff00)
  }

  /**
   * Set the new reference point for the anterio/posterior translation of the patella implant
   * @param {string} newRef The new patella reference point
   * @param {THREE.Scene} scene The femur sagittal view
   */
  setCurrentPatellaRefPoint(newRef: string, scene){
    const pointNew =  newRef==PATELLA_REF.REF.POSTERIOR.value ? PATELLA_REF.REF.POSTERIOR.point : PATELLA_REF.REF.ANTERIOR.point
    const pointOld =  this.currentPatellaRef==PATELLA_REF.REF.POSTERIOR.value ? PATELLA_REF.REF.POSTERIOR.point : PATELLA_REF.REF.ANTERIOR.point
    // @ts-ignore
    scene.getObjectByName(pointOld).material.color.setHex(PATELLA_COLOR)
    this.currentPatellaRef=newRef
    // @ts-ignore
    scene.getObjectByName(pointNew).material.color.setHex(0x00ff00)
  }

  /**
   * Set the new measure reference axis for the internal/external rotation
   * @param {string} newRef The new tibia measure line
   * @param {THREE.Scene} scene The tibia proximal view
   */
  setCurrentTibiaMeasureAxis(newRef: string, scene){
    // @ts-ignore
    scene.getObjectByName(this.currentTibiaMeasureAxis).material.color.setHex(TIBIA_COLOR)
    this.currentTibiaMeasureAxis=newRef
    // @ts-ignore
    scene.getObjectByName(this.currentTibiaMeasureAxis).material.color.setHex(0x00ff00)
  }

  /**
   * This method returns the akagi axis, orthogonal to the femoral epicondyle axis
   * @param {THREE.Scene} scene The current scene
   */
  setAkagiAxis(scene) {
    const vectAkagiA = Utils.getPositionByObjectName(scene, TIBIA_REF.REF.AKAGI.pointA)
    const vectAkagiP= Utils.getPositionByObjectName(scene, TIBIA_REF.REF.AKAGI.pointB)
    //vectAkagiA.z=+50 //TODO : this.getTibiaMecaAxis(scene)
    //vectAkagiP.z=+50
    const tibiaAxis = vectAkagiA.clone().sub(vectAkagiP)
    tibiaAxis.normalize()
    this.akagiAxis = tibiaAxis
    const name = TIBIA_REF.REF.AKAGI.value
    scene.add(this.createTibiaLine(vectAkagiA, vectAkagiP, name));
  }

  /**
   * @return {THREE.Vector3} A normalized vector describing the akagi line axis
   */
  getAkagiAxis() {
    return this.akagiAxis
  }

  /**
   * This method returns the akagi axis, orthogonal to the femoral epicondyle axis
   * @param {THREE.Scene} scene The current scene
   */
  setPosteriorPlateAxis(scene) {
    const vectPostExt = Utils.getPositionByObjectName(scene, TIBIA_REF.REF.POSTERIOR.pointA)
    const vectPostInt = Utils.getPositionByObjectName(scene, TIBIA_REF.REF.POSTERIOR.pointB)
    //vectPostExt.z=+50
    //vectPostInt.z=+50
    const tibiaAxis = vectPostInt.clone().sub(vectPostExt)
    tibiaAxis.normalize()
    this.posteriorPlateAxis = tibiaAxis
    const name = TIBIA_REF.REF.POSTERIOR.value
    scene.add(this.createTibiaLine(vectPostInt, vectPostExt, name));
  }

  /**
   * @return {THREE.Vector3} A normalized vector describing the akagi line axis
   */
  getPosteriorPLateAxis() {
    return this.posteriorPlateAxis
  }

  //TODO place in Utils ?
  /**
   * Create a line from two point with the femur color, and affect a name to it
   * @param {THREE.Vector3} pointA
   * @param {THREE.Vector3} pointB
   * @param {string} name
   * @return {THREE.Line} The line to display
   */
  createFemurLine(pointA, pointB, name) {
    const material = this.materialService.createFemurReferenceMaterial()

    const points = [];
    points.push(pointA);
    points.push(pointB);
    const geometry = new THREE.BufferGeometry().setFromPoints( points );
    const line = new THREE.Line( geometry, material );
    line.name = name
    return line
  }

  //TODO place in Utils ?
  /**
   * Create a line from two point with the tibial color, and affect a name to it
   * @param {THREE.Vector3} pointA
   * @param {THREE.Vector3} pointB
   * @param {string} name
   * @return {THREE.Line} The line to display
   */
  createTibiaLine(pointA, pointB, name) {
    const material = this.materialService.createTibiaReferenceMaterial()

    const points = [];
    points.push(pointA);
    points.push(pointB);
    const geometry = new THREE.BufferGeometry().setFromPoints( points );
    const line = new THREE.Line( geometry, material );
    line.name = name
    return line
  }

}

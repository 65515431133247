import {Utils} from "@app/services/class/Utils";
import {MathUtils, Vector3} from "three";

export const JSON_CASE1 = {
  intervention: {
    // @Input
    side: 'right',
    // @Input Réf
    // S: serf , P : Planification 3D , K:knee , M:Madison
    id: 'SP3DKM',
    /**
     *  state : 'created' 'opened' 'wip' 'closed'
     */
    state: 'opened',
    /**
     * Date intervention
     */
    date: '25-09-2021',
    scanType : 'CTscan',
    annotation : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis. Donec luctus urna eu ullamcorper mollis. Curabitur nec pulvinar sem, at luctus tellus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis. Donec luctus urna eu ullamcorper mollis. Curabitur nec pulvinar sem, at luctus tellus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis.'
  },
  patient: {
    // @Input
    firstName: 'Michel De la rue',
    lastName: 'PLATINI K',
    // @Input
    birthDate: '10-07-1990',
  },
  // @Input
  path: {
    toWorkflow: '/show/workflows',
    toValidate: '/planning/validate/10',
    toPrelanning: '/preplanning/hip/upload/10',
    toScreenshot: '/planning/modelisation/10/screenshot',
    toGenerateReport: '/planning/report/10',
    toReportFile: 'assets/hip/report.pdf',
    toObjectDirectory: 'assets/madison/scene/case_1/femurMeca_nonreax',
    //'assets/madison/scene/case_1'
    //'assets/madison/scene/case_1/scan'
    //'assets/madison/scene/case_1/femurMeca_nonreax'
    //'assets/madison/scene/case_1/femurMeca_reax'
    toImplantDirectory: 'assets/madison/scene/implant',
    toMortaiseDirectory: 'assets/madison/scene/mortaise',
    toInterventionSummary: 'uploads/surgeon/22-Nathan-Chirurgien/10-QOFIREFOXTESTANO1/DS10/preplanning/unzipped-data'
  },
  // @Input preplanification
  nii: {
    path: 'assets/madison/scene/case_1/case_01.nii.gz',

    // TODO Matrice de transfert, prendre une décision de la garder ou pas @TechMeet

    rotations: {
      /* //case_1_scan
      x: 0,
      y: 0,
      z: 180 */
      //case_1_femurMeca
        x: 0.8625,
        y: 2.1903,
        z: -12.3022
      /* //case_1
        x: 9.2+180,
        y: 6.0969+180,
        z: -12.2594+180 */
    },
    translations: {
      /* //case_1_scan
      x: 0,
      y: 0,
      z: 0 */
      //case_1_femurMeca
      x: -96.8706,
      y: -2.7773,
      z: 539.8622
      /* //case_1
      x: -96.87, + devient -
      y: +2.78, - devient +
      z: 539.86 */
    },
    reaxage: {
      //case_1_femurMeca
      rot: 2.9224
    }
  },
  preplanning: {
    // TODO preplaning automatique, insérer la rep json ici pour pouvoir la modifier.
  },
  planning: {
    range_femur: 'ps',
    size_femur: 't1',
    range_tibia: 'mobile',
    size_tibia: 't1',
    range_patella: '30',
    size_patella: '10'
  }
};

<div id="tibia_view_one">
  <canvas #tibiaViewProximal style="text-align:center;">TIBIA 1</canvas>
  <span class="value" id="t_v_1">{{ this.tibiaViewProximalService.getLeftValue() }} mm</span>
  <span class="value" id="t_v_2">{{ this.tibiaViewProximalService.getRightValue() }} mm</span>
  <span class="value" id="t_v_3">{{ value3.toFixed( 1 ) }} °</span>
  <div class="axe">
    <span>{{this.getLeftSideIndicator()}}</span>
    <span>{{this.getRightSideIndicator()}}</span>
  </div>
  <app-loader></app-loader>
  <!--scanner: {{ viewsChoiceService.getScanner() }}<br>
  patella: {{ viewsChoiceService.getPatella() }}<br>
  cut: {{ viewsChoiceService.getCut() }}<br>
  implant: {{ viewsChoiceService.getImplant() }}<br>
  rotationInterneExterne: {{ tibiaAdjustmentService.getRotationInterneExterne() }}<br>
  varusValgus: {{ tibiaAdjustmentService.getVarusValgus() }}<br>
  resectionProximale: {{ tibiaAdjustmentService.getResectionProximale() }}<br>
  tibialSlope: {{ tibiaAdjustmentService.getTibialSlope() }}<br>-->
</div>

import * as THREE from 'three';
import {ManagerService} from '../three/manager.service';
import {Observable} from 'rxjs';
import {Mesh} from "three";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";

/**
 * Class overrided to load objects
 */
export class ParentObject {
  url!: string;
  geometry!: THREE.BufferGeometry;
  name: string;
  mesh!: THREE.Mesh;
  material: any;

  constructor(public manager: ManagerService) {
  }

  /**
   * @return {Observable} The mesh loaded
   */
  load(): Observable<Mesh> {
    return new Observable(observer => {
      const loader = new OBJLoader(this.manager.manager);
      loader.load(this.url, (object3d) => {
        // @ts-ignore
        this.geometry = object3d.children[0].geometry;
        this.geometry.computeBoundingSphere();
        this.geometry.computeBoundingBox();
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.mesh.name = this.name;
        this.material.name = this.name;
        observer.next(this.mesh);
      });
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { TooltipsService } from '../../../../services/preop/tooltips.service';

/**
 * Component used to toggle the tooltips panels
 */

@Component({
  selector: 'app-po-toggle-tooltips',
  templateUrl: './po-toggle-tooltips.component.html',
  styleUrls: ['./po-toggle-tooltips.component.scss']
})
export class PoToggleTooltipsComponent implements OnInit {

  public tt;

  constructor( tooltipsService: TooltipsService ) {
    this.tt = tooltipsService.getState();
  }

  ngOnInit(): void {
  }

  /**
   * function triggered by the "Show all infos" button, returning a boolean that describes if the infos need to be displayed
   * or not
   */
  allVisible() {
    let res = true;
    Object.values( this.tt ).forEach( value => {
      if( !value ) res = false;
    } );
    return res;
  }

  /**
   * function used to show or hide all the infos panel, depending on the state of the boolean returned by the
   * allVisible method
   */
  toggle() {
    if( !this.allVisible() ) {
      this.tt.mldfa = true;
      this.tt.mpta = true;
      this.tt.hks = true;
      this.tt.varus = true;
      this.tt.flexion = true;
      this.tt.tibialSlope = true;
    } else {
      this.tt.mldfa = false;
      this.tt.mpta = false;
      this.tt.hks = false;
      this.tt.varus = false;
      this.tt.flexion = false;
      this.tt.tibialSlope = false;
    }
  }

}

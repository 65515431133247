import {OrthoViewer} from '@app/services/nii/ortho-viewer';
import * as THREE from 'three';
import {AxesHelper, Euler, Object3D} from 'three';
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {Utils} from "@app/services/class/Utils";

const PLANES = ['yz', 'xz', 'xy'];

export class ViewerNii {
  viewer: any;
  slicedX = 0;
  slicedY = 0;
  slicedZ = 0;
  private dataService: DataSymfonyService;

  constructor() {
    this.dataService = new DataSymfonyService();
  }

  init(scene, plane = 'xz', slice = null) {
    return new Promise(resolve => {
      const request = new XMLHttpRequest();
      const reader = new FileReader();
      const url = this.dataService.getPathNII();
      request.open('GET', url, true);
      request.responseType = 'blob';
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          this.viewer = new OrthoViewer(evt.target.result, (v) => {
            const mesh = new Object3D();
            mesh.name = 'viewer-nifti';
            const object = {
              dim: v.dims[PLANES.indexOf(plane)],
              x: v.header.qoffset_x,
              y: v.header.qoffset_y,
              z: v.header.qoffset_z,
            }

            resolve(object);
            //v.mesh.add(new AxesHelper(1000));

            mesh.add(v.mesh);
            scene.add(mesh);
            if (slice === null) {
              slice = v.dims[PLANES.indexOf(plane)] / 2
            }
            if (plane === 'yz') {
              slice = slice + object.x;
            } else if (plane === 'xz') {
              slice = slice + object.y;
            } else if (plane === 'xy') {
              slice = slice - object.z;
            }
            v.slice(plane, slice);
          }, plane);
        }
      };
      request.onloadend = () => {
        reader.readAsArrayBuffer(request.response);
      };
      request.send();
    })
  }
}

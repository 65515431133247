<div class="kneecap-adjustment" [class.horizontal]=" viewsChoiceService.getFemurTibias() == 'all' " [class.vertical]=" viewsChoiceService.getFemurTibias() != 'all' " [class.disabled]= " globalService.getValided() ">
    <div class="adjustment-item">
      <div class="title">
        {{patellaAdjustmentService.getRotationInternalExternal() | patellaExternalInternalDisplay}}
      </div>
      <div class="adjustment-row">
        <button type="button" class="minus" (click)=" patellaAdjustmentService.minusRotationInternalExternal() ">
          {{patellaAdjustmentService.getRotationInternalExternal() | buttonLeftDisplay : this.patellaAdjustmentService.patellaMortaiseService.sideModifier}}
        </button>
        <div class="value">
          {{ patellaAdjustmentService.getAbsoluteRotationInternalExternal() }}°
        </div>
        <button type="button" class="plus" (click)=" patellaAdjustmentService.plusRotationInternalExternal() ">
          {{patellaAdjustmentService.getRotationInternalExternal() | buttonRightDisplay : this.patellaAdjustmentService.patellaMortaiseService.sideModifier}}
        </button>
      </div>
    </div>
    <div class="separator"></div>
    <div class="adjustment-item">
      <div class="title">
        {{patellaAdjustmentService.patellaMortaiseService.refAnteriorPosterior | patellaAnterioPosteriorDisplay}}
      </div>
      <div class="adjustment-row">
        <button type="button" class="minus" (click)=" patellaAdjustmentService.minusResectionAnteriorPosterior() ">
          -
        </button>
        <div class="value">
          {{ patellaAdjustmentService.getResectionAnteriorPosterior() }} mm
        </div>
        <button type="button" class="plus" (click)=" patellaAdjustmentService.plusResectionAnteriorPosterior() ">
          +
        </button>
      </div>
    </div>
    <div class="separator"></div>
    <div class="adjustment-item">
      <div class="title">
        {{patellaAdjustmentService.getFlexionExtension() | patellaFlexionExtensionDisplay}}
      </div>
      <div class="adjustment-row">
        <button type="button" class="minus" (click)=" patellaAdjustmentService.minusFlexionExtension() ">
          {{patellaAdjustmentService.getFlexionExtension() | buttonLeftDisplay : 1}}
        </button>
        <div class="value">
          {{ patellaAdjustmentService.getAbsoluteFlexionExtension() }}°
        </div>
        <button type="button" class="plus" (click)=" patellaAdjustmentService.plusFlexionExtension() ">
          {{patellaAdjustmentService.getFlexionExtension() | buttonRightDisplay : 1}}
        </button>
      </div>
    </div>
  </div>

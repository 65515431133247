import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppRoutingModule} from "../../app-routing.module";

import {HeaderComponent} from "./header.component";
import {HeaderNavComponent} from "./header-nav/header-nav.component";
import {InfosPatientComponent} from "./infos-patient/infos-patient.component";

@NgModule({
  declarations: [HeaderNavComponent, HeaderComponent, InfosPatientComponent],
  imports: [
    CommonModule,
    AppRoutingModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }

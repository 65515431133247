<div class="app-container">
  <div class="app-inner">
    <app-header></app-header>
    <app-toolbar></app-toolbar>
    <div class="router-container">
      <router-outlet></router-outlet>
      <app-comment *ngIf=" commService.getCommIsOpen() "></app-comment>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TooltipsService } from '../../../../services/preop/tooltips.service';

/**
 * Component defining the right-side screen of the pre-operative page, containing the sagittal view of the knee with the
 * flexion angle
 */

@Component({
  selector: 'app-po-view-lateral',
  templateUrl: './po-view-lateral.component.html',
  styleUrls: ['./po-view-lateral.component.scss']
})
export class PoViewLateralComponent implements OnInit {

  public tt;

  constructor( tooltipsService: TooltipsService ) {
    this.tt = tooltipsService.getState();
  }

  ngOnInit(): void {
  }

  flexion = 15.5;
  tibialSlope = 28.5;

  /**
   * function that renders the flexion angle
   */
  renderFlexion() {
    return this.flexion.toFixed( 1 ) + "°";
  }

  /**
   * function that renders the tibial slope
   */
  renderTibialSlope() {
    return this.tibialSlope.toFixed( 1 ) + "°";
  }

}

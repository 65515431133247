export const ELEMENTS_PATELLA = [
  'patella_anterior',
  'patella_distal',
  'patella_internal',
  'patella_external',
  'patella_proximal',
  'patella_posterior'
]

export const ELEMENTS_PATELLA_2_DISPLAY = [
  'patella_anterior',
  'patella_posterior'
]

export const PATELLA_COLOR = 0x729e1f

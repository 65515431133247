import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    let $this = this;
    setTimeout( function() {
      $this.loaded();
    }, 6000 );
  }

  public show = true;

  loaded() {
    this.show = false;
  }

}

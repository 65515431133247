import {Injectable} from '@angular/core';
import {Implant} from '../class/implant';
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {ManagerService} from "@app/services/three/manager.service";
import {MaterialService} from "@app/services/three/material.service";
import {Utils} from "@app/services/class/Utils";
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";

@Injectable({
  providedIn: 'root'
})
/**
 * Service used to load and manipulate the patella implant
 */
export class PatellaImplantService extends Implant {
  constructor(dataSymfonyService: DataSymfonyService, manager: ManagerService,
              public materialService: MaterialService, private patellaMortaiseService: PatellaMortaiseService) {
    super(manager, dataSymfonyService);
    this.name = 'patella_implant';
    this.size = this.dataSymfonyService.getPlanification().size_patella
    this.range = this.dataSymfonyService.getPlanification().range_patella
    this.url = `${location.origin}/${this.dir}/${this.name}_${this.range}_${this.size}.obj`;
    this.material = this.materialService.createPatellaImplantMaterial()
  }

  //TODO : service Position ou ce service
  /**
   * This set the initial position of the patella implant (cut = 0.0mm) in the scene called
   * @param {THREE.scene} scene The scene on which the method is called
   */
  setPatellaImplantInitialPosition(scene) {
    let implant =  Utils.getMeshByName(scene, 'patella_implant')

    this.patellaMortaiseService.updatePatellaPosition([implant], scene)
    this.patellaMortaiseService.updatePatellaRotation([implant], scene)
  }
}

import { Component, OnInit } from '@angular/core';
import { ViewsChoiceService } from '../../../../../services/planif/views-choice.service';

/**
 * Component that allows the user to switch to femoral view
 */

@Component({
  selector: 'app-femur-switch-views',
  templateUrl: './femur-switch-views.component.html',
  styleUrls: ['./femur-switch-views.component.scss']
})
export class FemurSwitchViewsComponent implements OnInit {

  constructor( public viewsChoiceService: ViewsChoiceService ) { }

  ngOnInit(): void {
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreopComponent } from './ui/screens/preop/preop.component';
import { PlanifComponent } from './ui/screens/planif/planif.component';

const routes: Routes = [
  {
    path: '',
    //redirectTo: '/preop',
    redirectTo: '/planif',
    pathMatch: 'full'
  },
  {
    path: 'preop',
    component: PreopComponent
  },
  {
    path: 'planif',
    component: PlanifComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Pipe, PipeTransform } from '@angular/core';
import {PATELLA_REF} from "@app/shared/constant/ref_name.constant";
import {PATELLA_BUTTON} from "@app/shared/constant/button_name.constant";

@Pipe({
  name: 'patellaAnterioPosteriorDisplay'
})
export class PatellaAnterioPosteriorDisplayPipe implements PipeTransform {

  transform(value: string): string {
    return value === PATELLA_REF.REF.ANTERIOR.value ? PATELLA_BUTTON.RW : PATELLA_BUTTON.RP
  }

}

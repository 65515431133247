import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {ToolbarComponent} from "./toolbar.component";
import {ToolbarInfosComponent} from "./toolbar-infos/toolbar-infos.component";
import {ToolbarMiddleComponent} from "./toolbar-middle/toolbar-middle.component";
import {ToolbarRightComponent} from "./toolbar-right/toolbar-right.component";

@NgModule({
  declarations: [ToolbarComponent, ToolbarRightComponent, ToolbarMiddleComponent, ToolbarInfosComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ToolbarComponent
  ]
})
export class ToolbarModule { }

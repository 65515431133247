export const FEMUR_REF = {
  REF: {
    POSTERIOR: {
      value: 'posterior',
      display: 'Postérieure',
      point: 'condyle_posterior_internal'
    },
    ANTERIOR: {
      value: 'anterior',
      display: 'Antérieure',
      point: 'cortical_anterior'
    },
  },
  ROT: {
    MECA: {
      value: 'meachnical_axis',
      display: 'Axe mécanique',
      point: 'femur_mid'
    },
    POST: {
      value: 'internal_posterior_axis',
      display: 'Axe postérieur interne',
      point: 'post'
    },
  },
  MEASURE: {
    CONDYLE_POSTERIOR: {
      value: 'condyle_posterior_line',
      display: 'Condyles postérieurs',
      pointA: 'condyle_posterior_internal',
      pointB: 'condyle_posterior_external',
    },
    EPICONDYLE_SURGICAL: {
      value: 'epicondyle_surgical_line',
      display: 'Ligne épicondyles chirurgicaux',
      pointA:'epicondyle_surgical_internal',
      pointB:'epicondyle_external',
    },
    EPICONDYLE_ANATOMICAL: {
      value: 'epicondyle_anatomical_line',
      display: 'Ligne épicondyles anatomiques',
      pointA:'epicondyle_anatomical_internal',
      pointB:'epicondyle_external',
    },
    WHITESIDE: {
      value: 'whiteside_line',
      display: 'Ligne de Whiteside',
      pointA:'trochlea_base_concavity',
      pointB:'notch_posterior_concavity',
    },
  }
}

export const TIBIA_REF = {
  REF: {
    AKAGI: {
      value: 'akagi',
      display: 'Ligne AP d\'Akagi',
      pointA: 'akagi_anterior',
      pointB: 'akagi_posterior'
    },
    POSTERIOR: {
      value: 'posterior_plateau',
      display: 'Bord plateau postérieur',
      pointA: 'tibia_plateau_posterior_external',
      pointB: 'tibia_plateau_posterior_internal'
    }
  },
  MEASURE: {
    AKAGI: {
      value: 'akagi',
      display: 'Ligne AP d\'Akagi',
    },
    POSTERIOR: {
      value: 'posterior_plateau',
      display: 'Bord plateau postérieur',
    }
  }
}

export const PATELLA_REF = {
  REF: {
    POSTERIOR: {
      value: 'posterior',
      display: 'Postérieure',
      point: 'patella_posterior'
    },
    ANTERIOR: {
      value: 'anterior',
      display: 'Antérieure',
      point: 'patella_anterior'
    },
  }
}

import { Injectable } from '@angular/core';
import {FEMUR_REF, TIBIA_REF} from "@app/shared/constant/ref_name.constant";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor() { }

  public light = false;

  public maintain = false;

  public pageList = [
    {
      value: 'preop',
      display: 'Vue pré-operatoire'
    },
    {
      value: 'planif',
      display: 'Planification'
    }
  ];

  public page = {
    value: 'preop',
    display: 'Vue pré-operatoire'
  };

  public femurRefList = [
    {
      value: FEMUR_REF.REF.ANTERIOR.value,
      display: FEMUR_REF.REF.ANTERIOR.display
    },
    {
      value: FEMUR_REF.REF.POSTERIOR.value,
      display: FEMUR_REF.REF.POSTERIOR.display
    }
  ];

  public femurRef = {
    value: FEMUR_REF.REF.POSTERIOR.value,
    display: FEMUR_REF.REF.POSTERIOR.display
  };


  public femurRotList = [
    {
      value: FEMUR_REF.ROT.MECA.value,
      display: FEMUR_REF.ROT.MECA.display
    },
    {
      value: FEMUR_REF.ROT.POST.value,
      display: FEMUR_REF.ROT.POST.display
    }
  ]

  public femurRot = {
    value: FEMUR_REF.ROT.MECA.value,
    display: FEMUR_REF.ROT.MECA.display
  }

  public femurAxeList = [
    {
      value: FEMUR_REF.MEASURE.EPICONDYLE_ANATOMICAL.value,
      display: FEMUR_REF.MEASURE.EPICONDYLE_ANATOMICAL.display
    },
    {
      value: FEMUR_REF.MEASURE.EPICONDYLE_SURGICAL.value,
      display: FEMUR_REF.MEASURE.EPICONDYLE_SURGICAL.display
    },
    {
      value: FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.value,
      display: FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.display
    },
    {
      value: FEMUR_REF.MEASURE.WHITESIDE.value,
      display: FEMUR_REF.MEASURE.WHITESIDE.display
    }
  ];

  public femurAxe = {
    value: FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.value,
    display: FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.display
  };

  public femurRangeList = [
    {
      value: "madison_ps_evo",
      display: "MADISON PS EVO"
    }
  ];

  public femurRange = {
    value: "madison_ps_evo",
    display: "MADISON PS EVO"
  };

  public femurSizeList = [
    {
      value: "t1",
      display: "T1"
    },
    {
      value: "t2",
      display: "T2"
    },
    {
      value: "t3",
      display: "T3"
    },
    {
      value: "t4",
      display: "T4"
    },
    {
      value: "t5",
      display: "T5"
    },
    {
      value: "t6",
      display: "T6"
    },
    {
      value: "t7",
      display: "T7"
    },
    {
      value: "t8",
      display: "T8"
    }
  ];

  public femurSize = {
    value: "t1",
    display: "T1"
  };

  public kneecapRefList = [
    {
      value: "anterior",
      display: "Antérieure"
    },
    {
      value: "posterior",
      display: "Postérieure"
    }
  ];

  public kneecapRef = {
    value: "anterior",
    display: "Antérieure"
  };

  public kneecapAxeList = [
    {
      value: "ligne_epicondyles",
      display: "Ligne épicondyles"
    },
    {
      value: "condyle_posterior",
      display: "Condyles postérieurs"
    },
    {
      value: "whiteside_line",
      display: "Ligne de Whiteside"
    }
  ];

  public kneecapAxe = {
    value: "ligne_epicondyles",
    display: "Ligne épicondyles"
  };

  public kneecapSizeList = [
    {
      value: "t1",
      display: "T1"
    },
    {
      value: "t2",
      display: "T2"
    },
    {
      value: "t3",
      display: "T3"
    },
    {
      value: "t4",
      display: "T4"
    },
    {
      value: "t5",
      display: "T5"
    },
    {
      value: "t6",
      display: "T6"
    },
    {
      value: "t7",
      display: "T7"
    },
    {
      value: "t8",
      display: "T8"
    }
  ];

  public kneecapSize = {
    value: "t1",
    display: "T1"
  };

  public tibiaRefList = [
    {
      value: TIBIA_REF.REF.POSTERIOR.value,
      display: TIBIA_REF.REF.POSTERIOR.display
    },
    {
      value: TIBIA_REF.REF.AKAGI.value,
      display: TIBIA_REF.REF.AKAGI.display
    }
  ];

  public tibiaRef = {
    value: TIBIA_REF.REF.AKAGI.value,
    display: TIBIA_REF.REF.AKAGI.display
  };

  public tibiaAxeList = [
    {
      value: TIBIA_REF.MEASURE.POSTERIOR.value,
      display: TIBIA_REF.MEASURE.POSTERIOR.display
    },
    {
      value: TIBIA_REF.MEASURE.AKAGI.value,
      display: TIBIA_REF.MEASURE.AKAGI.display
    }
  ];

  public tibiaAxe = {
    value: TIBIA_REF.MEASURE.POSTERIOR.value,
    display: TIBIA_REF.MEASURE.POSTERIOR.display
  };

  public tibiaRangeList = [
    {
      value: "madison_fixe",
      display: "MADISON Fixe"
    },
    {
      value: "madison_mobile",
      display: "MADISON Mobile"
    }
  ];

  public tibiaRange = {
    value: "madison_fixe",
    display: "MADISON Fixe"
  };

  public tibiaSizeList = [
    {
      value: "taille_2",
      display: "taille 2"
    },
    {
      value: "taille_3",
      display: "taille 3"
    },
    {
      value: "taille_4",
      display: "taille 4"
    },
    {
      value: "taille_5",
      display: "taille 5"
    }
  ];

  public tibiaSize = {
    value: "taille_2",
    display: "taille 2"
  };

}

import {Injectable} from '@angular/core';
import * as THREE from 'three';

@Injectable({
  providedIn: 'root'
})
export class LightService {

  constructor() {
  }

  create(): THREE.Object3D {
    const lightHolder = new THREE.Object3D();

    const lightHemi1 = new THREE.HemisphereLight(0xfef8eb, 0xfffce4, 0.5); //new THREE.AmbientLight(0x404040);
    lightHemi1.position.set(-15,35,125);
    lightHolder.add(lightHemi1);

    const lightHemi2 = new THREE.HemisphereLight(0xccc6b5, 0xfff2be, 0.3); //new THREE.AmbientLight(0x404040);
    lightHemi2.position.set(70,-30,-200);
    lightHolder.add(lightHemi2);

    return lightHolder;
  }

  createCustomFemurDistal(): THREE.Object3D {
    const lightHolder = new THREE.Object3D();

    //lateral projector
    const lightDistalLateral = new THREE.SpotLight(0xffffff,0.2,0,1,0, 1);
    lightDistalLateral.position.set(-120,0,-100)

    const lightSup = new THREE.SpotLight(0xffffff,0.2,0,1,0, 1);
    lightSup.position.set(-100,100,-50)
    //lateral back
    const lightDistalMedial = new THREE.SpotLight(0xffffff,0.1,0,1, 0, 1)
    lightDistalMedial.position.set(100,100,-50)

    lightHolder.add(lightDistalLateral, lightSup, lightDistalMedial);

    return lightHolder
  }

  createCustomFemurFront(): THREE.Object3D {
    const lightHolder = new THREE.Object3D();

    //lateral projector
    const lightDistal = new THREE.SpotLight(0xffffff,0.4,0,1, 0, 1)
    lightDistal.position.set(100,100,-50)
    //sup projector
    const lightSup = new THREE.SpotLight(0xffffff,0.2,0,1, 0, 1)
    lightSup.position.set(-30,100,100)

    lightHolder.add(lightDistal, lightSup);

    return lightHolder
  }

  createCustomFemurSagittal(): THREE.Object3D {
    const lightHolder = new THREE.Object3D();

    //lateral projector 1
    const lightLat1 = new THREE.SpotLight(0xffffff,0.4,0,1, 0, 1)
    lightLat1.position.set(100,100,-50)
    //lateral projector 2
    const lightLat2= new THREE.SpotLight(0xffffff,0.3,0,1, 0, 1)
    lightLat2.position.set(-100,100,-50)
    //sup projector
    const lightSup = new THREE.SpotLight(0xffffff,0.2,0,1, 0, 1)
    lightSup.position.set(-30,100,100)

    lightHolder.add(lightLat1, lightLat2, lightSup);

    return lightHolder
  }

  createCustomTibiaProximal(): THREE.Object3D {
    const lightHolder = new THREE.Object3D();

    //lateral projector
    const lightLateral1 = new THREE.SpotLight(0xffffff,0.2,0,1,0, 1);
    lightLateral1.position.set(-120,0,100)
    //lateral projector2
    const lightLateral2 = new THREE.SpotLight(0xffffff,0.1,0,1, 0, 1)
    lightLateral2.position.set(70,0,50)
    //light back
    const lightBack = new THREE.SpotLight(0xffffff,0.3,0,1, 0, 1)
    lightBack.position.set(-100,-100,20)

    lightHolder.add(lightLateral1, lightLateral2, lightBack);

    return lightHolder
  }

  createCustomTibiaFront(): THREE.Object3D {
    const lightHolder = new THREE.Object3D();

    //lateral projector
    const lightDistal = new THREE.SpotLight(0xffffff,0.4,0,1, 0, 1)
    lightDistal.position.set(100,100,-50)
    //sup projector
    const lightSup = new THREE.SpotLight(0xffffff,0.2,0,1, 0, 1)
    lightSup.position.set(-100,100,100)

    lightHolder.add(lightDistal, lightSup);

    return lightHolder
  }

  createCustomTibiaTransversal(): THREE.Object3D {
    const lightHolder = new THREE.Object3D();

    //lateral projector 1
    const lightLat1 = new THREE.SpotLight(0xffffff,0.4,0,1, 0, 1)
    lightLat1.position.set(100,100,-50)
    //lateral projector 2
    const lightLat2= new THREE.SpotLight(0xffffff,0.2,0,1, 0, 1)
    lightLat2.position.set(-100,100,-50)
    //sup projector
    const lightSup = new THREE.SpotLight(0xffffff,0.1,0,1, 0, 1)
    lightSup.position.set(-30,-30,100)
    //light back
    const lightBack = new THREE.SpotLight(0xffffff,0.4,0,1, 0, 1)
    lightBack.position.set(-100,-100,0)

    lightHolder.add(lightLat1, lightLat2, lightSup, lightBack);

    return lightHolder
  }


  createCustomOverview(): THREE.Object3D {
    const lightHolder = new THREE.Object3D();

    //lateral projector
    const lightDistalLateral = new THREE.SpotLight(0xffffff,0.2,0,1,0, 1);
    lightDistalLateral.position.set(-120,0,-100)
    const lightSup = new THREE.SpotLight(0xffffff,0.2,0,1,0, 1);
    lightSup.position.set(-100,100,-50)
    //lateral back
    const lightDistalMedial = new THREE.SpotLight(0xffffff,0.1,0,1, 0, 1)
    lightDistalMedial.position.set(100,100,-50)
    // light back
    const lightBack = new THREE.SpotLight(0xffffff,0.4,0,1, 0, 1)
    lightBack.position.set(-100,-100,0)

    lightHolder.add(lightDistalLateral, lightSup, lightDistalMedial, lightBack);

    return lightHolder
  }
}

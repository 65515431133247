import { Injectable } from '@angular/core';
import {PlanifSlicerService} from "@app/ui/screens/planif/planif-slicer.service";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {SelectFemurService} from "@app/shared/component/select-femur/select-femur.service";
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {MathUtils} from "three";
import {FEMUR_REF} from "@app/shared/constant/ref_name.constant";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";

/**
 * Service that contains methods to manipulate (rotation and translation) the femur in the 3 views
 */
@Injectable({
  providedIn: 'root'
})
export class FemurAdjustmentService {

  constructor(private planifSlicerService: PlanifSlicerService, public femurMortaiseService: FemurMortaiseService,
              private selectFemurService: SelectFemurService, private dataSymfonyService: DataSymfonyService,
              private planifViewManagerService: PlanifViewManagerService, private planifViewReferencesService : PlanifViewReferencesService) {
  }

  /**
   * Method that return the value of externalRotation, depending on the axe rot ref chosen, a delta is added to the rotation
   * displayed. The angle is calculated from the X axis (which is the condyle posterior axis)
   */
  getRotationInternalExternal() {
    if (this.selectFemurService.getAxe().value===FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.value) {
      return this.femurMortaiseService.externalRotation;
    }
    else if (this.selectFemurService.getAxe().value===FEMUR_REF.MEASURE.EPICONDYLE_ANATOMICAL.value) {
      const condAxis = this.planifViewReferencesService.getCondylePosteriorAxis()
      const epicondAnatAxis = this.planifViewReferencesService.getEpicondyleAnatomicalAxis()
      return this.femurMortaiseService.externalRotation+Math.round(MathUtils.radToDeg(condAxis.angleTo(epicondAnatAxis)));
    }
    else if (this.selectFemurService.getAxe().value===FEMUR_REF.MEASURE.EPICONDYLE_SURGICAL.value) {
      const condAxis = this.planifViewReferencesService.getCondylePosteriorAxis()
      const epicondSurgAxis = this.planifViewReferencesService.getEpicondyleSurgicalAxis()
      return this.femurMortaiseService.externalRotation+Math.round(MathUtils.radToDeg(condAxis.angleTo(epicondSurgAxis)));
    }
    else {
      const condAxis = this.planifViewReferencesService.getCondylePosteriorAxis()
      const whitesideAxis = this.planifViewReferencesService.getAPWhitesideAxis()
      return this.femurMortaiseService.externalRotation+Math.round(MathUtils.radToDeg(condAxis.angleTo(whitesideAxis)));
    }
  }

  /**
   * Method that return the absolute value of externalRotation
   */
  getAbsoluteRotationInternalExternal() {
    return Math.abs(this.getRotationInternalExternal())
  }

  /**
   * method that decrease the value of externalRotation by 1
   */
  minusRotationInternalExternal() {
    this.femurMortaiseService.externalRotation -= (this.femurMortaiseService.sideModifier);
    this.planifSlicerService.updateFemurCut()
  }

  /**
   * method that increase the value of externalRotation by 1
   */
  plusRotationInternalExternal() {
    this.femurMortaiseService.externalRotation += (this.femurMortaiseService.sideModifier);
    this.planifSlicerService.updateFemurCut()
  }

  /**
   * method that return the value of posteriorResection
   */
  getResectionAnteriorPosterior() {
    if (this.femurMortaiseService.refAnteriorPosterior==FEMUR_REF.REF.ANTERIOR.value) {
      return this.femurMortaiseService.notchingAnterior;
    }
    else {
      return this.femurMortaiseService.posteriorResection;
    }
  }

  /**
   * method that decrease the value of posteriorResection by 1
   */
  minusResectionAnteriorPosterior() {
    if (this.femurMortaiseService.refAnteriorPosterior==FEMUR_REF.REF.ANTERIOR.value) {
      this.femurMortaiseService.notchingAnterior -= 1;
    }
    else {
      this.femurMortaiseService.posteriorResection -= 1;
    }
    this.planifSlicerService.updateFemurCut()
  }

  /**
   * method that increase the value of posteriorResection by 1
   */
  plusResectionAnteriorPosterior() {
    if (this.femurMortaiseService.refAnteriorPosterior==FEMUR_REF.REF.ANTERIOR.value) {
      this.femurMortaiseService.notchingAnterior += 1;
    }
    else {
      this.femurMortaiseService.posteriorResection += 1;
    }
    this.planifSlicerService.updateFemurCut()
  }

  /**
   * method that return the value of varusValgus
   */
  getVarusValgus() {
    return this.femurMortaiseService.varusValgus;
  }

  /**
   * method that return the absolute value of varusValgus
   */
  getAbsoluteVarusValgus() {
    return Math.abs(this.getVarusValgus());
  }

  /**
   * method that decrease the value of varusValgus by 1
   */
  minusVarusValgus() {
    this.femurMortaiseService.varusValgus -= (this.femurMortaiseService.sideModifier);
    this.planifSlicerService.updateFemurCut()
  }

  /**
   * method that increase the value of varusValgus by 1
   */
  plusVarusValgus() {
    this.femurMortaiseService.varusValgus += (this.femurMortaiseService.sideModifier);
    this.planifSlicerService.updateFemurCut()
  }

  /**
   * method that return the value of distalResection
   */
  getResectionDistal() {
    return this.femurMortaiseService.distalResection;
  }

  /**
   * method that decrease the value of distalResection by 1
   */
  minusResectionDistal() {
    this.femurMortaiseService.distalResection -= 1;
    this.planifSlicerService.updateFemurCut()
  }

  /**
   * method that increase the value of distalResection by 1
   */
  plusResectionDistal() {
    this.femurMortaiseService.distalResection += 1;
    this.planifSlicerService.updateFemurCut()
  }

  /**
   * method that return the value of flexionExtension
   */
  getFlexionExtension() {
    return this.femurMortaiseService.flexionExtension ;
  }

  /**
   * method that return the absolute value of flexionExtension
   */
  getAbsoluteFlexionExtension() {
    return Math.abs(this.getFlexionExtension()) ;
  }

  /**
   * method that decrease the value of flexionExtension by 1
   */
  minusFlexionExtension() {
    this.femurMortaiseService.flexionExtension -= 1;
    this.planifSlicerService.updateFemurCut()
  }

  /**
   * method that increase the value of flexionExtension by 1
   */
  plusFlexionExtension() {
    this.femurMortaiseService.flexionExtension += 1;
    this.planifSlicerService.updateFemurCut()
  }
}

import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import * as DATA_USER from '../shared/constant/user.constant';
import * as DATA_JSON from '../shared/constant/json.constant';
import {Euler, MathUtils} from 'three';



@Injectable({
  providedIn: 'root'
})
export class DataSymfonyService {
  data = DATA_JSON.JSON_CASE1;
  /*  data = DATA.INPUT_DS11;*/
  dataUser = DATA_USER.USER;

  constructor() {
    if (this.getEnvironment()) {
      this.data = this.getData();
      this.dataUser = this.getDataUser();
    }
  }

  getEnvironment() {
    return environment.production && this.getData() !== null && this.getDataUser() !== null;
  }

  addSimplifiee() {
    return this.getEnvironment() ? '_simplifiee' : '';
  }

  getPatientData() {
    return this.data.patient;
  }

  getData() {
    return JSON.parse(<string>(sessionStorage.getItem('JSonResponse')));
  }

  getDataUser() {
    return JSON.parse(<string>(sessionStorage.getItem('JSonUser')));
  }

  getIntervention() {
    return this.data.intervention;
  }

  getDirectory() {
    return this.data.path.toObjectDirectory;
  }

  getImplantDirectory() {
    return this.data.path.toImplantDirectory;
  }

  getMortaiseDirectory() {
    return this.data.path.toMortaiseDirectory;
  }

  getPlanification() {
    return this.data.planning;
  }

  getPreplanification() {
    return this.data.preplanning;
  }

  getState() {
    return this.data.intervention.state;
  }

  getRole() {
    return this.dataUser.user.role;
  }

  getPathNII() {
    return 'assets/madison/scene/case_1/case_1.nii';
  }

  getNiiRotation() {
    return this.data.nii.rotations;
  }

  getNiiTranslation() {
    return this.data.nii.translations;
  }

  getNiiRotationEuler() {
    return new Euler(
      -MathUtils.degToRad(this.data.nii.rotations.x),
      -MathUtils.degToRad(this.data.nii.rotations.y),
      -MathUtils.degToRad(this.data.nii.rotations.z),
    )
  }

  getNiiRotationEulerInverse() {
    return new Euler(
      MathUtils.degToRad(this.data.nii.rotations.x),
      MathUtils.degToRad(this.data.nii.rotations.y),
      MathUtils.degToRad(this.data.nii.rotations.z),
    )
  }

  getSide() {
    return this.data.intervention.side;
  }
}

import { Component, OnInit } from '@angular/core';

/**
 * Component defining the left-side screen of the pre-operative page, containing the frontal view of the knee with the
 * HKA angle
 */

@Component({
  selector: 'app-preop',
  templateUrl: './preop.component.html',
  styleUrls: ['./preop.component.scss']
})
export class PreopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

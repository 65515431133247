import {ManagerService} from "@app/services/three/manager.service";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {Observable} from "rxjs";
import {BufferGeometry, Material, Mesh} from "three";
import * as THREE from "three";

/**
 * Class overrided to load objects from the path
 */
export class ParentVirginObject {
  path: string;
  geometry: BufferGeometry;
  name: string;
  mesh: Mesh;
  material: any;

  constructor(public manager: ManagerService) {
  }

  /**
   * @param {string} path Path to the object to load
   * @param {string} name Name given to the futur mesh
   * @param {THREE.Material} material Material given to the object to load
   * @return {Observable} The mesh loaded
   */
  load(path: string, name: string, material: Material): Observable<Mesh> {
    return new Observable(observer => {
      const loader = new OBJLoader(this.manager.manager);
      loader.load(path, (object3d) => {
        // @ts-ignore
        this.geometry = object3d.children[0].geometry;
        this.geometry.computeBoundingSphere();
        this.geometry.computeBoundingBox();
        this.mesh = new THREE.Mesh(this.geometry, material);
        this.mesh.name = name;
        observer.next(this.mesh);
      });
    });
  }
}

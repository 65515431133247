<div class="select-kneecap" [class.disabled]= " globalService.getValided() ">
  <div class="title">
    <i class="icon icon-kneecap"></i>
    <span>ROTULE</span>
  </div>
  <div class="select-row">
    <label>Référence</label>
    <div class="separator"></div>
    <mat-select [(value)]=" ref.value " (selectionChange)="refChange()">
      <mat-option *ngFor=" let ref of refList " [value]=" ref.value ">
          {{ ref.display }}
      </mat-option>
    </mat-select>
  </div>
  <div class="select-row">
    <label>Réf de mesu.</label>
    <div class="separator"></div>
    <mat-select [(value)]=" axe.value " (selectionChange)="measureChange()">
      <mat-option *ngFor=" let axe of axeList " [value]=" axe.value ">
          {{ axe.display }}
      </mat-option>
    </mat-select>
  </div>
  <div class="select-row">
    <label>Taille</label>
    <div class="separator"></div>
    <mat-select [(value)]=" size.value ">
      <mat-option *ngFor=" let size of sizeList " [value]=" size.value ">
          {{ size.display }}
      </mat-option>
    </mat-select>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { PatellaAdjustmentService } from './patella-adjustment.service';
import { ViewsChoiceService } from '../../../services/planif/views-choice.service';
import { GlobalService } from '../../../services/global/global.service';

@Component({
  selector: 'app-patella-adjustment',
  templateUrl: './patella-adjustment.component.html',
  styleUrls: ['./patella-adjustment.component.scss']
})
export class PatellaAdjustmentComponent implements OnInit {

  constructor(public patellaAdjustmentService: PatellaAdjustmentService, public viewsChoiceService: ViewsChoiceService, public globalService: GlobalService ) { }

  ngOnInit(): void {
  }

}

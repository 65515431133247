import { Pipe, PipeTransform } from '@angular/core';
import {PATELLA_BUTTON} from "@app/shared/constant/button_name.constant";

@Pipe({
  name: 'patellaFlexionExtensionDisplay'
})
export class PatellaFlexionExtensionDisplayPipe implements PipeTransform {
  constructor () {
  }

  transform(value: number){
    return value>=0 ? PATELLA_BUTTON.FL : PATELLA_BUTTON.EX
  }
}

<div id="app_toolbar_middle">
  <div class="btn-group-label">
    <span>Actions</span>
  </div>
  <button type="button" id="btn_measure" matTooltip="Mesurer" matTooltipClass="custom-tooltip" matTooltipPosition="below" [class.active]=" measureIsActive " (click)=" measureIsActive = !measureIsActive " [disabled]=" isPreop() ">
    <i class="icon icon-regle"></i>
  </button>
  <button type="button" id="btn_comm" [class.active]=" commService.getCommIsOpen() " (click)=" commService.toggleCommIsOpen() " matTooltip="Ajouter un commentaire" matTooltipClass="custom-tooltip" matTooltipPosition="below">
    <i class="icon icon-commentaire"></i>
    <span class="has-comm" *ngIf=" commService.hasComm() ">1</span>
  </button>
  <button type="button" id="btn_photo" matTooltip="Capture d'écran" matTooltipClass="custom-tooltip" matTooltipPosition="below">
    <i class="icon icon-photo"></i>
  </button>
  <div class="btn-group-label">
    <span>Nav.</span>
  </div>
  <button type="button" id="btn_cancel" matTooltip="Annuler" matTooltipClass="custom-tooltip" matTooltipPosition="below" [disabled]=" globalService.getValided() ">
    <i class="icon icon-cancel"></i>
  </button>
  <button type="button" id="btn_reset" matTooltip="Réinitialiser" matTooltipClass="custom-tooltip" matTooltipPosition="below" (click)=" openReset() " [disabled]=" globalService.getValided() ">
    <i class="icon icon-reset"></i>
  </button>
  <button type="button" id="btn_save" matTooltip="Enregistrer" matTooltipClass="custom-tooltip" matTooltipPosition="below" [disabled]=" globalService.getValided() ">
    <i class="icon icon-save"></i>
  </button>
  <button type="button" id="btn_valid" matTooltip="Valider le cas" [class.valid]=" globalService.getValided() " matTooltipClass="custom-tooltip" matTooltipPosition="below" (click)=" openValid() ">
    <i class="icon icon-valid"></i>
  </button>
</div>

<nav id="app_nav">
  <ul>
    <li>
      <a routerLink="/" class="logo">
        <img src="../../../../assets/madison/img/logo.svg">
        <div class="arrow"></div>
      </a>
    </li>
    <li>
      <a routerLink="preop" routerLinkActive="active">
        <span class="hidden">Vue pré-opératoire</span>
        <span class="absolute">Vue pré-opératoire</span>
        <div class="arrow"></div>
      </a>
    </li>
    <li>
      <a routerLink="planif" routerLinkActive="active">
        <span class="hidden">Planification</span>
        <span class="absolute">Planification</span>
        <div class="arrow"></div>
      </a>
    </li>
  </ul>
</nav>

import {Injectable} from '@angular/core';
import {Implant} from '../class/implant';
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {ManagerService} from "@app/services/three/manager.service";
import {MaterialService} from "@app/services/three/material.service";
import {Utils} from "@app/services/class/Utils";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";

@Injectable({
  providedIn: 'root'
})
/**
 * Service used to load and manipulate the plate implant
 */
export class PlateService extends Implant {
  constructor(dataSymfonyService: DataSymfonyService, manager: ManagerService,
              public materialService: MaterialService, private planifViewReferencesService : PlanifViewReferencesService,
              private tibiaMortaiseService: TibiaMortaiseService) {
    super(manager, dataSymfonyService);
    this.name = 'plate';
    this.size= this.dataSymfonyService.getPlanification().size_tibia
    this.range= this.dataSymfonyService.getPlanification().range_tibia
    this.url = `${location.origin}/${this.dir}/${this.name}_${this.range}_${this.size}.obj`;
    this.material = this.materialService.createPlateMaterial()
  }

  //TODO : service Position ou ce service
  /**
   * This set the initial position of the plate (cut = 0.0mm) in the scene called
   * @param {THREE.scene} scene The scene on which the method is called
   */
  setPlateInitialPosition(scene) {
    let plate = Utils.getMeshByName(scene, 'plate')

    this.tibiaMortaiseService.updateTibiaPosition([plate], scene)
    this.tibiaMortaiseService.updateTibiaRotation([plate], scene)
  }
}

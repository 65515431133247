import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {FemurViewSagittalService} from "@app/ui/screens/planif/pl-panel-right/femur-view-sagittal/femur-view-sagittal.service";

/**
 * Component that defines the sagittal view (n°3) of the femur
 */

@Component({
  selector: 'app-femur-view-sagittal',
  templateUrl: './femur-view-sagittal.component.html',
  styleUrls: ['./femur-view-sagittal.component.scss']
})
export class FemurViewSagittalComponent implements OnInit {

  @ViewChild('femurViewSagittal', {static: true})
  public femurViewSagittal: ElementRef<HTMLCanvasElement>;

  count=0;
  value7 = 8.5;
  targetPoint = 'femur_mid'

  constructor(private femurViewSagittalService: FemurViewSagittalService) {}

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    console.log(event);
    if (event.deltaY > 0) {
      this.count++
    } else {
      this.count--;
    }
    console.log(this.count);
    this.femurViewSagittalService.scroll(this.count);
  }

  ngOnInit(): void {
    this.femurViewSagittalService.createScene(this.femurViewSagittal);
    this.femurViewSagittalService.loadObject();
    this.femurViewSagittalService.loadImplants();
    //this.femurViewSagittalService.loadImages(this);
    this.femurViewSagittalService.animate();
  }

  ngOnChanges(): void {
    this.femurViewSagittalService.resize();
  }
}

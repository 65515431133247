import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {FemurViewFrontService} from "@app/ui/screens/planif/pl-panel-right/femur-view-front/femur-view-front.service";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";

/**
 * Component that defines the front view (n°2) of the femur
 */

@Component({
  selector: 'app-femur-view-front',
  templateUrl: './femur-view-front.component.html',
  styleUrls: ['./femur-view-front.component.scss']
})
export class FemurViewFrontComponent implements OnInit {

  @ViewChild('femurViewFront', {static: true})
  public femurViewFront: ElementRef<HTMLCanvasElement>;
  count=0
  targetPoint= 'femur_mid';

  constructor(public femurViewFrontService: FemurViewFrontService, private femurMortaiseService: FemurMortaiseService) {

  }

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    console.log(event);
    if (event.deltaY > 0) {
      this.count++
    } else {
      this.count--;
    }
    console.log(this.count);
    this.femurViewFrontService.scroll(this.count);
  }
  ngOnInit(): void {
    this.femurViewFrontService.createScene(this.femurViewFront);
    this.femurViewFrontService.loadObject();
    this.femurViewFrontService.loadImplants();
    //this.femurViewFrontService.loadImages(this);
    this.femurViewFrontService.animate();
  }

  ngOnChanges(): void {
    this.femurViewFrontService.resize();
  }

  /**
   * This method indicates where the lateral and medial sides are, which depends on the operated knee side. The medial
   * side is displayed on the left for a left knee, on the right for a right knee
   * @return {string} Returns 'L' if the case is a right knee, 'M' otherwise.
   */
  getLeftSideIndicator() {
    if (this.femurMortaiseService.side == 'right') {
      return 'L'
    }
    else {
      return 'M'
    }
  }

  /**
   * This method indicates where the lateral and medial sides are, which depends on the operated knee side
   * @return {string} Returns 'M' if the case is a right knee, 'L' otherwise.
   */
  getRightSideIndicator() {
    if (this.femurMortaiseService.side == 'right') {
      return 'M'
    }
    else {
      return 'L'
    }
  }
}

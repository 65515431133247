import {ManagerService} from '../three/manager.service';
import {DataSymfonyService} from '@app/api/data-symfony.service';
import {Injectable} from '@angular/core';
import {ParentVirginObject} from '@app/services/class/parentVirginObject';
import {Observable} from "rxjs";
import {Mesh} from "three";

@Injectable({
  providedIn: 'root'
})

/**
 * Class used to be overrided for mortaise loading
 */
export class Mortaise extends ParentVirginObject {

  dir: string;
  state: string;
  addSimplifiee: string;
  side: string


  constructor(manager: ManagerService,  public dataSymfonyService: DataSymfonyService) {
    super(manager);
    this.state = this.dataSymfonyService.getIntervention().state;
    this.dir = this.dataSymfonyService.getMortaiseDirectory()
    this.addSimplifiee = this.dataSymfonyService.addSimplifiee();
    this.side = this.dataSymfonyService.getSide();

  }

  /**
   * This method is used to load the mortaise
   * @param {string} size The size of the mortaise loaded (if the mortaise is a tibia mortaise, size will be '')
   * @return {Observable} The mesh of the mortaise loaded
   */
  loadMortaise(size: string): Observable<Mesh> {
    const url =`${location.origin}/${this.dir}/${this.name}${size}.obj`;
    return super.load(url, this.name, this.material);
  }

}

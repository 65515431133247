import { Injectable } from '@angular/core';

/**
 * Service defining the state of the different infos panel on the pre-operative page. If set as true, the panel is showed,
 * if false the panel is hide
 */
@Injectable({
  providedIn: 'root'
})
export class TooltipsService {

  constructor() { }

  private tt = {
    mldfa: false,
    mpta: false,
    hks: false,
    varus: false,
    flexion: false,
    tibialSlope: false
  }
  /**
   * method that returns the state of all the tooltips
   */
  getState() {
    return this.tt;
  }
}

<div id="infos_patient">
  <div>
    <div class="ip-picto">
      <i class="icon icon-infos-patient"></i>
    </div>
    <div class="ip-title">
      Infos<br> Patient
    </div>
  </div>
  <div>
    <div>
      <span class="ip-value highlighted bold">{{getSide()}} </span><span class="ip-value bold">{{getImgType()}}</span>
    </div>
    <div>
      <span class="ip-label">Réf chirurgie : </span><span class="ip-value">{{getRef()}}</span>
    </div>
  </div>
  <div>
    <div>
      <span class="ip-label">Nom : </span><span class="ip-value">{{getPatientLastName()}}</span>
    </div>
    <div>
      <span class="ip-label">Prénom : </span><span class="ip-value">{{getPatientFirstName()}}</span>
    </div>
  </div>
  <div>
    <div>
      <span class="ip-label">Né le : </span><span class="ip-value">{{getPatientBirthDate()}}</span>
    </div>
    <div>
      <span class="ip-label">Int. : </span><span class="ip-value highlighted bold">{{getInterventionDate()}}</span>
    </div>
    <!--<span class="side">{{getSideInitial()}}</span>-->
  <div>
</div>

import {ManagerService} from '../three/manager.service';
import {DataSymfonyService} from '@app/api/data-symfony.service';
import {Injectable} from '@angular/core';
import {ParentVirginObject} from '@app/services/class/parentVirginObject';

@Injectable({
  providedIn: 'root'
})
/**
 * Class used to be overrided for points loading
 */
export class VirginBone extends ParentVirginObject {

  dir: string;
  state: string;
  addSimplifiee: string;
  side: string


  constructor(manager: ManagerService,  public dataSymfonyService: DataSymfonyService) {
    super(manager);
    this.state = this.dataSymfonyService.getIntervention().state;
    this.dir = this.dataSymfonyService.getDirectory();
    this.addSimplifiee = this.dataSymfonyService.addSimplifiee();
    this.side = this.dataSymfonyService.getSide();
  }
}

import {Injectable} from "@angular/core";
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {ManagerService} from "@app/services/three/manager.service";
import {Observable} from "rxjs";
import {Mesh} from "three";
import {MaterialService} from "@app/services/three/material.service";
import {VirginBone} from "@app/services/class/virginBone";

@Injectable({
  providedIn: 'root'
})

/**
 * Service used to load points
 */
export class PointService extends VirginBone {

  constructor(dataSymfonyService: DataSymfonyService,   manager: ManagerService, public materialService: MaterialService) {
    super(manager, dataSymfonyService)

  }

  /**
   * This method overrides the load methods to load a specific point visible or not
   * @param {string} name The name of the point to load
   * @param {boolean} displayed True if the point will be displayed, false else
   * @return {Observable} The mesh of the point loaded
   */
  // @ts-ignore
   load(name: string, displayed: boolean): Observable<Mesh> {
    const url =`${location.origin}/${this.dir}/${name}${this.addSimplifiee}_point.obj`;
    this.material =  displayed ? this.materialService.createPointVisibleMaterial() : this.materialService.createPointInvisibleMaterial();
    return super.load(url, name, this.material);
  }
}

import { Component, OnInit } from '@angular/core';
import { SelectFemurService } from './select-femur.service';
import { GlobalService } from '../../../services/global/global.service';
import {FemurViewDistalService} from "@app/ui/screens/planif/pl-panel-right/femur-view-distal/femur-view-distal.service";
import {PlanifLoaderService} from "@app/ui/screens/planif/planif-loader.service";
import {PlanifSlicerService} from "@app/ui/screens/planif/planif-slicer.service";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {
  FemurViewSagittalService
} from "@app/ui/screens/planif/pl-panel-right/femur-view-sagittal/femur-view-sagittal.service";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";

/**
 * Component used on the planification page to define the femoral view reference for the implant, and choose the size
 * and type of femoral implant
 */

@Component({
  selector: 'app-select-femur',
  templateUrl: './select-femur.component.html',
  styleUrls: ['./select-femur.component.scss']
})
export class SelectFemurComponent implements OnInit {

  public refList;
  public ref;
  public rotList;
  public rot;
  public axeList;
  public axe;
  public rangeList;
  public range;
  public sizeList;
  public size;

  constructor( private selectFemurService: SelectFemurService, public globalService: GlobalService,
               private femurViewDistalService: FemurViewDistalService, private femurViewSagittalService: FemurViewSagittalService,
               private planifLoaderService: PlanifLoaderService, private planifSlicerService: PlanifSlicerService,
               private femurMortaiseService: FemurMortaiseService, private planifViewManagerService: PlanifViewManagerService,
               private planifViewReferencesService: PlanifViewReferencesService) {

    this.refList = selectFemurService.getRefList();
    this.ref = selectFemurService.getRef();
    this.rotList = selectFemurService.getRotList();
    this.rot = selectFemurService.getRot()
    this.axeList = selectFemurService.getAxeList();
    this.axe = selectFemurService.getAxe();
    this.rangeList = selectFemurService.getRangeList();
    this.range = selectFemurService.getRange();
    this.sizeList = selectFemurService.getSizeList();
    this.size = selectFemurService.getSize();
  }

  ngOnInit(): void {
  }

  refChange() {
    this.planifViewReferencesService.setCurrentFemurRefPoint(this.ref.value, this.femurViewSagittalService.scene)
    this.femurMortaiseService.refAnteriorPosterior = this.ref.value
    this.femurMortaiseService.posteriorResection = 8;
    this.femurMortaiseService.notchingAnterior = 0;
    this.planifSlicerService.updateFemurCut()
  }

  measureChange() {
    this.planifViewReferencesService.setCurrentFemurMeasureAxis(this.axe.value, this.femurViewDistalService.scene)
  }

  rotChange() {
    this.planifViewReferencesService.setCurrentFemurRotationAxis(this.rot.value, this.femurViewDistalService.scene)
    this.planifSlicerService.updateFemurCut()
  }
}

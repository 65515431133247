import { Injectable } from '@angular/core';
import {TIBIA_REF} from "@app/shared/constant/ref_name.constant";

/**
 * Service that contains methods for the reference selection and implant choice for the tibia
 */
@Injectable({
  providedIn: 'root'
})
export class SelectTibiaService {

  constructor() { }

  private refList = [
    {
      value: TIBIA_REF.REF.POSTERIOR.value,
      display: TIBIA_REF.REF.POSTERIOR.display
    },
    {
      value: TIBIA_REF.REF.AKAGI.value,
      display: TIBIA_REF.REF.AKAGI.display
    }
  ];

  private ref = {
    value: TIBIA_REF.REF.AKAGI.value,
    display: TIBIA_REF.REF.AKAGI.display
  };

  /**
   * method that returns the value of ref. ref design the referential (anterior/posterior)
   */
  getRef() {
    return this.ref;
  }

  /**
   * method that returns the list of referential : anterior and posterior
   */
  getRefList() {
    return this.refList;
  }


  private axeList = [
    {
      value: TIBIA_REF.MEASURE.POSTERIOR.value,
      display: TIBIA_REF.MEASURE.POSTERIOR.display
    },
    {
      value: TIBIA_REF.MEASURE.AKAGI.value,
      display: TIBIA_REF.MEASURE.AKAGI.display
    }
  ];

  private axe =  {
    value: TIBIA_REF.MEASURE.AKAGI.value,
    display: TIBIA_REF.MEASURE.AKAGI.display
  };

  /**
   * method that returns the value of axe. axe design the axe on which the tibia is lined
   */
  getAxe() {
    return this.axe;
  }

  /**
   * method that returns the list of the axes : Cobb, posterior condyle line, LCP/TMT
   */
  getAxeList() {
    return this.axeList;
  }

  private rangeList = [
    {
      value: "madison_fixe",
      display: "MADISON Fixe"
    },
    {
      value: "madison_mobile",
      display: "MADISON Mobile"
    }
  ];

  private range = {
    value: "madison_fixe",
    display: "MADISON Fixe"
  };

  /**
   * method that returns the value of range. range represents the type of tibial implant chosen.
   */
  getRange() {
    return this.range;
  }

  /**
   * method that returns the list of the tibial implants.
   */
  getRangeList() {
    return this.rangeList;
  }

  private sizeList = [
    {
      value: "taille_2",
      display: "taille 2"
    },
    {
      value: "taille_3",
      display: "taille 3"
    },
    {
      value: "taille_4",
      display: "taille 4"
    },
    {
      value: "taille_5",
      display: "taille 5"
    }
  ];

  private size = {
    value: "taille_2",
    display: "taille 2"
  };

  /**
   * method that returns the value of size. size reprents the size of the tibial implant
   */
  getSize() {
    return this.size;
  }

  /**
   * method that returns the list of sizes available for the tibial implant.
   */
  getSizeList() {
    return this.sizeList;
  }
}

import { Component, OnInit } from '@angular/core';
import { SelectPatellaService } from './select-patella.service';
import { GlobalService } from '../../../services/global/global.service';
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {PlanifSlicerService} from "@app/ui/screens/planif/planif-slicer.service";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {FemurViewSagittalService} from "@app/ui/screens/planif/pl-panel-right/femur-view-sagittal/femur-view-sagittal.service";
import {SelectFemurService} from "@app/shared/component/select-femur/select-femur.service";
import {FemurViewDistalService} from "@app/ui/screens/planif/pl-panel-right/femur-view-distal/femur-view-distal.service";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";

@Component({
  selector: 'app-select-patella',
  templateUrl: './select-patella.component.html',
  styleUrls: ['./select-patella.component.scss']
})
export class SelectPatellaComponent implements OnInit {

  public refList;
  public ref;
  public axeList;
  public axe;
  public sizeList;
  public size;

  constructor(selectKneecapService: SelectPatellaService, public globalService: GlobalService,
              private patellaMortaiseService: PatellaMortaiseService, private planifSlicerService: PlanifSlicerService,
              private planifViewManagerService: PlanifViewManagerService, private femurViewSagittalService: FemurViewSagittalService,
              private selectFemurService: SelectFemurService, private femurViewDistalService: FemurViewDistalService,
              private planifViewReferencesService: PlanifViewReferencesService) {

    this.refList = selectKneecapService.getRefList();
    this.ref = selectKneecapService.getRef();
    this.axeList = this.selectFemurService.getAxeList();
    this.axe = this.selectFemurService.getAxe();
    this.sizeList = selectKneecapService.getSizeList();
    this.size = selectKneecapService.getSize();
  }

  ngOnInit(): void {
  }

  refChange() {
    this.patellaMortaiseService.refAnteriorPosterior = this.ref.value
    this.planifViewReferencesService.setCurrentPatellaRefPoint(this.ref.value, this.femurViewSagittalService.scene)
    this.patellaMortaiseService.posteriorResection = 8;
    this.patellaMortaiseService.anteriorWidth = 10;
    this.planifSlicerService.updatePatellaCut()
  }

  measureChange() {
    this.planifViewReferencesService.setCurrentFemurMeasureAxis(this.axe.value, this.femurViewDistalService.scene)
  }
}

<div class="tibia-adjustment" [class.horizontal]=" viewsChoiceService.getFemurTibias() == 'all' " [class.vertical]=" viewsChoiceService.getFemurTibias() != 'all' " [class.disabled]= " globalService.getValided() ">
  <div class="adjustment-item">
    <div class="title">
      {{tibiaAdjustmentService.getRotationInternalExternal() | tibiaExternalInternalDisplay}}
    </div>
    <div class="adjustment-row">
      <button type="button" class="minus" (click)=" tibiaAdjustmentService.minusRotationInternalExternal() ">
        {{tibiaAdjustmentService.getRotationInternalExternal() | buttonLeftDisplay : this.tibiaAdjustmentService.tibiaMortaiseService.sideModifier}}
      </button>
      <div class="value">
        {{ tibiaAdjustmentService.getAbsoluteRotationInternalExternal()  }}°
      </div>
      <button type="button" class="plus" (click)=" tibiaAdjustmentService.plusRotationInternalExternal() ">
        {{tibiaAdjustmentService.getRotationInternalExternal() | buttonRightDisplay : this.tibiaAdjustmentService.tibiaMortaiseService.sideModifier}}
      </button>
    </div>
  </div>
  <div class="separator"></div>
  <div class="adjustment-item">
    <div class="title">
      {{tibiaAdjustmentService.getVarusValgus() | tibiaVarusValgusDisplay}}
    </div>
    <div class="adjustment-row">
      <button type="button" class="minus" (click)=" tibiaAdjustmentService.minusVarusValgus() ">
        {{tibiaAdjustmentService.getVarusValgus() | buttonLeftDisplay : this.tibiaAdjustmentService.tibiaMortaiseService.sideModifier}}
      </button>
      <div class="value">
        {{ tibiaAdjustmentService.getAbsoluteVarusValgus() }}°
      </div>
      <button type="button" class="plus" (click)=" tibiaAdjustmentService.plusVarusValgus() ">
        {{tibiaAdjustmentService.getVarusValgus() | buttonRightDisplay : this.tibiaAdjustmentService.tibiaMortaiseService.sideModifier}}
      </button>
    </div>
  </div>
  <div class="separator"></div>
  <div class="adjustment-item">
    <div class="title">
      Résection tibiale
    </div>
    <div class="adjustment-row">
      <button type="button" class="minus" (click)=" tibiaAdjustmentService.minusResectionTibial() ">
        -
      </button>
      <div class="value">
        {{ tibiaAdjustmentService.getResectionTibial() }} mm
      </div>
      <button type="button" class="plus" (click)=" tibiaAdjustmentService.plusResectionTibial() ">
        +
      </button>
    </div>
  </div>
  <div class="separator"></div>
  <div class="adjustment-item">
    <div class="title">
      Pente tibiale
    </div>
    <div class="adjustment-row">
      <button type="button" class="minus" (click)=" tibiaAdjustmentService.minusTibialSlope() ">
        -
      </button>
      <div class="value">
        {{ tibiaAdjustmentService.getAbsoluteTibialSlope() }}°
      </div>
      <button type="button" class="plus" (click)=" tibiaAdjustmentService.plusTibialSlope() ">
        +
      </button>
    </div>
  </div>
  <!--internalExternalRotation: {{ internalExternalRotation }}<br>
  varusValgus: {{ varusValgus }}<br>
  proximalResection: {{ proximalResection }}<br>
  tibialSlope: {{ tibialSlope }}-->
</div>

import {Component, ElementRef, HostListener, OnChanges, OnInit, ViewChild} from '@angular/core';
import {TibiaViewTransversalService} from "@app/ui/screens/planif/pl-panel-right/tibia-view-transversal/tibia-view-transversal.service";

/**
 * Component that defines the sagittal view (n°3) of the tibia
 */

@Component({
  selector: 'app-tibia-view-transversal',
  templateUrl: './tibia-view-transversal.component.html',
  styleUrls: ['./tibia-view-transversal.component.scss']
})
export class TibiaViewTransversalComponent implements OnInit, OnChanges {

  @ViewChild('tibiaViewTransversal', {static: true})
  public tibialViewTransversal: ElementRef<HTMLCanvasElement>;
  count = 0;
  targetPoint='tibia_spine_center'

  constructor(private tibiaViewTransversalService: TibiaViewTransversalService) {
  }

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    console.log(event);
    if (event.deltaY > 0) {
      this.count++
    } else {
      this.count--;
    }
    console.log(this.count);
    this.tibiaViewTransversalService.scroll(this.count);
  }


  ngOnInit(): void {
    this.tibiaViewTransversalService.createScene(this.tibialViewTransversal);
    this.tibiaViewTransversalService.loadObject();
    this.tibiaViewTransversalService.loadImplants();
    //this.tibiaViewTransversalService.loadImages(this);
    this.tibiaViewTransversalService.animate();
  }

  ngOnChanges(): void {
    this.tibiaViewTransversalService.resize();
  }
}

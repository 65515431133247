import { Injectable } from '@angular/core';

/**
 * A service defining the different view choices
 */
@Injectable({
  providedIn: 'root'
})
export class ViewsChoiceService {

  constructor() { }

  private scanner = false;
  private cut = 2;
  private patella = 1;
  private implantFemur = 2;
  private implantTibia = 2;
  private transparency = 2;

  private sagIsInverted = false;

  private femurDist = true;
  private femurFront = true;
  private femurSag = true;
  private tibiaDist = true;
  private tibiaFront = true;
  private tibiaSag = true;

  private panelIsOpen = false;

  /**
   * method that returns the boolean value of panelIsOpen. True if the left panel of the planification page is open,
   * false if it is close
   */
  getPanelIsOpen() {
    return this.panelIsOpen;
  }

  //TODO
  /**
   *
   */
  togglePanelIsOpen() {
    this.panelIsOpen = !this.panelIsOpen;
    this.resetFemursTibias();
  }

  /**
   * method called when the panel is opened
   */
  openPanel() {
    this.panelIsOpen = true;
  }

  /**
   * method that returns the boolean value of sagIsInverted. True if the sagittal view of the femur is inverted,
   * false otherwise
   */
  getSagIsInverted() {
    return this.sagIsInverted;
  }

  /**
   * method called when the sagittal view is inverted
   */
  toggleSagIsInverted() {
    this.sagIsInverted = !this.sagIsInverted;
  }

  /**
   * method that returns the boolean value of scanner. True if the scanner view is displayed, false if it is the 3D view
   */
  getScanner() {
    return this.scanner;
  }

  /**
   * method called when the user switch from Scanner view to 3D view
   */
  toggleScanner() {
    this.scanner = !this.scanner;
  }

  /**
   * method that returns the value of patella. 1 if the patella is shown, 0 if it is hidden
   */
  getPatella() {
    return this.patella;
  }

  /**
   * method called when the user mask/unmask the patella, change the value of patella
   */
  togglePatella() {
    switch( this.patella ) {
      /*case 2:
      this.patella = 1;
      break;*/
      case 1:
      this.patella = 0;
      break;
      case 0:
      this.patella = 1;
      break;
    }
  }

  //TODO
  /**
   * method that returns the value of cut. 2 means that the knee is shown without the cuts, 1 means that the knee is shown
   * with the cuts, 0 means the cuts are shown in transparencies
   */
  getCut() {
    return this.cut;
  }

  /**
   * method called when the user mask/unmask the cuts, change the value of cut
   */
  toggleCut() {
    switch( this.cut ) {
      case 2:
      this.cut = 1;
      break;
      case 1:
      this.cut = 0;
      break;
      case 0:
      this.cut = 2;
      break;
    }
  }

  //TODO
  /**
   * method that returns the value of implant. 2 means that the implants are shown, 1 means that the
   * implants are not shown, 0 means the implants are displayed in transparencies
   */
  getImplantFemur() {
    return this.implantFemur;
  }

  getImplantTibia() {
   return this.implantTibia;
  }

  /**
   * method called when the user mask/unmask the implants, change the value of implant
   */
  toggleImplantFemur() {
    switch( this.implantFemur ) {
      case 2:
      this.implantFemur = 1;
      break;
      case 1:
      this.implantFemur = 0;
      break;
      case 0:
      this.implantFemur = 2;
      break;
    }
  }

  toggleImplantTibia() {
   switch( this.implantTibia ) {
     case 2:
     this.implantTibia = 1;
     break;
     case 1:
     this.implantTibia = 0;
     break;
     case 0:
     this.implantTibia = 2;
     break;
   }
  }

  //TODO
  /**
   * method that returns the bones transparency.
   */
  getTransparency() {
    return this.transparency;
  }

  /**
   * method called when the user mask/unmask the bones, change the value of transparency
   */
  toggleTransparency() {
    switch( this.transparency ) {
      case 2:
      this.transparency = 1;
      break;
      case 1:
      this.transparency = 0;
      break;
      case 0:
      this.transparency = 2;
      break;
    }
  }

  //FEMUR 1

  /**
   * method that returns the value of femurDist. True if the distal view of the femur is shown, false otherwise
   */
  getFemurDist() {
    return this.femurDist;
  }

  /**
   * method called to show/hide the distal view of the femur
   */
  clickFemurDist() {
    if( this.getFemurTibias() == 'all' ) {
      this.femurFront = false;
      this.femurSag = false;
    } else {
      this.femurDist = !this.femurDist;
    }
    this.tibiaDist = false;
    this.tibiaFront = false;
    this.tibiaSag = false;
  }

  //FEMUR 2

  /**
   * method that returns the value of femurFront. True if the front view of the femur is shown, false otherwise
   */
  getFemurFront() {
    return this.femurFront;
  }

  /**
   * method called to show/hide the front view of the femur
   */
  clickFemurFront() {
    if( this.getFemurTibias() == 'all' ) {
      this.femurDist = false;
      this.femurSag = false;
    } else {
      this.femurFront = !this.femurFront;
    }
    this.tibiaDist = false;
    this.tibiaFront = false;
    this.tibiaSag = false;
  }

  //FEMUR 3

  /**
   * method that returns the value of femurSag. True if the sagittal view of the femur is shown, false otherwise
   */
  getFemurSag() {
    return this.femurSag;
  }

  /**
   * method called to show/hide the sagittal view of the femur
   */
  clickFemurSag() {
    if( this.getFemurTibias() == 'all' ) {
      this.femurDist = false;
      this.femurFront = false;
    } else {
      this.femurSag = !this.femurSag;
    }
    this.tibiaDist = false;
    this.tibiaFront = false;
    this.tibiaSag = false;
  }

  //TIBIA 1

  /**
   * method that returns the value of tibiaDist. True if the proximal view of the tibia is shown, false otherwise
   */
  getTibiaDist() {
    return this.tibiaDist;
  }

  /**
   * method called to show/hide the proximal view of the tibia
   */
  clickTibiaDist() {
    if( this.getFemurTibias() == 'all' ) {
      this.tibiaFront = false;
      this.tibiaSag = false;
    } else {
      this.tibiaDist = !this.tibiaDist;
    }
    this.femurDist = false;
    this.femurFront = false;
    this.femurSag = false;
  }

  //TIBIA 2

  /**
   * method that returns the value of tibiaFront. True if the front view of the tibia is shown, false otherwise
   */
  getTibiaFront() {
    return this.tibiaFront;
  }

  /**
   * method called to show/hide the front view of the tibia
   */
  clickTibiaFront() {
    if( this.getFemurTibias() == 'all' ) {
      this.tibiaDist = false;
      this.tibiaSag = false;
    } else {
      this.tibiaFront = !this.tibiaFront;
    }
    this.femurDist = false;
    this.femurFront = false;
    this.femurSag = false;
  }

  //TIBIA 3

  /**
   * method that returns the value of tibiaSag. True if the sagittal view of the tibia is shown, false otherwise
   */
  getTibiaSag() {
    return this.tibiaSag;
  }

  /**
   * method called to show/hide the sagittal view of the tibia
   */
  clickTibiaSag() {
    if( this.getFemurTibias() == 'all' ) {
      this.tibiaDist = false;
      this.tibiaFront = false;
    } else {
      this.tibiaSag = !this.tibiaSag;
    }
    this.femurDist = false;
    this.femurFront = false;
    this.femurSag = false;
  }

  //TODO
  //Tous
  /**
   *
   */
  getFemurTibias() {
    if( this.femurDist && this.femurFront && this.femurSag && this.tibiaDist && this.tibiaFront && this.tibiaSag ) {
      return 'all';
    }
    let count = 0;
    if( this.femurDist ) count++;
    if( this.femurFront ) count++;
    if( this.femurSag ) count++;
    if( this.tibiaDist ) count++;
    if( this.tibiaFront ) count++;
    if( this.tibiaSag ) count++;
    if( count == 0 ) {
      this.resetFemursTibias();
      return 'all'
    }
    return 'count-' + count;
  }

  /**
   * method called to show all views (tibia and femur)
   */
  resetFemursTibias() {
    this.femurDist = true;
    this.femurFront = true;
    this.femurSag = true;
    this.tibiaDist = true;
    this.tibiaFront = true;
    this.tibiaSag = true;
  }

  //Toggle all
  /**
   * method called to show/hide the three view of the femur
   */
  clickFemurs() {
    if( !this.getPanelIsOpen() ) this.openPanel();
    if( this.femurDist && this.femurFront && this.femurSag && this.getFemurTibias() != 'all' ) {
      this.femurDist = false;
      this.femurFront = false;
      this.femurSag = false;
    } else {
      this.femurDist = true;
      this.femurFront = true;
      this.femurSag = true;
    }
    this.tibiaDist = false;
    this.tibiaFront = false;
    this.tibiaSag = false;
  }

  /**
   * method called to show/hide the three view of the tibia
   */
  clickTibias() {
    if( !this.getPanelIsOpen() ) this.openPanel();
    if( this.tibiaDist && this.tibiaFront && this.tibiaSag && this.getFemurTibias() != 'all' ) {
      this.tibiaDist = false;
      this.tibiaFront = false;
      this.tibiaSag = false;
    } else {
      this.tibiaDist = true;
      this.tibiaFront = true;
      this.tibiaSag = true;
    }
    this.femurDist = false;
    this.femurFront = false;
    this.femurSag = false;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {FEMUR_BUTTON} from "@app/shared/constant/button_name.constant";

@Pipe({
  name: 'femurVarusValgusDisplay'
})
export class FemurVarusValgusDisplayPipe implements PipeTransform {
  constructor (private femurMortaiseService: FemurMortaiseService) {
  }

  transform(value: number){
    return (value*this.femurMortaiseService.sideModifier)>=0 ? FEMUR_BUTTON.VAL : FEMUR_BUTTON.VAR
  }
}

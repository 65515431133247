import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buttonRightDisplay'
})
export class ButtonRightDisplayPipe implements PipeTransform {

  transform(value: number, sideModifier: number): unknown {
    return (sideModifier*value >= 0) ? "+" : "-"
  }
}

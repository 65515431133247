export const MORTAISE_MEASURE = {
  femur:{
    deltaYintPost: 16.014, //13
    deltaYextPost: 26.014, //23
    deltaYintAnt: -24.375,
    deltaYextAnt: -34.375,
    deltaZint: 8,
    deltaZext: -10,
    l:10,
    h:73,
    L:100,
    slopeAnt:3.5,
    slopePost:1.5,
    pointAnt1: [49.999908, 24.284500, 73.005096],
    pointAnt2: [49.840080, 21.245918, 20.960489],
    pointAnt3: [-49.999779, 21.245762, 20.866207],
  },
  tibia:{
    deltaZint:-10,
    deltaZext:20
  },
  patella:{ //Size 10
    deltaZ: 7.5,
    hZ: 55,
    deltaY: 38,
    hY: 22,
    hX: 55
  }
}

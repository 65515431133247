import { Component, OnInit } from '@angular/core';
import { ViewsChoiceService } from '../../../../services/planif/views-choice.service';
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {TibiaViewFrontService} from "@app/ui/screens/planif/pl-panel-right/tibia-view-front/tibia-view-front.service";
import {FemurViewFrontService} from "@app/ui/screens/planif/pl-panel-right/femur-view-front/femur-view-front.service";
import {
  FemurViewDistalService
} from "@app/ui/screens/planif/pl-panel-right/femur-view-distal/femur-view-distal.service";

/**
 * Component that defines the right panel of the planification page, which is composed of 3 different views of the femur
 * and/or tibia and view buttons
 */

@Component({
  selector: 'app-pl-panel-right',
  templateUrl: './pl-panel-right.component.html',
  styleUrls: ['./pl-panel-right.component.scss']
})
export class PlPanelRightComponent implements OnInit {

  constructor( public viewsChoiceService: ViewsChoiceService, private patellaMortaiseService: PatellaMortaiseService,
               private tibiViewFrontService: TibiaViewFrontService, private femurViewFrontService: FemurViewFrontService,
               private femurViewDistalService: FemurViewDistalService) {
  }

  ngOnInit(): void {
  }

  diff_1 = 18;
  diff_2 = 13;

  /**
   * This method displays the posterior resection of the patella if the patella is shown
   * @return {number} Posterior resection displayed.
   */
  getPosteriorResection() {
    return this.patellaMortaiseService.getMeasuredPosteriorResection()
  }

  /**
   * This method displays the anterior remaining width of the patella if the patella is shown
   * @return {number} Posterior resection displayed.
   */
  getAnteriorWidth() {
    return this.patellaMortaiseService.getMeasuredAnteriorWidth()
  }

  /**
   * This method displays the distance between the patella posterior cut and the femoral anterior cut
   * @return {number} Sum of the femoral and patella cuts.
   */
  getPatellaFemurDif() {
    return this.patellaMortaiseService.getMeasurePatellaFemurCut()
  }

  /**
   * This method displays the internal extension measure if it's a right knee, or the external extension measure
   * if it's a left knee
   * @return {number} External extension (left knee) / Internal extension (right knee).
   */
  getRightExtensionMeasure() {
    return (this.tibiViewFrontService.getRightValue() + this.femurViewFrontService.getRightValue())
  }

  /**
   * This method displays the external extension measure if it's a right knee, or the internal extension measure
   * if it's a left knee
   * @return {number} External extension (right knee) / Internal extension (left knee).
   */
  getLeftExtensionMeasure() {
    return (this.tibiViewFrontService.getLeftValue() + this.femurViewFrontService.getLeftValue())
  }

  /**
   * This method displays the internal flexion measure if it's a right knee, or the external extension measure
   * if it's a left knee
   * @return {number} External flexion (left knee) / Internal flexion (right knee).
   */
  getRightFlexionMeasure() {
    return (this.tibiViewFrontService.getRightValue() + this.femurViewDistalService.getRightValue())
  }

  /**
   * This method displays the external extension measure if it's a right knee, or the internal extension measure
   * if it's a left knee
   * @return {number} External flexion (right knee) / Internal flexion (left knee).
   */
  getLeftFlexionMeasure() {
    return (this.tibiViewFrontService.getLeftValue() + this.femurViewDistalService.getLeftValue())
  }
}

<div id="app_toolbar_right">
  <button type="button" id="btn_settings" matTooltip="Préferences" matTooltipClass="custom-tooltip" matTooltipPosition="below" (click)=" openSettings() ">
    <i class="icon icon-settings"></i>
  </button>
  <button type="button" id="btn_help" matTooltip="Aide" matTooltipClass="custom-tooltip" matTooltipPosition="below">
    <i class="icon icon-help"></i>
  </button>
  <button type="button" id="btn_quit" matTooltip="Quitter" matTooltipClass="custom-tooltip" matTooltipPosition="below" (click)=" openReturn() ">
    <i class="icon icon-quit"></i>
  </button>
</div>

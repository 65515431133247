import {Pipe, PipeTransform} from '@angular/core';
import {FEMUR_REF} from "@app/shared/constant/ref_name.constant";
import {FEMUR_BUTTON} from "@app/shared/constant/button_name.constant";

@Pipe({
  name: 'femurAnterioPosteriorDisplay',
  pure : false
})
export class FemurAnterioPosteriorDisplayPipe implements PipeTransform {

  transform(value: string): string {
    return value === FEMUR_REF.REF.ANTERIOR.value ? FEMUR_BUTTON.NA : FEMUR_BUTTON.RP;
  }
}

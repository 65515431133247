import { Pipe, PipeTransform } from '@angular/core';
import {FEMUR_BUTTON} from "@app/shared/constant/button_name.constant";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";

@Pipe({
  name: 'femurExternalInternalDisplay'
})
export class FemurExternalInternalDisplayPipe implements PipeTransform {
  constructor (private femurMortaiseService: FemurMortaiseService) {
  }

  transform(value: number){
    return (value*this.femurMortaiseService.sideModifier)>=0 ? FEMUR_BUTTON.RE : FEMUR_BUTTON.RI
  }

}

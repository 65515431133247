import {ElementRef, Injectable, NgZone} from "@angular/core";
import * as THREE from "three";
import {Init} from "@app/services/class/Init";
import {SceneService} from "@app/services/three/scene.service";
import {ControlsService} from "@app/services/three/controls.service";
import {CameraService} from "@app/services/three/camera.service";
import {RendererService} from "@app/services/three/renderer.service";
import {LightService} from "@app/services/three/light.service";
import {PlanifLoaderService} from "@app/ui/screens/planif/planif-loader.service";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {CondyleService} from "@app/services/implant/condyle.service";
import {PlateService} from "@app/services/implant/plate.service";
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {PatellaImplantService} from "@app/services/implant/patella-implant.service";

declare global {
  interface Window {
    sceneOverview: any;
  }
}

@Injectable({
  providedIn: 'root'
})

export class OverviewService {

  canvas: HTMLCanvasElement;
  camera;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  controls;
  clock: THREE.Clock;
  delta;

  constructor(private ngZone: NgZone, private sceneService: SceneService, private controlsService: ControlsService,
              private cameraService: CameraService, private rendererService: RendererService,
              private lightService: LightService, private planifLoaderService: PlanifLoaderService,
              private planifViewManagerService: PlanifViewManagerService, private planifViewReferencesService: PlanifViewReferencesService,
              private tibiaMortaiseService : TibiaMortaiseService, private femurMortaiseService : FemurMortaiseService,
              private condyleService: CondyleService, private plateService: PlateService,
              private patellaMortaiseService: PatellaMortaiseService, private patellaImplantService: PatellaImplantService) {
  }

  createScene(canvas: ElementRef<HTMLCanvasElement>) {
    Init.createOverviewScene(this, canvas);
  }

  loadObject() {
    this.planifLoaderService.getSourceViewFemur().subscribe({
      next: value => {
        this.scene.add(...value);
        this.planifViewReferencesService.displayFemurMecaAxis(this.scene)
        this.femurMortaiseService.setMortaiseFemurInitialPosition(this.scene)
      }
    })
    this.planifLoaderService.getSourceViewPatella().subscribe({
      next: value => {
        this.scene.add(...value);
        this.patellaMortaiseService.setMortaisePatellaInitialPosition(this.scene)
      }
    })
    this.planifLoaderService.getSourceViewTibia().subscribe({
      next: value => {
        this.scene.add(...value);
        this.toOverView();
        this.planifViewReferencesService.displayTibiaMecaAxis(this.scene)
        this.tibiaMortaiseService.setMortaiseTibiaInitialPosition(this.scene)
        this.loadOverviewRef()
      }
    })
  }

  loadImplants() {
    this.planifLoaderService.getSourceViewCondyle().subscribe({
      next: value => {
        this.scene.add(...value);
        this.condyleService.setCondyleInitialPosition(this.scene)
      }
    })
    this.planifLoaderService.getSourceViewPlate().subscribe({
      next: value => {
        this.scene.add(...value);
        this.plateService.setPlateInitialPosition(this.scene)
      }
    })
    this.planifLoaderService.getSourceViewPatellaImplant().subscribe({
      next: value => {
        this.scene.add(...value);
        this.patellaImplantService.setPatellaImplantInitialPosition(this.scene)
        this.planifLoaderService.loadFemurCut(this.scene)
        this.planifLoaderService.loadPatellaCut(this.scene)
        this.planifLoaderService.loadTibiaCut(this.scene)
      }
    })
  }

  /**
   * Loads all the reference points on the overview scene
   */
  loadOverviewRef () {
    this.planifViewReferencesService.displayFemurPoint(this.scene)
    this.planifViewReferencesService.displayPatellaPoint(this.scene)
    this.planifViewReferencesService.displayTibiaPoint(this.scene)
  }

  animate() {
    Init.animate(this);
    window.THREE = THREE;
    window.sceneOverview = this.scene;

  }


  public render(): void {
    Init.render(this);
  }

  public resize(): void {
    Init.resize(this);
    this.planifViewManagerService.fitToAll(this.scene, this.controls);
  }

  toOverView() {
    this.planifViewManagerService.fitToAll(this.scene, this.controls);
  }
}

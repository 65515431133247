<div id="views_choice">
  <div class="btn-group-label">
    <span>{{ renderLabel() }}</span>
  </div>
  <div class="toggle-scanner" [class.active]=" viewsChoiceService.getScanner() " (click)=" viewsChoiceService.toggleScanner() " matTooltip="Vue 3D / Scanner" matTooltipClass="custom-tooltip" matTooltipPosition="below">
  </div>
  <div class="btn-group-label">
    <span>Vues</span>
  </div>
  <button type="button" id="btn_knee_cap" [attr.data-state]=" viewsChoiceService.getPatella() " (click)=" viewsChoiceService.togglePatella() " matTooltip="Afficher/Masquer la rotule" matTooltipClass="custom-tooltip" matTooltipPosition="below">
    <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3016 9.10439C9.11994 7.09177 6.5426 8.47545 5.52664 9.41886C4.56414 10.6768 4.56415 12.7208 2.96 14.9221C1.35585 17.1234 -1.69204 21.9977 1.19543 24.8279C4.0829 27.6582 7.61203 26.872 9.05577 26.0858C10.4995 25.2996 10.3391 21.2115 14.0286 21.0543C17.7182 20.897 18.1994 25.6141 19.6432 27.1865C21.0869 28.7588 27.3431 30.9601 29.5889 25.6141C31.8347 20.2681 24.2952 13.3497 21.0869 10.5195C18.5203 8.25531 16.4883 9.15679 15.7932 9.89056C15.205 10.4671 13.4832 11.117 11.3016 9.10439Z" class="knee-cap-top"/>
      <path d="M11.7828 5.33078C8.57448 4.70183 7.34463 3.49636 7.13074 2.97224C7.45157 1.55713 7.7724 1.39989 9.53696 0.456473C11.3015 -0.48694 14.0286 0.299238 16.7556 0.456473C19.4827 0.613709 19.6431 4.5446 19.0015 4.85907C18.3598 5.17354 15.7931 6.11695 11.7828 5.33078Z" class="knee-cap-bottom"/>
    </svg>
  </button>
  <button type="button" id="btn_cut" [attr.data-state]=" viewsChoiceService.getCut() " (click)=" viewsChoiceService.toggleCut() " matTooltip="Afficher/Masquer la coupe" matTooltipClass="custom-tooltip" matTooltipPosition="below">
    <svg width="33" height="41" viewBox="0 0 33 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.9612 6.21859L29.2378 43.962L6.78527 43.9977L6.3455 5.86224L7.03241 4.19657L7.96143 5.57915L8.76282 3.63587L9.99542 5.91822L10.8908 4.41349L11.7269 5.65781L13.1969 3.36559L14.3842 5.8791L15.0711 4.21344L16.0466 5.66514L17.3308 3.09641L18.4262 5.77195L19.4145 4.40548L20.2506 5.6498L21.5823 3.45049L22.8387 5.91755L23.7341 4.41281L24.6167 5.72626L25.9257 3.64253L27.2286 6.17872L28.2136 3.91138L28.9612 6.21859Z" class="cut-bg"/>
      <path d="M13.3495 11.3541L21.8505 11.4617C22.4368 11.4691 22.5283 12.11 22.5008 12.4295L22.4927 22.8705C19.9338 24.2889 14.6409 27.2233 13.9402 27.614C13.2394 28.0046 13.0151 27.3325 12.9906 26.9476C12.8972 22.4281 12.7048 13.1721 12.6831 12.3036C12.6615 11.4352 13.1184 11.3088 13.3495 11.3541Z" class="cut-top"/>
      <path d="M22.2133 42.5883L13.7127 42.4538C13.1264 42.4445 13.0369 41.8033 13.0654 41.4839L13.1067 31.043C15.67 29.6327 20.9722 26.7151 21.6742 26.3267C22.3763 25.9383 22.5984 26.6111 22.6216 26.9961C22.7008 31.5158 22.8638 40.7724 22.8827 41.6409C22.9016 42.5095 22.4443 42.6344 22.2133 42.5883Z" class="cut-bottom"/>
    </svg>
  </button>
  <button type="button" id="btn_implant_femur" [attr.data-state]=" viewsChoiceService.getImplantFemur() " (click)=" viewsChoiceService.toggleImplantFemur() " matTooltip="Afficher/Masquer l'implant Fémur" matTooltipClass="custom-tooltip" matTooltipPosition="below" [disabled]=" !viewsChoiceService.getFemurDist() && !viewsChoiceService.getFemurFront() && !viewsChoiceService.getFemurSag() ">
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.80905 10.5914V21.624C5.53769 24.2718 9.32663 25.0073 10.4925 23.8305C11.4251 22.8891 10.8811 20.5943 10.4925 19.5646H13.99V22.6537C15.3015 22.8008 18.391 22.8891 20.2563 22.0653C22.1216 21.2415 24.6281 17.309 25.6482 15.4457V0H29V13.9747C29 18.8291 23.0251 26.9197 16.9045 28.3907C10.7839 29.8617 6.55779 28.3907 3.49749 26.9197C1.04925 25.7428 0.145729 22.7027 0 21.3298V16.3283C0 13.8276 0.437186 13.2392 1.31156 11.6211C2.01106 10.3266 3.93467 10.3952 4.80905 10.5914Z"/>
    </svg>
  </button>
  <button type="button" id="btn_implant_tibia" [attr.data-state]=" viewsChoiceService.getImplantTibia() " (click)=" viewsChoiceService.toggleImplantTibia() " matTooltip="Afficher/Masquer l'implant Tibia" matTooltipClass="custom-tooltip" matTooltipPosition="below" [disabled]=" !viewsChoiceService.getTibiaDist() && !viewsChoiceService.getTibiaFront() && !viewsChoiceService.getTibiaSag() ">
    <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1358 4.47918C10.9154 4.4508 10.9414 2.50421 11.107 1.53446C11.1293 0.172082 8.58542 -0.0502576 7.31069 0.00886982C1.88955 -0.133053 0.628897 3.97064 0.67621 6.04023C0.285946 9.94288 2.42176 17.7978 14.0871 17.9965C25.7525 18.1952 27.841 9.84827 27.4271 5.64998C27.3916 -0.558777 20.0475 0.00888044 18.3445 0.186273C16.6416 0.363666 16.9964 2.20855 17.0318 3.23743C17.0602 4.06054 16.0976 4.40823 15.6127 4.47918C14.9623 4.49101 13.3563 4.50757 12.1358 4.47918Z"/>
    </svg>
  </button>
  <button type="button" id="btn_transparency" [attr.data-state]=" viewsChoiceService.getTransparency() " (click)=" viewsChoiceService.toggleTransparency() " matTooltip="Afficher/Masquer les os" matTooltipClass="custom-tooltip" matTooltipPosition="below">
    <svg width="26" height="44" viewBox="0 0 26 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.05738 10.1505C8.40057 10.1726 8.74214 10.0836 9.04061 9.89453C9.33908 9.70542 9.58158 9.42426 9.73866 9.08522C9.95773 8.6236 10.1474 8.14557 10.3064 7.65461C10.4301 7.28054 10.6152 6.93506 10.8522 6.63558C10.98 6.44699 11.086 6.24144 11.1678 6.02372C11.2631 5.80362 11.3465 5.58353 11.4358 5.37664C11.6187 4.99636 11.7785 4.60299 11.9142 4.19914C12.2179 3.09867 12.4839 1.98719 12.7638 0.880121C12.783 0.778631 12.8364 0.689101 12.9131 0.629795C12.9899 0.570489 13.0841 0.545878 13.1766 0.560986C14.1321 0.61821 15.0882 0.679103 16.0449 0.743664C16.6265 0.78108 17.2101 0.800887 17.7877 0.880121C18.5162 0.981364 18.7921 1.36433 18.806 2.17868C18.8338 3.6423 18.8596 5.10372 18.8914 6.56515C18.9303 8.11135 19.2617 9.63126 19.864 11.0264C20.0188 11.3918 20.1161 11.788 20.261 12.1643C20.3084 12.2851 20.369 12.399 20.4416 12.5033C20.4983 12.5795 20.5653 12.6456 20.6401 12.6992C21.3865 13.245 21.5036 14.0329 21.2753 14.9001C21.0916 15.5423 20.8695 16.1703 20.6103 16.7797C20.4119 17.2881 20.1399 17.7657 19.9156 18.2631C19.6142 18.9576 19.08 19.4938 18.4269 19.7576C17.5521 20.1232 16.6354 20.3507 15.7035 20.4333C15.4881 20.4291 15.2738 20.3989 15.0643 20.343C15.0167 20.343 14.9691 20.3232 14.9214 20.321C14.6812 20.31 14.3974 20.3914 14.2108 20.2748C13.6153 19.9094 13.0714 19.4648 12.4998 19.0599C12.4177 18.9944 12.319 18.9597 12.2179 18.9608C10.5545 19.1985 8.89901 19.1963 7.26935 18.7011C6.26942 18.406 5.41433 17.6865 4.88879 16.6982C4.36325 15.7098 4.20945 14.5319 4.46061 13.4189C4.67697 12.5165 5.14344 11.8782 6.04859 11.788C6.28129 11.7812 6.50682 11.6972 6.6961 11.547C6.88538 11.3967 7.02973 11.1871 7.11055 10.945C7.15577 10.8411 7.2089 10.7417 7.26935 10.6479C7.45594 10.3001 7.68619 10.047 8.05738 10.1505Z"/>
      <path d="M12.2634 25.219L13.225 25.219C13.6008 25.238 13.482 24.8472 13.7057 25.219C13.8007 25.3795 14.1425 24.818 14.3368 24.8982C14.5433 24.9689 14.4523 25.1814 14.6673 25.219C15.2078 25.3479 16.0276 25.0926 16.3358 25.6081C16.4172 25.7521 16.5384 25.8695 16.6848 25.9464C16.8312 26.0232 16.9967 26.0561 17.1613 26.0412C17.4739 26.0681 17.7822 26.1319 18.0797 26.2313C18.2908 26.2799 18.502 26.356 18.7131 26.3834C18.912 26.3981 19.0983 26.4867 19.2353 26.6317C19.3724 26.7766 19.4504 26.9676 19.4541 27.1672C19.4649 27.319 19.4976 27.4685 19.5512 27.6109C19.9506 28.6161 20.2339 29.6637 20.3957 30.7333C20.4811 31.1521 20.4308 31.5873 20.2522 31.9755C20.0621 32.3706 19.9397 32.7995 19.7687 33.2072C19.6597 33.4943 19.518 33.768 19.3464 34.0227C19.2545 34.1357 19.1902 34.2687 19.1586 34.411C19.1271 34.5533 19.1293 34.701 19.1649 34.8424C19.3042 35.4762 19.1754 36.0973 19.0108 36.712C18.6256 38.1895 18.4438 39.7127 18.4703 41.2394C18.4703 41.5922 18.3309 41.8457 18.0248 41.9302C17.2859 42.1415 16.7074 42.6084 16.0719 42.9992C15.2275 43.521 14.2521 43.7302 13.2788 43.8781C12.6835 43.9734 12.0806 44.013 11.4779 43.9964C10.758 43.971 10.4772 43.6351 10.34 42.9401C10.0149 41.3049 9.70661 39.6613 9.30548 38.0472C9.09436 37.2022 8.64678 36.4121 8.32377 35.5924C8.1452 35.1601 7.9943 34.717 7.87197 34.2656C7.82832 34.0483 7.74037 33.8424 7.61359 33.6606C7.48681 33.4789 7.32394 33.3252 7.13515 33.2093C6.96145 33.0956 6.78096 32.9925 6.59468 32.9009C6.2348 32.7584 5.92851 32.5068 5.71876 32.1814C5.509 31.8559 5.4063 31.4729 5.42506 31.0861C5.44348 30.9363 5.40789 30.7849 5.32468 30.659C5.24147 30.5332 5.11611 30.4412 4.97115 30.3995C4.80091 30.3369 4.6475 30.2356 4.52296 30.1036C4.39842 29.9717 4.30613 29.8126 4.25334 29.639C4.10969 29.3152 4.05927 28.9578 4.10776 28.6069C4.15625 28.256 4.30173 27.9257 4.52779 27.6531C5.46729 26.4405 6.57568 25.437 8.05353 24.8982C9.23159 24.4757 10.0562 25.897 11.3019 25.7006C11.513 25.6667 12.0945 25.2317 12.2634 25.219Z"/>
    </svg>
  </button>
  <button type="button" id="btn_invert" [attr.data-state]=" viewsChoiceService.getSagIsInverted() " (click)=" viewsChoiceService.toggleSagIsInverted() " matTooltip="Inverser la vue sagittal" matTooltipClass="custom-tooltip" matTooltipPosition="below" [disabled]=" !viewsChoiceService.getFemurSag() && !viewsChoiceService.getTibiaSag() ">
    <svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="14.2881" y="0.75" width="1.05891" height="33.6205" rx="0.529456"/>
      <path d="M0.583688 17.5467C0.344682 17.3469 0.344682 16.9795 0.583687 16.7796L7.13745 11.2992C7.46295 11.027 7.95819 11.2584 7.95819 11.6828V22.6436C7.95819 23.0679 7.46295 23.2993 7.13745 23.0271L0.583688 17.5467Z"/>
      <path d="M28.7913 17.5467C29.0303 17.3469 29.0303 16.9795 28.7913 16.7796L22.2376 11.2992C21.912 11.027 21.4168 11.2584 21.4168 11.6828V22.6436C21.4168 23.0679 21.912 23.2993 22.2376 23.0271L28.7913 17.5467Z"/>
    </svg>
  </button>
  <!--scanner: {{ viewsChoiceService.getScanner() }}<br>
  patella: {{ viewsChoiceService.getPatella() }}<br>
  cut: {{ viewsChoiceService.getCut() }}<br>
  implant: {{ viewsChoiceService.getImplant() }}-->
</div>

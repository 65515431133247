import { Component, OnInit } from '@angular/core';
import {DataSymfonyService} from "@app/api/data-symfony.service";

/**
 * Component that display patient info such as : planification side, case reference, birth date, surgery date, patient name(s)
 */

@Component({
  selector: 'app-infos-patient',
  templateUrl: './infos-patient.component.html',
  styleUrls: ['./infos-patient.component.scss']
})
export class InfosPatientComponent implements OnInit {

  constructor(private dataService: DataSymfonyService) { }

  ngOnInit(): void {

  }

  /**
   * Return the patient last name from the response.json
   */
  getPatientLastName() {
    return this.dataService.getPatientData().lastName
  }

  /**
   * Return the patient first name from the response.json
   */
  getPatientFirstName() {
    return this.dataService.getPatientData().firstName
  }

  /**
   * Return the patient birth date from the response.json
   */
  getPatientBirthDate() {
    return this.dataService.getPatientData().birthDate
  }

  /**
   * Return the intervention date from the response.json
   */
  getInterventionDate() {
   return this.dataService.getIntervention().date
  }

  /**
   * Return the operation side from the response.json in a proper sentence
   */
  getSide() {
    return this.renderSide(this.dataService.getIntervention().side)
  }

  getSideInitial() {
    let res = "";
    let side = this.dataService.getIntervention().side;
    if( side == "left" ) {
      res += "G";
    }
    if( side == "right" ) {
      res += "D";
    }
    return res;
  }

  /**
   * Return the type of imaging from the response.json
   */
  getImgType() {
    return this.dataService.getIntervention().scanType
  }

  /**
   * Return the case ref from the response.json
   */
  getRef() {
    return this.dataService.getIntervention().id
  }

  /**
   * A function that return a sentence describing the planned side
   * @param side: string ; the planned side from the response.json ("right" or "left")
   */
  renderSide(side: string) {
    let res = "";
    if( side == "left" ) {
      res += "Genou gauche";
    }
    if( side == "right" ) {
      res += "Genou droit";
    }
    return res;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import {FEMUR_BUTTON} from "@app/shared/constant/button_name.constant";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";

@Pipe({
  name: 'tibiaExternalInternalDisplay'
})
export class TibiaExternalInternalDisplayPipe implements PipeTransform {
  constructor (private tibiaMortaiseService: TibiaMortaiseService) {
  }

  transform(value: number){
    return (value*this.tibiaMortaiseService.sideModifier)>=0 ? FEMUR_BUTTON.RE : FEMUR_BUTTON.RI
  }
}

import { Component, OnInit } from '@angular/core';
import { ViewsChoiceService } from '../../../../services/planif/views-choice.service';

/**
 * Component that defines the left panel of the planification page, which contains the synthesis of the 3D view and the
 * buttons to choose the displayed bone(s)
 */

@Component({
  selector: 'app-pl-panel-left',
  templateUrl: './pl-panel-left.component.html',
  styleUrls: ['./pl-panel-left.component.scss']
})
export class PlPanelLeftComponent implements OnInit {

  constructor( public viewsChoiceService: ViewsChoiceService ) {
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { CommentService } from './shared/component/comment/comment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'knee';
  containerClass= [ "serf", "dark" ];

  constructor( public commService: CommentService ) {
  }

  ngOnInit(): void {
    let body = document.getElementsByTagName('body')[0];
    body.classList.add( ...this.containerClass );
  }
}

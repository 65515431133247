<div id="po_toggle_tooltips">
  <button type="button" id="btn_pott" (click)="toggle()">
    <div class="tt-toggle-indicator">
      <i class="icon icon-info"></i>
    </div>
    Montrer toutes les infos
  </button>
  <!--<br>
  mldfa: {{tt.mldfa}}<br>
  mpta: {{tt.mpta}}<br>
  hks: {{tt.hks}}<br>
  varus: {{tt.varus}}<br>
  flexion: {{tt.flexion}}<br>
  tibialSlope: {{tt.tibialSlope}}<br>
  allVisible: {{allVisible()}}-->
</div>

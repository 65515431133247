<div id="selects_container" [class.not-all]=" viewsChoiceService.getFemurTibias() != 'all' ">
  <img class="femur" src="../../../../../assets/madison/img/femur.png">
  <img class="tibia" src="../../../../../assets/madison/img/tibia.png">
  <div class="select-femur-container" *ngIf=" ( viewsChoiceService.getFemurDist() || viewsChoiceService.getFemurFront() || viewsChoiceService.getFemurSag() ) && ( !viewsChoiceService.getPatella() || viewsChoiceService.getFemurTibias() == 'all' ) ">
    <app-select-femur *ngIf=" true "></app-select-femur>
  </div>
  <div class="select-patella-container" *ngIf=" ( viewsChoiceService.getFemurDist() || viewsChoiceService.getFemurFront() || viewsChoiceService.getFemurSag() ) && viewsChoiceService.getPatella() ">
    <app-select-patella *ngIf=" true "></app-select-patella>
  </div>
  <div class="select-tibia-container" *ngIf=" viewsChoiceService.getTibiaDist() || viewsChoiceService.getTibiaFront() || viewsChoiceService.getTibiaSag() ">
    <app-select-tibia *ngIf=" true "></app-select-tibia>
  </div>
</div>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule} from "./ui/header/header.module";
import { PreopModule } from "./ui/screens/preop/preop.module";
import {PlanifModule} from "./ui/screens/planif/planif.module";
import {ToolbarModule} from "./ui/toolbar/toolbar.module";
import { CommentComponent } from './shared/component/comment/comment.component';
import { SettingsComponent } from './ui/dialog/settings/settings.component';
import { ReturnComponent } from './ui/dialog/return/return.component';
import { ResetComponent } from './ui/dialog/reset/reset.component';
import { ValidComponent } from './ui/dialog/valid/valid.component';
import {MaterialModule} from "./shared/module/material.module";

@NgModule({
    declarations: [
        AppComponent,
        CommentComponent,
        SettingsComponent,
        ReturnComponent,
        ResetComponent,
        ValidComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HeaderModule,
        ToolbarModule,
        PlanifModule,
        PreopModule,
        MaterialModule,
    ],
    providers: [],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { ViewsChoiceService } from '../../../../../services/planif/views-choice.service';

//TODO
/**
 *
 */

@Component({
  selector: 'app-selects-container',
  templateUrl: './selects-container.component.html',
  styleUrls: ['./selects-container.component.scss']
})
export class SelectsContainerComponent implements OnInit {

  constructor( public viewsChoiceService: ViewsChoiceService ) { }

  ngOnInit(): void {
  }

}

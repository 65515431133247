import { Pipe, PipeTransform } from '@angular/core';
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {PATELLA_BUTTON} from "@app/shared/constant/button_name.constant";

@Pipe({
  name: 'patellaExternalInternalDisplay'
})
export class PatellaExternalInternalDisplayPipe implements PipeTransform {
  constructor (private patellaMortaiseService: PatellaMortaiseService) {
  }

  transform(value: number){
    return (value*this.patellaMortaiseService.sideModifier)>=0 ? PATELLA_BUTTON.RE : PATELLA_BUTTON.RI
  }
}

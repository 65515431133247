import {ParentObject} from './parentObject';
import * as THREE from 'three';
import {ManagerService} from "@app/services/three/manager.service";
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {Observable} from "rxjs";

/**
 * Class used to be overrided for implants loading
 */
export class Implant extends ParentObject {
  dir: string;
  state: string;
  side: string
  range: string
  size: string

  constructor(public override manager: ManagerService, public dataSymfonyService: DataSymfonyService) {
    super(manager);
    this.state = this.dataSymfonyService.getIntervention().state;
    this.dir = this.dataSymfonyService.getImplantDirectory(); //TODO add toImplantDirectory: 'assets/madison/....',
    if (this.dataSymfonyService.getSide()=="right") {
      this.side='d'
    }
    else {
      this.side='g'
    }
  }


  setGeometry(): Observable<THREE.BufferGeometry> {
    return new Observable()
  }

  setSize() {

  }

  setRange() {

  }

  setProduct() {

  }

}

import { Component, OnInit } from '@angular/core';

/**
 * Component used to navigate between the pre-op and the planification page
 */

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { ViewsChoiceService } from '../../../../../services/planif/views-choice.service';

/**
 * Component used to deploy the left panel thanks to a panel toggle button
 */

@Component({
  selector: 'app-panel-toggle',
  templateUrl: './panel-toggle.component.html',
  styleUrls: ['./panel-toggle.component.scss']
})
export class PanelToggleComponent implements OnInit {

  constructor( public viewsChoiceService: ViewsChoiceService ) {
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

/**
 * Component used to display a warning on the pre-operative page
 */

@Component({
  selector: 'app-po-warning',
  templateUrl: './po-warning.component.html',
  styleUrls: ['./po-warning.component.scss']
})
export class PoWarningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {ElementRef, Injectable, NgZone} from "@angular/core";
import * as THREE from "three";
import {Init} from "@app/services/class/Init";
import {SceneService} from "@app/services/three/scene.service";
import {ControlsService} from "@app/services/three/controls.service";
import {CameraService} from "@app/services/three/camera.service";
import {RendererService} from "@app/services/three/renderer.service";
import {LightService} from "@app/services/three/light.service";
import {FemurService} from "@app/services/bone/femur.service";
import {PlanifLoaderService} from "@app/ui/screens/planif/planif-loader.service";
import {ViewerNii} from "@app/services/nii/viewerNii";
import {Utils} from "@app/services/class/Utils";
import {DataSymfonyService} from "@app/api/data-symfony.service";
import CameraControls from "camera-controls";
import {MathUtils, Vector3} from "three";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";
import {CondyleService} from "@app/services/implant/condyle.service";
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {PatellaImplantService} from "@app/services/implant/patella-implant.service";
import {environment} from "@environments/environment";

declare global {
  interface Window {
    sceneFemurFrontal: any;
  }
}

@Injectable({
  providedIn: 'root'
})

export class FemurViewFrontService {

  canvas: HTMLCanvasElement;
  camera;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  controls;
  clock: THREE.Clock;
  delta;
  private viewerNii: ViewerNii;

  constructor(private ngZone: NgZone, private sceneService: SceneService, private controlsService: ControlsService,
              private cameraService: CameraService, private rendererService: RendererService,
              private lightService: LightService, private femurService: FemurService,
              private dataSymfonyService: DataSymfonyService, public planifLoaderService: PlanifLoaderService,
              private planifViewManagerService: PlanifViewManagerService, private femurMortaiseService: FemurMortaiseService,
              private planifViewReferencesService: PlanifViewReferencesService ,private condyleService: CondyleService,
              private patellaMortaiseService: PatellaMortaiseService, private patellaImplantService: PatellaImplantService) {
  }

  createScene(canvas: ElementRef<HTMLCanvasElement>) {
    Init.createFemurFrontScene(this, canvas);
    this.controls.mouseButtons.wheel = CameraControls.ACTION.NONE;
  }

  loadObject() {
    this.planifLoaderService.getSourceViewFemur().subscribe({
      next: value => {
        this.scene.add(...value);
        this.planifViewReferencesService.displayFemurMecaAxis(this.scene)
        this.femurMortaiseService.setMortaiseFemurInitialPosition(this.scene)
      }
    })
    this.planifLoaderService.getSourceViewPatella().subscribe({
      next: value => {
        this.scene.add(...value);
        this.toFrontView();
        this.patellaMortaiseService.setMortaisePatellaInitialPosition(this.scene)
        this.loadFrontRef()
      }
    })
  }

  loadImplants() {
    this.planifLoaderService.getSourceViewCondyle().subscribe({
      next: value => {
        this.scene.add(...value);
        this.condyleService.setCondyleInitialPosition(this.scene)
      }
    })
    this.planifLoaderService.getSourceViewPatellaImplant().subscribe({
      next: value => {
        this.scene.add(...value);
        this.patellaImplantService.setPatellaImplantInitialPosition(this.scene)
        this.planifLoaderService.loadFemurCut(this.scene)
        this.planifLoaderService.loadPatellaCut(this.scene)
      }
    })
  }

  loadImages(scope) {
    this.viewerNii = new ViewerNii();
    this.viewerNii.init(this.scene, 'xz').then(el => {
      const {x, y, z} = this.dataSymfonyService.getNiiTranslation();
      const rotation = this.dataSymfonyService.getNiiRotation();
      const pixDimY = this.viewerNii.viewer.header.pixDims[2]
      const pointVect = Utils.getPointPositionByName(this.scene, scope.targetPoint)

      // @ts-ignore
      scope.count = (y+el.y-pointVect.y)/pixDimY

      Utils.getMeshByName(this.scene, 'viewer-nifti').position.set(x, y, z)
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(0,0,1), MathUtils.degToRad(rotation.z))
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(0,1,0), MathUtils.degToRad(rotation.y))
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(1,0,0), MathUtils.degToRad(rotation.x))

      this.viewerNii.viewer.slice('xz', scope.count)
    })
  }

  scroll(deltaY: number) {
    this.viewerNii.viewer.slice('xz', deltaY);
  }

  /**
   * Loads all the reference in the front view
   */
  loadFrontRef() {
    this.planifViewReferencesService.displayFemurPoint(this.scene)
    this.planifViewReferencesService.displayPatellaPoint(this.scene)
  }

  animate() {
    Init.animate(this);
    if (environment.production === false) {
      window.sceneFemurFrontal = this.scene;
    }
  }

  public render(): void {
    Init.render(this);
  }

  public resize(): void {
    Init.resize(this);
    this.planifViewManagerService.fitToFemurPatella(this.scene, this.controls);
  }

  toFrontView() {
    this.planifViewManagerService.fitToFemurPatella(this.scene, this.controls);
  }

  /**
   * This method displays the distal resection on the left side of the screen. Depending on the operated knee (right or
   * left), this value will be the internal (left) or external resection (right)
   * @return {number} Distal resection value of the left side displayed.
   */
  getLeftValue() {
    if (this.femurMortaiseService.side == 'right') {
      return Math.round(this.femurMortaiseService.getExternalResectionDistal()*10)/10
    } else {
      return Math.round(this.femurMortaiseService.getInternalResectionDistal()*10)/10
    }
  }

  /**
   * This method displays the distal value on the right side of the screen. Depending on the operated knee (right or
   * left), this value will be the internal (right) or external resection (left)
   * @return {number} Distal resection value of the right side displayed.
   */
  getRightValue() {
    if (this.femurMortaiseService.side == 'right') {
      return Math.round(this.femurMortaiseService.getInternalResectionDistal()*10)/10
    } else {
      return Math.round(this.femurMortaiseService.getExternalResectionDistal()*10)/10
    }
  }

}


import {ElementRef, Injectable, NgZone} from "@angular/core";
import * as THREE from "three";
import {Init} from "@app/services/class/Init";
import {SceneService} from "@app/services/three/scene.service";
import {ControlsService} from "@app/services/three/controls.service";
import {CameraService} from "@app/services/three/camera.service";
import {RendererService} from "@app/services/three/renderer.service";
import {LightService} from "@app/services/three/light.service";
import {FemurService} from "@app/services/bone/femur.service";
import {PlanifLoaderService} from "@app/ui/screens/planif/planif-loader.service";
import {ViewerNii} from "@app/services/nii/viewerNii";
import {Utils} from "@app/services/class/Utils";
import {DataSymfonyService} from "@app/api/data-symfony.service";
import CameraControls from "camera-controls";
import {MathUtils, Vector3} from "three";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";
import {FEMUR_REF, PATELLA_REF} from "@app/shared/constant/ref_name.constant";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {CondyleService} from "@app/services/implant/condyle.service";
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {PatellaImplantService} from "@app/services/implant/patella-implant.service";
import {environment} from "@environments/environment";

declare global {
  interface Window {
    sceneFemurSagittal: any;
  }
}

@Injectable({
  providedIn: 'root'
})

export class FemurViewSagittalService {

  canvas: HTMLCanvasElement;
  camera;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  controls;
  clock: THREE.Clock;
  delta;
  viewerNii: ViewerNii;

  constructor(private ngZone: NgZone, private sceneService: SceneService, private controlsService: ControlsService,
              private cameraService: CameraService, private rendererService: RendererService,
              private lightService: LightService, private femurService: FemurService,
              private dataSymfonyService: DataSymfonyService, private planifLoaderService: PlanifLoaderService,
              private planifViewManagerService: PlanifViewManagerService, private condyleService: CondyleService,
              private planifViewReferencesService: PlanifViewReferencesService, private femurMortaiseService : FemurMortaiseService,
              private patellaMortaiseService: PatellaMortaiseService, private patellaImplantService: PatellaImplantService) {
  }

  createScene(canvas: ElementRef<HTMLCanvasElement>) {
    Init.createFemurSagittalScene(this, canvas);
    this.controls.mouseButtons.wheel = CameraControls.ACTION.NONE;
  }

  loadObject() {
    this.planifLoaderService.getSourceViewFemur().subscribe({
      next: value => {
        this.scene.add(...value);
        this.planifViewReferencesService.displayFemurMecaAxis(this.scene)
        this.femurMortaiseService.setMortaiseFemurInitialPosition(this.scene)
      }
    })
    this.planifLoaderService.getSourceViewPatella().subscribe({
      next: value => {
        this.scene.add(...value);
        this.toSagittalView();
        this.patellaMortaiseService.setMortaisePatellaInitialPosition(this.scene)
        this.loadSagittalRef();
      }
    })
  }

  loadImplants() {
    this.planifLoaderService.getSourceViewCondyle().subscribe({
      next: value => {
        this.scene.add(...value);
        this.condyleService.setCondyleInitialPosition(this.scene)
      }
    })
    this.planifLoaderService.getSourceViewPatellaImplant().subscribe({
      next: value => {
        this.scene.add(...value);
        this.patellaImplantService.setPatellaImplantInitialPosition(this.scene)
        this.planifLoaderService.loadFemurCut(this.scene)
        this.planifLoaderService.loadPatellaCut(this.scene)
      }
    })
  }

  loadImages(scope) {
    this.viewerNii = new ViewerNii();
    this.viewerNii.init(this.scene, 'yz').then(el => {
      const {x, y, z} = this.dataSymfonyService.getNiiTranslation();
      const rotation = this.dataSymfonyService.getNiiRotation();
      const pixDimX = this.viewerNii.viewer.header.pixDims[1]
      const pointVect = Utils.getPointPositionByName(this.scene, scope.targetPoint)

      // @ts-ignore
      scope.count = (x+el.x+pointVect.x)/pixDimX

      Utils.getMeshByName(this.scene, 'viewer-nifti').position.set(x, y, z)
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(0,0,1), MathUtils.degToRad(rotation.z))
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(0,1,0), MathUtils.degToRad(rotation.y))
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(1,0,0), MathUtils.degToRad(rotation.x))

      this.viewerNii.viewer.slice('yz', scope.count)
    })
  }

  /**
   * Loads all the reference axis in the sagittal view and set the default reference as "posterior" for the patella
   * and for the femur
   */
  loadSagittalRef() {
    this.planifViewReferencesService.displayFemurPoint(this.scene)
    this.planifViewReferencesService.displayPatellaPoint(this.scene)
    this.planifViewReferencesService.setCurrentPatellaRefPoint(PATELLA_REF.REF.POSTERIOR.value, this.scene)
    this.planifViewReferencesService.setCurrentFemurRefPoint(FEMUR_REF.REF.POSTERIOR.value, this.scene)
  }

  scroll(deltaX: number) {
    this.viewerNii.viewer.slice('yz', deltaX);
  }

  animate() {
    Init.animate(this);
    window.THREE = THREE;
    if (environment.production === false) {
      window.sceneFemurSagittal = this.scene;
    }
  }

  public render(): void {
    Init.render(this);
  }

  public resize(): void {
    Init.resize(this);
    this.planifViewManagerService.fitToFemurPatella(this.scene, this.controls);
  }

  toSagittalView() {
    this.controls.rotateAzimuthTo(-90 * THREE.MathUtils.DEG2RAD, false);
    this.camera.up.set(0,1,0)
    this.controls.updateCameraUp()
    this.planifViewManagerService.fitToFemurPatella(this.scene, this.controls);
  }

  toSagittalViewReverse() {
    this.controls.rotateAzimuthTo(90 * THREE.MathUtils.DEG2RAD, false);
    this.camera.up.set(0,1,0)
    this.controls.updateCameraUp()
    this.planifViewManagerService.fitToFemurPatella(this.scene, this.controls);
  }

}

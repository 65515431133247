import {ParentObject} from './parentObject';
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {ManagerService} from "@app/services/three/manager.service";

/**
 * Class used to be overrided for bones loading
 */
export class Bone extends ParentObject {

  dir: string;
  state: string;
  addSimplifiee: string;
  side: string


  constructor(manager: ManagerService,  public dataSymfonyService: DataSymfonyService) { //TODO why need to add override
    super(manager);
    this.state = this.dataSymfonyService.getIntervention().state;
    this.dir = this.dataSymfonyService.getDirectory();
    this.addSimplifiee = this.dataSymfonyService.addSimplifiee();
    this.side = this.dataSymfonyService.getSide();
  }
}

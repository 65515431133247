import { Component, OnInit } from '@angular/core';
import { ViewsChoiceService } from '../../../../../services/planif/views-choice.service';

/**
 * Component that proposes different options to change the displayed views : hide patella, hide the cuts, hide the implants,
 * choose between 2D and 3D view
 */

@Component({
  selector: 'app-views-choice',
  templateUrl: './views-choice.component.html',
  styleUrls: ['./views-choice.component.scss']
})
export class ViewsChoiceComponent implements OnInit {

  constructor( public viewsChoiceService: ViewsChoiceService ) {
  }

  ngOnInit(): void {
  }

  /**
   * function used to display the choosen view, it can be Scanner or 3D
   */
  renderLabel() {
    if( this.viewsChoiceService.getScanner() ) {
      return "Scanner";
    } else {
      return "3D";
    }
  }
}

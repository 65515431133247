import * as THREE from "three"

/**
 * Class used initiate the scenes
 */
export class Init {

  static create3DScene(scope, canvas) {
    this.firstInit(scope, canvas);
    scope.controls = scope.controlsService.createAnonymousCameraControl(scope.camera, scope.renderer);
    scope.camera.lookAt(scope.controls.getTarget(new THREE.Vector3()));
  }

  static createFemurDistalScene(scope, canvas) {
    this.create3DScene(scope, canvas);
    scope.lightCustom = scope.lightService.createCustomFemurDistal()
    scope.scene.add(scope.lightCustom)
  }

  static createFemurFrontScene(scope, canvas) {
    this.create3DScene(scope, canvas);
    scope.lightCustom = scope.lightService.createCustomFemurFront()
    scope.scene.add(scope.lightCustom)
  }

  static createFemurSagittalScene(scope, canvas) {
    this.create3DScene(scope, canvas);
    scope.lightCustom = scope.lightService.createCustomFemurSagittal()
    scope.scene.add(scope.lightCustom)
  }

  static createOverviewScene(scope, canvas) {
    this.create3DScene(scope, canvas);
    scope.lightCustom = scope.lightService.createCustomOverview()
    scope.scene.add(scope.lightCustom)
  }

  static createTibiaProximalScene(scope, canvas) {
    this.create3DScene(scope, canvas);
    scope.lightCustom = scope.lightService.createCustomTibiaProximal()
    scope.scene.add(scope.lightCustom)
  }

  static createTibiaFrontScene(scope, canvas) {
    this.create3DScene(scope, canvas);
    scope.lightCustom = scope.lightService.createCustomTibiaFront()
    scope.scene.add(scope.lightCustom)
  }

  static createTibiaTransversalScene(scope, canvas)  {
    this.create3DScene(scope, canvas);
    scope.lightCustom = scope.lightService.createCustomTibiaTransversal()
    scope.scene.add(scope.lightCustom)
  }

  static firstInit(scope, canvas) {
    // create scene
    scope.scene = scope.sceneService.createAnonymous();
    // init mesh & camera parent
    scope.meshParent = new THREE.Object3D();
    scope.meshParent.name = '3D';
    scope.cameraParent = new THREE.Object3D();
    // ini clock
    scope.clock = new THREE.Clock();
    // canvas & render & controls & camera
    scope.canvas = canvas.nativeElement;
    scope.renderer = scope.rendererService.createAnonymous(scope.canvas);
    scope.camera = scope.cameraService.createAnonymous(scope.canvas);
    scope.lightParent = scope.lightService.create();
    scope.scene.add(scope.meshParent, scope.lightParent);
    scope.cameraParent.add(scope.camera);
  }

  static render(scope) {
    scope.delta = scope.clock.getDelta();
    scope.controls.update(scope.delta);
    scope.frameId = requestAnimationFrame(() => {
      scope.render();
    });
    scope.renderer.render(scope.scene, scope.camera);
  }

  static animate(scope) {
    scope.ngZone.runOutsideAngular(() => {
      window.THREE = THREE;
      if (document.readyState !== 'loading') {
        scope.render();
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          scope.render();
        });
      }
      window.addEventListener('resize', () => {
        scope.resize();
      });
    });
  }

  static resize(scope) {
    if (scope.renderer) {
      scope.ngZone.runOutsideAngular(() => {
        const width = scope.canvas.parentElement.parentElement.offsetWidth;
        const height = scope.canvas.parentElement.parentElement.offsetHeight;
        scope.camera.left = width / -2;
        scope.camera.right = width / 2;
        scope.camera.top = height / 2;
        scope.camera.bottom = height / -2;
        scope.camera.updateProjectionMatrix();
        scope.renderer.setSize(width, height);
      });
    }
  }
}

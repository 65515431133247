import {Injectable} from "@angular/core";
import * as THREE from "three";
import {Utils} from "@app/services/class/Utils";
import {ELEMENTS_FEMUR_EPIPHYSIS} from "@app/shared/constant/femur.constant";
import {ELEMENTS_TIBIA_EPIPHYSIS} from "@app/shared/constant/tibia.constant";
import {ELEMENTS_PATELLA} from "@app/shared/constant/patella.constant";

@Injectable({
  providedIn: 'root'
})

/**
 * Service used to manage the focus of the views of the different scenes on the displayed parts
 */
export class PlanifViewManagerService {

  constructor() {
  }

  /**
   * This method returns all points of the distal epiphysis of the femur, so that the view can be centered on the epiphysis
   * @param {THREE.Scene} scene  the current scene
   * @return {THREE.Group} The group of the epiphysis points
   */
  getFemurGroup(scene) {
    let group = new THREE.Group();
    for (let point of ELEMENTS_FEMUR_EPIPHYSIS) {
      group.add(Utils.getMeshByName(scene, point));
      }
    group.name = 'femur_group'
    return group
  }

  /**
   * This method returns all points of the patella, so that the view can be centered on the patella
   * @param {THREE.Scene} scene  the current scene
   * @return {THREE.Group} The group of the patella points
   */
  getPatellaGroup(scene) {
    let group = new THREE.Group();
    for (let point of ELEMENTS_PATELLA) {
      group.add(Utils.getMeshByName(scene, point));
    }
    group.name = 'patella_group'
    return group
  }

  /**
   * This method returns all points of the proximal epiphysis of the tibia, so that the view can be centered on the epiphysis
   * @param {THREE.Scene} scene The current scene
   * @return {THREE.Group} The group of the tibia epiphysis points
   */
  getTibiaGroup(scene) {
    let group = new THREE.Group();
    for (let point of ELEMENTS_TIBIA_EPIPHYSIS) {
      group.add(Utils.getMeshByName(scene, point));
    }
    group.name = 'tibia_group'
    return group
  }

  /**
   * Fit the current view so that it is centered on the distal epiphysis of the femur and on the patella
   * @param {THREE.Scene} scene The current scene to fit the view
   * @param {THREE.Controls} controls The controls of the current scene
   */
  fitToFemurPatella(scene, controls) {
    let points = new THREE.Group();
    points.add(this.getFemurGroup(scene))
    points.add(this.getPatellaGroup(scene))
    points.name = 'femur_patella_group'
    scene.add(points)
    controls.fitToSphere(points, false);
  }

  /**
   * Fit the current view so that it is centered on the distal epiphysis of the femur
   * @param {THREE.Scene} scene The current scene to fit the view
   * @param {THREE.Controls} controls The controls of the current scene
   */
  fitToFemur(scene, controls) {
    const points = this.getFemurGroup(scene)
    scene.add(points)
    controls.fitToSphere(points, false);
  }

  /**
   * Fit the current view so that it is centered on the proximal epiphysis of the tibia
   * @param {THREE.Scene} scene The current scene to fit the view
   * @param {THREE.Controls} controls The controls of the current scene
   */
  fitToTibia(scene, controls) {
    const points = this.getTibiaGroup(scene)
    scene.add(points)
    controls.fitToSphere(points, false);
    controls.zoom(-0.5)
  }

  /**
   * Fit the current view so that it is centered on the distal epiphysis of the femur, the patella and the proximal
   * epiphysis of the tibia
   * @param {THREE.Scene} scene The current scene to fit the view
   * @param {THREE.Controls} controls The controls of the current scene
   */
  fitToAll(scene, controls) {
    let points = new THREE.Group();
    points.add(this.getFemurGroup(scene))
    points.add(this.getPatellaGroup(scene))
    points.add(this.getTibiaGroup(scene))
    points.name = 'femur_patella_tibia_group'
    scene.add(points)
    controls.fitToSphere(points, false);
  }
}

import {ElementRef, Injectable, NgZone} from "@angular/core";
import * as THREE from "three";
import {Init} from "@app/services/class/Init";
import {SceneService} from "@app/services/three/scene.service";
import {ControlsService} from "@app/services/three/controls.service";
import {CameraService} from "@app/services/three/camera.service";
import {RendererService} from "@app/services/three/renderer.service";
import {LightService} from "@app/services/three/light.service";
import {FemurService} from "@app/services/bone/femur.service";
import {PlanifLoaderService} from "@app/ui/screens/planif/planif-loader.service";
import {ViewerNii} from "@app/services/nii/viewerNii";
import {Utils} from "@app/services/class/Utils";
import CameraControls from "camera-controls";
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {MathUtils, Vector3} from "three";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {FEMUR_REF} from "@app/shared/constant/ref_name.constant";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";
import {PlanifMeasuresService} from "@app/ui/screens/planif/planif-measures.service";
import {CondyleService} from "@app/services/implant/condyle.service";
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {PatellaImplantService} from "@app/services/implant/patella-implant.service";
import {environment} from "@environments/environment";

declare global {
  interface Window {
    sceneFemurDistal: any;
  }
}

@Injectable({
  providedIn: 'root'
})

export class FemurViewDistalService {

  canvas: HTMLCanvasElement;
  camera;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  controls;
  clock: THREE.Clock;
  delta;
  axis;
  viewerNii: ViewerNii;

  constructor(private ngZone: NgZone, private sceneService: SceneService, private controlsService: ControlsService,
              private cameraService: CameraService, private rendererService: RendererService,
              private lightService: LightService, private femurService: FemurService,
              private dataSymfonyService: DataSymfonyService, private planifLoaderService: PlanifLoaderService,
              private planifViewManagerService: PlanifViewManagerService, private femurMortaiseService: FemurMortaiseService,
              private planifViewReferencesService: PlanifViewReferencesService, public planifMeasureService: PlanifMeasuresService,
              private condyleService: CondyleService, private patellaMortaiseService: PatellaMortaiseService,
              private patellaImplantService: PatellaImplantService) {
  }


  createScene(canvas: ElementRef<HTMLCanvasElement>) {
    Init.createFemurDistalScene(this, canvas);
    this.controls.mouseButtons.wheel = CameraControls.ACTION.NONE;
  }

  /**
   * Load objetcs in the femur distal view. Loads the femur, the femur points, the reference axis for rotation, and the
   * mortaise. Loads the patella, the patella points, and the patella mortaise
   */
  loadObject() {
    this.planifLoaderService.getSourceViewFemur().subscribe({
      next: value => {
        this.scene.add(...value);
        this.planifViewReferencesService.displayFemurMecaAxis(this.scene)
        this.femurMortaiseService.setMortaiseFemurInitialPosition(this.scene)
       }
    })
    this.planifLoaderService.getSourceViewPatella().subscribe({
      next: value => {
        this.scene.add(...value);
        this.toDistalView();
        this.loadDistalRef()
        this.patellaMortaiseService.setMortaisePatellaInitialPosition(this.scene)
      }
    })
  }

  /**
   * Load implants in the femur distal view. Loads the condyle and the patella implant
   */
  loadImplants() {
    this.planifLoaderService.getSourceViewCondyle().subscribe({
      next: value => {
        this.scene.add(...value);
        this.condyleService.setCondyleInitialPosition(this.scene)
      }
    })
    this.planifLoaderService.getSourceViewPatellaImplant().subscribe({
      next: value => {
        this.scene.add(...value);
        this.patellaImplantService.setPatellaImplantInitialPosition(this.scene)
        this.planifLoaderService.loadFemurCut(this.scene)
        this.planifLoaderService.loadPatellaCut(this.scene)
      }
    })
  }

  /**
   * Loads scanner images in the femur distal view, and set the position
   */
  loadImages(scope) {
    this.viewerNii = new ViewerNii();
    this.viewerNii.init(this.scene, 'xy').then(el => {
      const {x, y, z} = this.dataSymfonyService.getNiiTranslation();
      const rotation = this.dataSymfonyService.getNiiRotation();
      const pixDimZ = this.viewerNii.viewer.header.pixDims[3]
      const pointVect = Utils.getPointPositionByName(this.scene, scope.targetPoint)
      // @ts-ignore
      scope.count = -(z+el.z-pointVect.z)/pixDimZ

      Utils.getMeshByName(this.scene, 'viewer-nifti').position.set(x, y, z)
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(0,0,1), MathUtils.degToRad(rotation.z))
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(0,1,0), MathUtils.degToRad(rotation.y))
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(1,0,0), MathUtils.degToRad(rotation.x))
      /*
      M.set (-0.9764, 0.2124, 0.0382, -96.8706,
              -0.2130, -0.9769, -0.0151, 2.7773,
              0.0341, -0.0228, 0.9992, 539.8622,
              0, 0, 0, 1)
      */
      this.viewerNii.viewer.slice('xy', scope.count)
    })
  }

  scroll(deltaZ: number) {
    this.viewerNii.viewer.slice('xy', deltaZ);
  }

  /**
   * Loads all the reference axis in the distal view and set the default reference as "condyle_posterior"
   */
  loadDistalRef() {
    this.planifViewReferencesService.displayFemurPoint(this.scene)
    this.planifViewReferencesService.displayPatellaPoint(this.scene)
    this.planifViewReferencesService.setCondylePosteriorAxis(this.scene)
    this.planifViewReferencesService.setEpicondyleAnatomicalAxis(this.scene)
    this.planifViewReferencesService.setEpicondyleSurgicalAxis(this.scene)
    this.planifViewReferencesService.setAPWhitesideAxis(this.scene)
    this.planifMeasureService.setAngleBetweenCondyleAndEpicondyleAnat()
    this.planifMeasureService.setAngleBetweenCondyleAndEpicondyleSurg()
    this.planifMeasureService.setAngleBetweenEpicondyleSurgAndWhiteside()
    this.planifViewReferencesService.setCurrentFemurMeasureAxis(FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.value, this.scene)
    this.planifViewReferencesService.setCurrentFemurRotationAxis(FEMUR_REF.ROT.MECA.value, this.scene)
  }

  animate() {
    Init.animate(this);
    window.THREE = THREE;
    if (environment.production === false) {
      window.sceneFemurDistal = this.scene;
    }
  }

  public render(): void {
    Init.render(this);
  }

  public resize(): void {
    Init.resize(this);
    this.planifViewManagerService.fitToFemurPatella(this.scene, this.controls);
  }

  toDistalView() {
    this.controls.rotatePolarTo(180 * THREE.MathUtils.DEG2RAD, false);
    this.planifViewManagerService.fitToFemurPatella(this.scene, this.controls);
  }

  /**
   * This method displays the posterior value on the left side of the screen. Depending on the operated knee (right or
   * left), this value will be the internal (left) or external resection (right)
   * @return {number} Posterior resection value of the left side displayed.
   */
  getLeftValue() {
    if (this.femurMortaiseService.side == 'right') {
      return Math.round(this.femurMortaiseService.getExternalResectionPosterior()*10)/10
    } else {
      return Math.round(this.femurMortaiseService.getInternalResectionPosterior()*10)/10
    }
  }

  /**
   * This method displays the posterior value on the right side of the screen. Depending on the operated knee (right or
   * left), this value will be the internal (right) or external resection (left)
   * @return {number} Posterior resection value of the right side displayed.
   */
  getRightValue() {
    if (this.femurMortaiseService.side == 'right') {
      return Math.round(this.femurMortaiseService.getInternalResectionPosterior()*10)/10
    } else {
      return Math.round(this.femurMortaiseService.getExternalResectionPosterior()*10)/10
    }
  }
}

import {Injectable} from '@angular/core';
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {ManagerService} from "@app/services/three/manager.service";
import {MaterialService} from "@app/services/three/material.service";
import * as MEASURE_JSON from '@app/shared/constant/mortaise_measure';
import * as THREE from 'three';
import {Utils} from "@app/services/class/Utils";
import {Mortaise} from "@app/services/class/mortaise";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {MathUtils} from "three";
import {FEMUR_REF} from "@app/shared/constant/ref_name.constant";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";

@Injectable({
  providedIn: 'root'
})

/**
 * Service used to load and manipulate the femur mortaise
 */
export class FemurMortaiseService extends Mortaise {

  data = MEASURE_JSON.MORTAISE_MEASURE.femur;
  //parameters for the users
  externalRotation = 0;
  posteriorResection = 8;
  notchingAnterior = 0;
  varusValgus = 0;
  distalResection = 8;
  flexionExtension = 0;
  //cuts measure
  private internalResectionDistal = 8;
  private externalResectionDistal = 8;
  private internalResectionPosterior = 8;
  private externalResectionPosterior = 8;
  private resectionAnterior: number;
  //ref
  // todo @Houssam variabiliser
  refAnteriorPosterior = FEMUR_REF.REF.POSTERIOR.value;
  sideModifier = this.dataSymfonyService.getSide() == 'right' ? -1 : 1
  //planes
  planAnt: THREE.Mesh

  constructor(dataSymfonyService: DataSymfonyService, manager: ManagerService,
              public materialService: MaterialService, private planifViewReferencesService: PlanifViewReferencesService) {
    super(manager, dataSymfonyService);
    this.material = this.materialService.createMortaiseMaterial()
    this.name = 'mortaise_femur_ptg'
  }

  //TODO : service Position ou ce service
  /**
   * This set the initial position of the femur mortaise (cut = 0.0mm) in the scene called and group the initial points
   * of the femur on it for measures. Then it reset the position to fit to the initial cut parameters (cut = 8.0mm)
   * @param {THREE.scene} scene The scene on which the method is called
   */
  setMortaiseFemurInitialPosition(scene) {
    let mortaise =  Utils.getMeshByName(scene, 'mortaise_femur_ptg')
    this.groupAnteriorPlane(scene)

    //set position
    mortaise.position.copy(this.getInitialPosition(scene))

    //set  so the plane get orthogonal to Femur meca axis
    const initRot = this.getInitialRotation(scene)
    mortaise.rotation.setFromQuaternion( initRot )

    mortaise.updateMatrix()
    this.groupPointOnMortaise(scene)

    this.updateFemurPosition([mortaise], scene)
  }

  /**
   * This method copies the two distal points and the two posterior points (external and internal) of the femur onto the
   * mortaise object. These points will undergo the same displacement as the mortaise and will be useful for measuring
   * the cuts.
   * @param {THREE.scene} scene The scene on which the method is called
   */
  groupPointOnMortaise(scene) {
    let newCondylePosteriorInt = Utils.getMeshByName(scene, 'condyle_posterior_internal').clone()
    newCondylePosteriorInt.name = 'condyle_posterior_internal_mortaise'
    newCondylePosteriorInt.visible = false
    let newCondylePosteriorExt = Utils.getMeshByName(scene, 'condyle_posterior_external').clone()
    newCondylePosteriorExt.name = 'condyle_posterior_external_mortaise'
    newCondylePosteriorExt.visible = false
    let newCondyleDistalInt = Utils.getMeshByName(scene, 'condyle_distal_internal').clone()
    newCondyleDistalInt.name = 'condyle_distal_internal_mortaise'
    newCondyleDistalInt.visible = false
    let newCondyleDistalExt = Utils.getMeshByName(scene, 'condyle_distal_external').clone()
    newCondyleDistalExt.name = 'condyle_distal_external_mortaise'
    newCondyleDistalExt.visible = false

    Utils.getMeshByName(scene, 'mortaise_femur_ptg').attach(newCondylePosteriorInt)
    Utils.getMeshByName(scene, 'mortaise_femur_ptg').attach(newCondylePosteriorExt)
    Utils.getMeshByName(scene, 'mortaise_femur_ptg').attach(newCondyleDistalInt)
    Utils.getMeshByName(scene, 'mortaise_femur_ptg').attach(newCondyleDistalExt)
  }

  /**
   * This method creates a plane merged with the previous cutting plane for measurements
   * @param {THREE.scene} scene The scene on which the method is called
   */
  groupAnteriorPlane(scene) {
    const x1 = this.getLength()
    const y1 = -this.getFemurDeltaYextAnt() - this.getWidth()
    const z1 = this.getHeight()
    const x2 = -this.getLength()
    const z2 = 0
    const y2 = y1 - (z1 - z2) * Math.tan(MathUtils.degToRad(this.getSlopeAnt()))

    const ptA = new THREE.Vector3(x1, y1, z1)
    const ptB = new THREE.Vector3(x1, y2, z2)
    const ptC = new THREE.Vector3(x2, y1, z1)
    const plane = Utils.createPlane(ptA, ptB, ptC)
    plane.name = "anterior_plane"
    plane.visible = false
    this.planAnt = plane
    Utils.getMeshByName(scene, 'mortaise_femur_ptg').attach(plane)
  }

  /**
   * This method returns the position vector of the femur mortaise when the distal cuts are at 0.0mm, and the posterior
   * cuts (if in posterior reference) at 0.0mm or the anterior cuts (if in anterior reference) at 0.0mm
   * @param {THREE.scene} scene The scene on which the method is called
   * @return {THREE.Vector3} The position vector of the mortaise when cuts are equal to 0.0mm
   */
  getInitialPosition(scene) {
    let vect = new THREE.Vector3()
    vect.x = Utils.getPositionByObjectName(scene, 'femur_mid').x
    if (this.refAnteriorPosterior == FEMUR_REF.REF.ANTERIOR.value) {
      vect.y = Utils.getPointPositionByName(scene, FEMUR_REF.REF.ANTERIOR.point).y + this.getFemurDeltaYintAnt()
    } else {
      vect.y = Utils.getPointPositionByName(scene, FEMUR_REF.REF.POSTERIOR.point).y + this.getFemurDeltaYintPost()
    }
    vect.z = Utils.getPointPositionByName(scene, 'condyle_distal_internal').z - this.getFemurDeltaZint()
    return vect
  }

  /**
   * This method returns the rotation of the femur mortaise in its initial position, which is orthogonal to the
   * mechanical plane of the femur
   * @param {THREE.scene} scene The scene on which the method is called
   * @return {THREE.Quaternion} The quaternion describing the rotation of the mortaise when orthogonal to the mechanical
   * femur plane
   */
  getInitialRotation(scene) {
    let quaternion = new THREE.Quaternion();
    return quaternion
  }

  //TODO Service position ou dans ce service
  /**
   * This method updates position of the femur cuts and implant
   * @param {THREE.Mesh} mesh A group composed of mesh that will undergo the translations made to the femoral cuts :
   * mortaise, condyle, points mortaise, points condyle
   * @param {THREE.Scene} scene the current scene
   */
  updateFemurPosition(mesh, scene) {
    const newX = this.getInitialPosition(scene).x
    const newY = this.getInitialPosition(scene).y + ((this.refAnteriorPosterior == FEMUR_REF.REF.ANTERIOR.value) ? -this.getNotchingAnterior() : this.getPosteriorResection());
    const newZ =  this.getInitialPosition(scene).z + this.getDistalResection()
    for (let i = 0; i < mesh.length; i = i + 1) {
      mesh[i].position.set(newX, newY, newZ)
    }
  }

  //TODO Service position ou dans ce service
  /**
   * This method updates rotation of the femur cuts and implant
   * @param {THREE.Mesh} mesh A group composed of mesh that will undergo the rotations made to the femoral cuts : mortaise,
   * condyle, points mortaise, points condyle. Each rotation depends on an axis and a rotation point (which can be
   * modified by the rotations, like the pointRotX, or by the translations, like the pointRotY). The rotation around Z
   * depends on the rotation axis choosen.
   */
  updateFemurRotation(mesh, scene) {

    const initRot = this.getInitialRotation(scene)
    const axeX = new THREE.Vector3(1,0,0)
    const axeY = new THREE.Vector3(0,1,0)
    const axeZ = new THREE.Vector3(0,0,1)
    //point for the flexion/extension rotation : center of the condyle distal circle
    let pointRotX = Utils.getPositionByObjectName(scene, 'dist')
    //point for the varus/valgus rotation : projection of the anatomical axis of the femur on the distal section
    let pointRotY = Utils.getPositionByObjectName(scene, 'femur_mid')
    pointRotY.z=Utils.getPositionByObjectName(scene, 'condyle_distal_internal_mortaise').z
    //point for the external/internal rotation : 1-middle of the femur, 2-internal posterior point of the prosthetic condyle
    let  pointRotZ = this.planifViewReferencesService.currentFemurRotationAxis===FEMUR_REF.ROT.MECA.value ? Utils.getPositionByObjectName(scene, FEMUR_REF.ROT.MECA.point) : Utils.getPositionByObjectName(scene, FEMUR_REF.ROT.POST.point)
    for (let i = 0; i < mesh.length; i = i + 1) {
      //reset the initial rotation before applying rotations from the beginning
      mesh[i].rotation.setFromQuaternion( initRot )
      Utils.rotateAroundWorldAxis( mesh[i], pointRotX, axeX, MathUtils.degToRad(-this.getFlexionExtension()))
      Utils.rotateAroundWorldAxis( mesh[i], pointRotY, axeY, MathUtils.degToRad(this.getVarusValgus()))
      Utils.rotateAroundWorldAxis( mesh[i], pointRotZ, axeZ, MathUtils.degToRad(this.getExternalRotation()))
    }
  }

  /**
   * @return {number} The calculated value of the internal posterior resection (value of the internal posterior cut of the
   * femur)
   */
  getInternalResectionPosterior() {
    return this.internalResectionPosterior
  }

  /**
   * This method sets the new value of the internal posterior resection
   * @param {number} n The calculated value of the internal posterior resection (value of the internal posterior cut of the
   * femur)
   */
  setInternalResectionPosterior(n: number) {
    this.internalResectionPosterior = n
  }

  /**
   * @return {number} The calculated value of the external posterior resection (value of the external posterior cut of the
   * femur)
   */
  getExternalResectionPosterior() {
    return this.externalResectionPosterior
  }

  /**
   * This method sets the new value of the external posterior resection
   * @param {number} n The calculated value of the external posterior resection (value of the external posterior cut of the
   * femur)
   */
  setExternalResectionPosterior(n: number) {
    this.externalResectionPosterior = n
  }

  /**
   * @return {number} The calculated value of the internal distal resection (value of the internal distal cut of the
   * femur)
   */
  getInternalResectionDistal() {
    return this.internalResectionDistal
  }

  /**
   * This method sets the new value of the internal distal resection
   * @param {number} n The calculated value of the internal distal resection (value of the internal distal cut of the
   * femur)
   */
  setInternalResectionDistal(n: number) {
    this.internalResectionDistal = n
  }

  /**
   * @return {number} The calculated value of the external distal resection (value of the external distal cut of the
   * femur)
   */
  getExternalResectionDistal() {
    return this.externalResectionDistal
  }

  /**
   * This method sets the new value of the external distal resection
   * @param {number} n The calculated value of the external distal resection (value of the external distal cut of the
   * femur)
   */
  setExternalResectionDistal(n: number) {
    this.externalResectionDistal = n
  }

  /**
   * @return {number} The value of the external rotation of the femur, set by the user
   */
  getExternalRotation() {
    return this.externalRotation
  }

  /**
   * @return {number} The value of the posterior resection of the femur, set by the user
   */
  getPosteriorResection() {
    return this.posteriorResection
  }

  /**
   * @return {number} The value of the anterior notching of the femur, set by the user
   */
  getNotchingAnterior() {
    return this.notchingAnterior
  }

  /**
   * @return {number} The value of the varus valgus angle of the femur, set by the user
   */
  getVarusValgus() {
    return this.varusValgus
  }

  /**
   * @return {number} The value of the distal resection of the femur, set by the user
   */
  getDistalResection() {
    return this.distalResection
  }

  /**
   * @return {number} The value of the flexion extension angle of the femur, set by the user
   */
  getFlexionExtension() {
    return this.flexionExtension
  }

  /**
   * @return {number} The distance in Z between the center of the reference and the inside of the distal face of the mortaise
   */
  getFemurDeltaZint() {
    return this.data.deltaZint
  }

  /**
   * @return {number} The distance in Z between the center of the reference and the outside of the distal face of the mortaise
   */
  getFemurDeltaZext() {
    return this.data.deltaZext
  }

  /**
   * @return {number} The distance in Y between the center of the reference and the inside of the anterior face of the mortaise
   */
  getFemurDeltaYintAnt() {
    return this.data.deltaYintAnt
  }

  /**
   * @return {number} The distance in Y between the center of the reference and the outside of the anterior face of the mortaise
   */
  getFemurDeltaYextAnt() {
    return this.data.deltaYextAnt
  }

  /**
   * @return {number} The distance in Y between the center of the reference and the inside of the posterior face of the mortaise
   */
  getFemurDeltaYintPost() {
    return this.data.deltaYintPost
  }

  /**
   * @return {number} The distance in Y between the center of the reference and the outside of the posterior face of the mortaise
   */
  getFemurDeltaYextPost() {
    return this.data.deltaYextPost
  }

  /**
   * @return {number} The width of the mortaise
   */
  getWidth() {
    return this.data.l
  }

  /**
   * @return {number} The height of the mortaise
   */
  getHeight() {
    return this.data.h
  }

  /**
   * @return {number} The length of the mortaise
   */
  getLength() {
    return this.data.L
  }

  /**
   * @return {number} The anterior slope of the internal anterior plane
   */
  getSlopeAnt() {
    return this.data.slopeAnt
  }

  /**
   * @return {number} The posterior slope of the internal posterior plane
   */
  getSlopePost() {
    return this.data.slopePost
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buttonLeftDisplay'
})
export class ButtonLeftDisplayPipe implements PipeTransform {

  transform(value: number, sideModifier: number): unknown {
    return (sideModifier*value >= 0) ? "-" : "+"
  }
}

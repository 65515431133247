export const USER = {
  user: {
    firstName: 'Toto',
    lastName: 'coucou',
    role: 'surgeon',
    lang: 'en'
  },
  // TODO @TechMeet
  preference: {
    theme : 'dark',
    // @Input preop or planif
    step : 'preop',
    femur : {
      implantRange : '',
    },
    tibia : {
      implantRange : ''
    }
  }
};

import {ElementRef, Injectable, ViewChild} from '@angular/core';
import * as THREE from 'three';
import {LoadingManager} from 'three';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  private _manager = new THREE.LoadingManager();

  constructor() {
  }

  set manager(value: LoadingManager) {
    this._manager = value;
  }

  get manager() {
    this._manager.onStart = this.onStart;
    this._manager.onLoad = this.onLoad;
    this._manager.onProgress = this.onProgress;
    this._manager.onError = this.onError;
    return this._manager;
  }

  onStart(url, itemsLoaded, itemsTotal) {
    console.log('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
  }

  onLoad() {
    console.log('Loading complete!');
  }

  onProgress(url, itemsLoaded, itemsTotal) {
    console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
  }

  onError(url) {
    console.log('There was an error loading ' + url);
  }
}

<div id="comm" cdkDragBoundary=".router-container" cdkDrag>
  <div class="close" (click)=" commService.toggleCommIsOpen() ">
    <i class="icon icon-close"></i>
  </div>
  <div class="title">
    Annotation patient :
  </div>
  <textarea [(ngModel)]=" comm " rows="10" placeholder="Tapez vos annontations ici..."></textarea>
  <button id="valid_comm" (click)=" commService.setComm( comm ) ">
    Valider
  </button>
  <!--<div>comment: {{ commService.getComm() }}</div>-->
</div>

import {Component, ElementRef, OnInit, Input, ViewChild} from '@angular/core';
import { TooltipsService } from '../../../../services/preop/tooltips.service';

/**
 * Component
 */

@Component({
  selector: 'app-po-view-front',
  templateUrl: './po-view-front.component.html',
  styleUrls: ['./po-view-front.component.scss']
})
export class PoViewFrontComponent implements OnInit {

  public tt;

  constructor(tooltipsService: TooltipsService ) {
    this.tt = tooltipsService.getState();
  }

  ngOnInit(): void {

  }

  mldfa = 89;
  mpta = 82.5;
  hks = 4.5;
  varus = 1;

  renderMLDFA() {
    return this.mldfa.toFixed( 1 ) + "°";
  }

  renderMPTA() {
    return this.mpta.toFixed( 1 ) + "°";
  }

  renderHKS() {
    return this.hks.toFixed( 1 ) + "°";
  }

  renderVarus() {
    return this.varus.toFixed( 1 ) + "°";
  }

}

import { Component, OnInit } from '@angular/core';
import { SelectTibiaService } from './select-tibia.service';
import { GlobalService } from '../../../services/global/global.service';
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {
  TibiaViewProximalService
} from "@app/ui/screens/planif/pl-panel-right/tibia-view-proximal/tibia-view-proximal.service";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";
import {PlanifSlicerService} from "@app/ui/screens/planif/planif-slicer.service";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";

/**
 * Component used on the planification page to define the tibial view reference for the implant, and choose the size
 * and type of tibial implant
 */

@Component({
  selector: 'app-select-tibia',
  templateUrl: './select-tibia.component.html',
  styleUrls: ['./select-tibia.component.scss']
})
export class SelectTibiaComponent implements OnInit {

  public refList;
  public ref;
  public axeList;
  public axe;
  public rangeList;
  public range;
  public sizeList;
  public size;

  constructor( selectTibiaService: SelectTibiaService, public globalService: GlobalService,
               private planifViewManagerService : PlanifViewManagerService, private tibiaViewProximalService: TibiaViewProximalService,
               private tibiaMortaiseService: TibiaMortaiseService, private planifSlicerService: PlanifSlicerService,
               private planifViewReferencesService: PlanifViewReferencesService) {

    this.refList = selectTibiaService.getRefList();
    this.ref = selectTibiaService.getRef();
    this.axeList = selectTibiaService.getAxeList();
    this.axe = selectTibiaService.getAxe();
    this.rangeList = selectTibiaService.getRangeList();
    this.range = selectTibiaService.getRange();
    this.sizeList = selectTibiaService.getSizeList();
    this.size = selectTibiaService.getSize();
  }

  ngOnInit(): void {
  }

  refChange() {
    this.tibiaMortaiseService.refAkagiPosterior= this.ref.value
    this.tibiaMortaiseService.internalExternalRotation = 0
    this.planifSlicerService.updateTibiaCut()
  }

  measureChange() {
    this.planifViewReferencesService.setCurrentTibiaMeasureAxis(this.axe.value, this.tibiaViewProximalService.scene)
  }
}

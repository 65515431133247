import {Injectable} from '@angular/core';
import {Scene} from "three";
import {DataSymfonyService} from "@app/api/data-symfony.service";

@Injectable({
  providedIn: 'root'
})
export class SceneService {

  constructor(private dataService: DataSymfonyService) {
  }

  createAnonymous() {
    const scene = new Scene();
    /*
         scene.rotation.copy(this.dataService.getNiiRotationEuler());
    */
    return scene;
  }

  orientation(scene) {
    scene.rotation.copy(this.dataService.getNiiRotationEuler());
  }
}

import { Component, OnInit } from '@angular/core';
import {DataSymfonyService} from "@app/api/data-symfony.service";

/**
 * Left component of the toolbar, gives infos about VARUS/VALGUS, Hip Knee Ankle (HKA) angle, and if cartilage is present
 */

@Component({
  selector: 'app-toolbar-infos',
  templateUrl: './toolbar-infos.component.html',
  styleUrls: ['./toolbar-infos.component.scss']
})
export class ToolbarInfosComponent implements OnInit {

  constructor(private dataService: DataSymfonyService) { }

  ngOnInit(): void {
  }

  varus: any = 1;

  /**
   * A method that return the VARUS angle in a string for display
   */
  varusRender() {
    return "VARUS " + parseFloat( this.varus ).toFixed( 1 );
  }

  getSideInitial() {
    let res = "";
    let side = this.dataService.getIntervention().side;
    if( side == "left" ) {
      res += "G";
    }
    if( side == "right" ) {
      res += "D";
    }
    return res;
  }

}

<div class="dialog-title">
  <div class="title">
    <i class="icon icon-reset"></i> <span>Remettre à zéro ?</span>
  </div>
  <div class="close" [mat-dialog-close]>
    <i class="icon icon-close"></i>
  </div>
</div>
<div class="sub-part embed">
  <p>Êtes-vous sûr de vouloir remettre les valeurs à zéro ?</p>
</div>
<div class="sub-part">
  <div class="btns">
    <button type="button" class="dialog-no" [mat-dialog-close]>
      <span>Non</span>
    </button>
    <button type="button" class="dialog-yes" [mat-dialog-close]>
      <span>Oui</span>
    </button>
  </div>
</div>

import {Injectable} from '@angular/core';
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {ManagerService} from "@app/services/three/manager.service";
import {MaterialService} from "@app/services/three/material.service";
import * as MEASURE_JSON from '@app/shared/constant/mortaise_measure';
import * as THREE from 'three';
import {Utils} from "@app/services/class/Utils";
import {Mortaise} from "@app/services/class/mortaise";
import {MathUtils} from "three";
import {TIBIA_REF} from "@app/shared/constant/ref_name.constant";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";
import {TibiaService} from "@app/services/bone/tibia.service";

@Injectable({
  providedIn: 'root'
})

/**
 * Service used to load and manipulate the tibia mortaise
 */
export class TibiaMortaiseService extends Mortaise {

  data = MEASURE_JSON.MORTAISE_MEASURE.tibia;
  internalExternalRotation = 0
  varusValgus = 0;
  tibialResection = 10;
  tibialSlope = 0;
  private externalResectionTibial= 10 ;
  private internalResectionTibial= 10 ;
  refAkagiPosterior= TIBIA_REF.REF.AKAGI.value  //"akagi" or "plateau_posterior",
  sideModifier= this.dataSymfonyService.getSide()=='right' ? 1 : -1
  angleAkagiPosterior: number;


  constructor(dataSymfonyService: DataSymfonyService, manager: ManagerService,
              public materialService: MaterialService, private planifViewReferencesService: PlanifViewReferencesService,
              private tibiaService: TibiaService) {
    super(manager, dataSymfonyService);
    this.material = this.materialService.createMortaiseMaterial()
    this.name = 'mortaise_tibia_ptg'
  }

  //TODO : service Position ou ce service
  /**
   * This set the initial position of the tibia mortaise (cut = 0.0mm) in the scene called and group the initial points
   * of the tibia on it for measures. Then it reset the position to fit to the initial cut parameters (cut = 10.0mm)
   * @param {THREE.Scene} scene the scene on which the mortaise position is calculated
   */
  setMortaiseTibiaInitialPosition(scene) {
    let mortaise =  Utils.getMeshByName(scene, 'mortaise_tibia_ptg')

    //set position
    mortaise.position.copy(this.getInitialPosition(scene))

    //set  so the plane get orthogonal to Tibia meca axis
    const initRot = this.getInitialRotation(scene)
    mortaise.rotation.setFromQuaternion( initRot )

    mortaise.updateMatrix()
    this.groupPointOnMortaise(scene)
    this.tibiaService.setMatrixTibiaToWorld(scene)

    this.updateTibiaPosition([mortaise], scene)
  }

  /**
   * This method copies the two cup base points (external and internal) of the tibia onto the mortaise object. These
   * points will undergo the same displacement as the mortaise and will be useful for measuring the cuts.
   * @param {THREE.scene} scene The scene on which the method is called
   */
  groupPointOnMortaise(scene) {
    let newCupBaseInt = Utils.getMeshByName(scene, 'cup_base_internal').clone()
    newCupBaseInt.name = 'cup_base_internal_mortaise'
    newCupBaseInt.visible = false
    let newCupBaseExt = Utils.getMeshByName(scene, 'cup_base_external').clone()
    newCupBaseExt.name = 'cup_base_external_mortaise'
    newCupBaseExt.visible = false

    Utils.getMeshByName(scene, 'mortaise_tibia_ptg').attach(newCupBaseInt)
    Utils.getMeshByName(scene, 'mortaise_tibia_ptg').attach(newCupBaseExt)
  }

  /**
   * This method returns the position vector of the tibia mortaise when the tibial resection is at 0.0mm
   * @param {THREE.scene} scene The scene on which the method is called
   * @return {THREE.Vector3} The position vector of the mortaise when cuts are equal to 0.0mm
   */
  getInitialPosition(scene) {
    let vect = new THREE.Vector3()
    vect.x = Utils.getPositionByObjectName(scene,   'tibia_spine_center').x
    vect.y = Utils.getPointPositionByName(scene, 'tibia_spine_center').y + 4 //TODO delete after Christophe démo
    vect.z= Utils.getPointPositionByName(scene, 'cup_base_internal').z - this.getTibiaDeltaZint()

    return vect
  }

  /**
   * This method returns the rotation of the tibia mortaise in its initial position, which is orthogonal to the
   * mechanical plane of the tibia, and the posterior anterior line of the plate is aligned with the Akagi axis
   * @param {THREE.scene} scene The scene on which the method is called
   * @return {THREE.Quaternion} The quaternion describing the rotation of the mortaise when orthogonal to the mechanical
   * tibia plane
   */
  getInitialRotation(scene) {
    const vectTibiaMeca = this.planifViewReferencesService.getTibiaMecaAxis(scene)
    let vectTibiaY = new THREE.Vector3()
    let vectTibiaX = new THREE.Vector3()
    const vectAkagiA = Utils.getPositionByObjectName(scene, TIBIA_REF.REF.AKAGI.pointA)
    const vectAkagiP= Utils.getPositionByObjectName(scene, TIBIA_REF.REF.AKAGI.pointB)
    vectTibiaY.set(vectAkagiA.x-vectAkagiP.x, vectAkagiA.y-vectAkagiP.y, vectAkagiA.z-vectAkagiP.z)
    vectTibiaY.normalize()
    vectTibiaY.z=0 //the akagi axis is not always parallel to the plane of the plate, but the plane of the plate is
    // already oriented by the tibia meca axis
    const vectPostExt = Utils.getPositionByObjectName(scene, TIBIA_REF.REF.POSTERIOR.pointA)
    const vectPostInt = Utils.getPositionByObjectName(scene, TIBIA_REF.REF.POSTERIOR.pointB)
    vectTibiaX.set(vectPostInt.x-vectPostExt.x, vectPostInt.y-vectPostExt.y, vectPostInt.z-vectPostExt.z)
    vectTibiaX.normalize()
    vectTibiaX.z=0

    const qAkagi = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0,1,0), vectTibiaY)
    const qPosterior = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(-1,0,0), vectTibiaX)
    this.angleAkagiPosterior = MathUtils.radToDeg(qAkagi.angleTo(qPosterior))
    const qPlateExternalRot = this.refAkagiPosterior===TIBIA_REF.REF.AKAGI.value ? qAkagi : qPosterior

    let qAxeMeca = new THREE.Quaternion();
    qAxeMeca.setFromUnitVectors( new THREE.Vector3(0,0,1), vectTibiaMeca )
    qAxeMeca.multiply(qPlateExternalRot)
    return qAxeMeca
  }

  //TODO Service position ou dans ce service
  /**
   * This method updates position of the tibia cuts and implant
   * @param {THREE.Mesh} mesh A group composed of meshes whose position will be updated with to the new set values.
   * This group is composed of the mortaise, the plate implant, and the attached points.
   * @param {THREE.Scene} scene the current scene
   */
  updateTibiaPosition (mesh, scene) {
    const axeTibiaZ = this.planifViewReferencesService.getTibiaMecaAxis(scene)
    //update proximal resection
    const newX = -this.getTibialResection()*axeTibiaZ.x + this.getInitialPosition(scene).x
    const newY = -this.getTibialResection()*axeTibiaZ.y + this.getInitialPosition(scene).y
    const newZ = -this.getTibialResection()*axeTibiaZ.z + this.getInitialPosition(scene).z
    for (let i = 0; i < mesh.length; i = i + 1) {
      mesh[i].position.set(newX, newY, newZ)
    }
  }

  //TODO Service position ou dans ce service
  /**
   * This method updates rotation of the tibia cuts and implant
   * @param {THREE.Mesh} mesh A group composed of meshes whose rotation will be updated with to the new set values.
   * This group is composed of the mortaise, the plate implant, and the attached points.
   * @param {THREE.Scene} scene the current scene
   */
  updateTibiaRotation (mesh, scene) {
    //update varus/valgus angle
    const axeTibiaZ = this.planifViewReferencesService.getTibiaMecaAxis(scene)
    const axeTibiaY = new THREE.Vector3(axeTibiaZ.y,axeTibiaZ.z,axeTibiaZ.x)
    const axeTibiaX = new THREE.Vector3(axeTibiaZ.z,axeTibiaZ.x,axeTibiaZ.y)
    let center = Utils.getPositionByObjectName(scene, 'mortaise_tibia_ptg')
    const initRot = this.getInitialRotation(scene)
    for (let i = 0; i < mesh.length; i = i + 1) {
      mesh[i].rotation.setFromQuaternion( initRot )
      //reset the center to update the position after the previous transformation
      center = Utils.getPositionByObjectName(scene, 'mortaise_tibia_ptg')
      Utils.rotateAroundWorldAxis(mesh[i], center ,axeTibiaY, MathUtils.degToRad(this.getVarusValgus()))
      center = Utils.getPositionByObjectName(scene, 'mortaise_tibia_ptg')
      Utils.rotateAroundWorldAxis(mesh[i], center ,axeTibiaZ, MathUtils.degToRad(this.getInternalExternalRotation()))
      center = Utils.getPositionByObjectName(scene, 'mortaise_tibia_ptg')
      Utils.rotateAroundWorldAxis(mesh[i], center ,axeTibiaX, MathUtils.degToRad(this.getTibialSlope()))
    }
  }

  /**
   * @return {number} The calculated value of the external tibial resection (value of the external cut of the tibia)
   */
  getExternalResectionTibial() {
    return this.externalResectionTibial
  }

  /**
   * This method sets the new value of the external tibial resection
   * @param {number} n The calculated value of the external tibial resection (value of the external cut of the tibia)
   */
  setExternalResectionTibial(n: number ) {
    this.externalResectionTibial = n
  }

  /**
   * @return {number} The calculated value of the internal tibial resection (value of the internal cut of the tibia)
   */
  getInternalResectionTibial() {
    return this.internalResectionTibial
  }

  /**
   * This method sets the new value of the internal tibial resection
   * @param {number} n The calculated value of the internal tibial resection (value of the internal cut of the tibia)
   */
  setInternalResectionTibial(n: number) {
    this.internalResectionTibial = n
  }

  /**
   * @return {number} The value of the internal external rotation angle of the tibia, set by the user
   */
  getInternalExternalRotation() {
    return this.internalExternalRotation
  }

  /**
   * @return {number} The value of the varus valgus angle of the tibia, set by the user
   */
  getVarusValgus() {
    return this.varusValgus
  }

  /**
   * @return {number} The value of the tibial resection, set by the user
   */
  getTibialResection() {
    return this.tibialResection
  }

  /**
   * @return {number} The value of the tibial slope, set by the user
   */
  getTibialSlope() {
    return this.tibialSlope
  }

  /**
   * @return {number} The distance in Z between the center of the reference and the inside face of the mortaise
   */
  getTibiaDeltaZint() {
    return this.data.deltaZint
  }

  /**
   * @return {number} The distance in Z between the center of the reference and the outside face of the mortaise
   */
  getTibiaDeltaZext() {
    return this.data.deltaZext
  }
}

import { Component, OnInit } from '@angular/core';
import { FemurAdjustmentService } from './femur-adjustment.service';
import { ViewsChoiceService } from '../../../services/planif/views-choice.service';
import { GlobalService } from '../../../services/global/global.service';


/**
 * Component used on the planification page to apply rotations and transitions to femoral voew
 */

@Component({
  selector: 'app-femur-adjustment',
  templateUrl: './femur-adjustment.component.html',
  styleUrls: ['./femur-adjustment.component.scss']
})
export class FemurAdjustmentComponent implements OnInit {

  constructor( public femurAdjustmentService: FemurAdjustmentService, public viewsChoiceService: ViewsChoiceService,
               public globalService: GlobalService) {
  }

  ngOnInit(): void {
  }

}

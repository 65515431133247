<div id="femur_view_two">
  <canvas #femurViewFront style="text-align:center;">FEMUR 2</canvas>
  <span class="value" id="f_v_5">{{ this.femurViewFrontService.getLeftValue() }} mm</span>
  <span class="value" id="f_v_6">{{ this.femurViewFrontService.getRightValue() }} mm</span>
  <div class="axe">
    <span>{{this.getLeftSideIndicator()}}</span>
    <span>{{this.getRightSideIndicator()}}</span>
  </div>
  <app-loader></app-loader>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import {FEMUR_BUTTON} from "@app/shared/constant/button_name.constant";

@Pipe({
  name: 'femurFlexionExtensionDisplay'
})
export class FemurFlexionExtensionDisplayPipe implements PipeTransform {
  constructor () {
  }

  transform(value: number){
    return value>=0 ? FEMUR_BUTTON.FL : FEMUR_BUTTON.EX
  }
}

import { Component, OnInit } from '@angular/core';

/**
 * Component that generate the header of the application, containing the navigation system and patient info
 */

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';

import {TibiaViewProximalService} from "@app/ui/screens/planif/pl-panel-right/tibia-view-proximal/tibia-view-proximal.service";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";

/**
 * Component that defines the distal view (n°1) of the tibia
 */

@Component({
  selector: 'app-tibia-view-proximal',
  templateUrl: './tibia-view-proximal.component.html',
  styleUrls: ['./tibia-view-proximal.component.scss']
})
export class TibiaViewProximalComponent implements OnInit {

  @ViewChild('tibiaViewProximal', {static: true})
  public tibiaViewProximal: ElementRef<HTMLCanvasElement>;
  value3 = 15.5;
  count = 0;
  targetPoint='akagi_anterior'

  constructor(public tibiaViewProximalService: TibiaViewProximalService, private tibiaMortaiseService: TibiaMortaiseService) { }

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    console.log(event);
    if (event.deltaY > 0) {
      this.count++
    } else {
      this.count--;
    }
    console.log(this.count);
    this.tibiaViewProximalService.scroll(this.count);
  }


  ngOnInit(): void {
    this.tibiaViewProximalService.createScene(this.tibiaViewProximal);
    this.tibiaViewProximalService.loadObject();
    this.tibiaViewProximalService.loadImplants();
    //this.tibiaViewProximalService.loadImages(this);
    this.tibiaViewProximalService.animate();
  }

  ngOnChanges(): void {
    this.tibiaViewProximalService.resize();
  }

  /**
   * This method indicates where the lateral and medial sides are, which depends on the operated knee side. The medial
   * side is displayed on the left for a left knee, on the right for a right knee
   * @return {string} Returns 'L' if the case is a right knee, 'M' otherwise.
   */
  getLeftSideIndicator() {
    if (this.tibiaMortaiseService.side == 'right') {
      return 'L'
    }
    else {
      return 'M'
    }
  }

  /**
   * This method indicates where the lateral and medial sides are, which depends on the operated knee side
   * @return {string} Returns 'M' if the case is a right knee, 'L' otherwise.
   */
  getRightSideIndicator() {
    if (this.tibiaMortaiseService.side == 'right') {
      return 'M'
    }
    else {
      return 'L'
    }
  }
}

import {Injectable} from '@angular/core';
import {Implant} from '../class/implant';
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {ManagerService} from "@app/services/three/manager.service";
import {MaterialService} from "@app/services/three/material.service";
import {Observable} from "rxjs";
import {Mesh} from "three";
import {VirginBone} from "@app/services/class/virginBone";
import {Utils} from "@app/services/class/Utils";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";

@Injectable({
  providedIn: 'root'
})

/**
 * Service used to load and manipulate the condyle implant
 */
export class CondyleService extends Implant {
  constructor(dataSymfonyService: DataSymfonyService, manager: ManagerService,
              public materialService: MaterialService, private virginBone: VirginBone,
              private femurMortaiseService: FemurMortaiseService) {
    super(manager, dataSymfonyService);
    this.name = 'condyle';
    this.size= this.dataSymfonyService.getPlanification().size_femur
    this.range= this.dataSymfonyService.getPlanification().range_femur
    this.url = `${location.origin}/${this.dir}/${this.name}_${this.range}_${this.size}_${this.side}.obj`;
    this.material = this.materialService.createCondyleMaterial()
  }

  /**
   * This method loads the condyle points, used for rotations
   * @param {string} name The name of the point to load
   * @param {boolean} displayed True if the point will be displayed, false else
   * @return {Observable} The mesh of the point loaded
   */
  loadCondylePoints(name: string, displayed: boolean): Observable<Mesh> {
    const url =`${location.origin}/${this.dir}/${this.name}_${this.range}_${this.size}_${this.side}_${name}_point.obj`;
    const material =  displayed ? this.materialService.createFemurPointMaterial() : this.materialService.createPointInvisibleMaterial();
    return this.virginBone.load(url, name, material);
  }

  //TODO : service Position ou ce service
  /**
   * This set the initial position of the condyle (cut = 0.0mm) in the scene called
   * @param {THREE.scene} scene The scene on which the method is called
   */
  setCondyleInitialPosition(scene) {
    this.groupPointOnCondyle(scene)

    let condyleGroup = Utils.getMeshByName(scene, 'condyle')

    //set position
    this.femurMortaiseService.updateFemurPosition([condyleGroup], scene)
    this.femurMortaiseService.updateFemurPosition([condyleGroup], scene)
  }

  /**
   * This method groups the condyle points and the condyle to manipulate them together
   * @param {THREE.scene} scene The scene on which the method is called
   */
  groupPointOnCondyle(scene) {
    const ptsPost = Utils.getMeshByName(scene, 'post')
    const ptsDist = Utils.getMeshByName(scene, 'dist')
    const condyle = Utils.getMeshByName(scene, 'condyle')

    condyle.attach(ptsPost)
    condyle.attach(ptsDist)
    /*
    const condyleGroup = new THREE.Group()
    condyleGroup.name = 'condyle_group'
    condyleGroup.add(ptsDist, ptsPost, condyle)
    scene.add(condyleGroup) */
  }
}

import { Component, OnInit } from '@angular/core';
import { CommentService } from './comment.service';

/**
 * Component used to get comment from the user and display it
 */
@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  comm;

  constructor( public commService: CommentService ) {
    this.comm = this.commService.getComm();
  }

  ngOnInit(): void {
  }

}

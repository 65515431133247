import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PlPanelLeftComponent} from "./pl-panel-left.component";
import {FemurSwitchViewsComponent} from "./femur-switch-views/femur-switch-views.component";
import {PanelToggleComponent} from "./panel-toggle/panel-toggle.component";
import {OverviewComponent} from "./overview/overview.component";
import {TibiaSwitchViewsComponent} from "./tibia-switch-views/tibia-switch-views.component";
//import { LoaderComponent } from '@app/shared/component/loader/loader.component';

@NgModule({
  declarations: [PlPanelLeftComponent, FemurSwitchViewsComponent, PanelToggleComponent, OverviewComponent, TibiaSwitchViewsComponent, /*LoaderComponent*/],
  imports: [
    CommonModule
  ],
  exports: [
    PlPanelLeftComponent
  ]
})
export class PlPanelLeftModule { }

import { Injectable } from '@angular/core';

/**
 * Service that provides functions for users comments
 */
@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor() { }

  private commIsOpen = false;
  private comm = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis. Donec luctus urna eu ullamcorper mollis. Curabitur nec pulvinar sem, at luctus tellus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis. Donec luctus urna eu ullamcorper mollis. Curabitur nec pulvinar sem, at luctus tellus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis.';

  /**
   * method to call to get the value of comment.
   */
  getComm() {
    return this.comm;
  }

  /**
   * method that return a true if the comment panel is open, false if it is hidden.
   */
  getCommIsOpen() {
    return this.commIsOpen;
  }

  /**
   * method called when the comment panel is opened or closed. Set the value of commIsOpen to false if the panel is closed
   * and to true if it is opened.
   */
  toggleCommIsOpen() {
    this.commIsOpen = !this.commIsOpen;
  }

  /**
   * method that returns an info about the comment. Returns a true if there is comment stored in comment, and false if there
   * is no comment.
   */
  hasComm() {
    return this.comm != '';
  }

  /**
   * method that set a new comment. Set a new value for comment and open/close the comment panel
   *
   * @param {string} str the comment added by the user
   */
  setComm( str: string ) {
    this.comm = str;
    this.commIsOpen = !this.commIsOpen;
  }
}

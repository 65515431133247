import { Pipe, PipeTransform } from '@angular/core';
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";
import {FEMUR_BUTTON} from "@app/shared/constant/button_name.constant";

@Pipe({
  name: 'tibiaVarusValgusDisplay'
})
export class TibiaVarusValgusDisplayPipe implements PipeTransform {
  constructor (private tibiaMortaiseService: TibiaMortaiseService) {
  }

  transform(value: number){
    return (value*this.tibiaMortaiseService.sideModifier)>=0 ? FEMUR_BUTTON.VAR : FEMUR_BUTTON.VAL
  }
}

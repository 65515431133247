<div id="po_view_right">
  <div style="text-align:center;">VUE 2</div>
  <div class="pov-value-container" id="pvc_flexion">
    <div class="tt-toggle" [class.active]="tt.flexion" (click)=" tt.flexion = !tt.flexion ">
      <div class="tt-toggle-indicator">
        <i class="icon icon-info"></i>
      </div>
      Flexion {{ renderFlexion() }}
    </div>
    <div class="tt-content tt-arrow-top">
      Angle entre l’axe mécanique du fémur et l’axe mecanique du tibia dans le plan sagittal du fémur
    </div>
  </div>
  <div class="pov-value-container" id="pvc_tibial_slope">
    <div class="tt-toggle" [class.active]="tt.tibialSlope" (click)=" tt.tibialSlope = !tt.tibialSlope ">
      <div class="tt-toggle-indicator">
        <i class="icon icon-info"></i>
      </div>
      Pente tibiale {{ renderTibialSlope() }}
    </div>
    <div class="tt-content tt-arrow-bottom">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum purus eros, sit amet pretium ligula feugiat quis. Donec luctus urna eu ullamcorper mollis. Curabitur nec pulvinar sem, at luctus tellus.
    </div>
  </div>
  <!--flexion: {{tt.flexion}}<br>
  tibialSlope: {{tt.tibialSlope}}-->
</div>

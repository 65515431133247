<div id="tibia_switch_views">
  <div class="toggle-all" (click)=" viewsChoiceService.clickTibias() ">
    <i class="icon icon-tibia"></i>
    <span>TIBIA</span>
    <i class="icon icon-quit"></i>
  </div>
  <div class="toggle-ones">
    <div class="toggle-one" [class.active]=" viewsChoiceService.getTibiaDist() && viewsChoiceService.getFemurTibias() != 'all' " (click)=" viewsChoiceService.clickTibiaDist() ">
      <img src="../../../../assets/madison/img/mini_tibia_1.png">
    </div>
    <div class="toggle-one" [class.active]=" viewsChoiceService.getTibiaFront() && viewsChoiceService.getFemurTibias() != 'all' " (click)=" viewsChoiceService.clickTibiaFront() ">
      <img src="../../../../assets/madison/img/mini_tibia_2.png">
    </div>
    <div class="toggle-one" [class.active]=" viewsChoiceService.getTibiaSag() && viewsChoiceService.getFemurTibias() != 'all' " [class.inverted]=" viewsChoiceService.getSagIsInverted() " (click)=" viewsChoiceService.clickTibiaSag() ">
      <img src="../../../../assets/madison/img/mini_tibia_3.png">
    </div>
  </div>
</div>

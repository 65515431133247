<div class="femur-adjustment" [class.horizontal]=" viewsChoiceService.getFemurTibias() == 'all' " [class.vertical]=" viewsChoiceService.getFemurTibias() != 'all' " [class.disabled]= " globalService.getValided() ">
  <div class="adjustment-item">
    <div class="title">
      {{femurAdjustmentService.getRotationInternalExternal() | femurExternalInternalDisplay}}
    </div>
    <div class="adjustment-row">
      <button type="button" class="minus" (click)=" femurAdjustmentService.minusRotationInternalExternal() ">
        {{femurAdjustmentService.getRotationInternalExternal() | buttonLeftDisplay : this.femurAdjustmentService.femurMortaiseService.sideModifier}}
      </button>
      <div class="value">
        {{ femurAdjustmentService.getAbsoluteRotationInternalExternal() }}°
      </div>
      <button type="button" class="plus" (click)=" femurAdjustmentService.plusRotationInternalExternal() ">
        {{femurAdjustmentService.getRotationInternalExternal() | buttonRightDisplay : this.femurAdjustmentService.femurMortaiseService.sideModifier}}
      </button>
    </div>
  </div>
  <div class="separator"></div>
  <div class="adjustment-item">
    <div class="title">
      {{ femurAdjustmentService.femurMortaiseService.refAnteriorPosterior | femurAnterioPosteriorDisplay }}
    </div>
    <div class="adjustment-row">
      <button type="button" class="minus" (click)=" femurAdjustmentService.minusResectionAnteriorPosterior() ">
        -
      </button>
      <div class="value">
        {{ femurAdjustmentService.getResectionAnteriorPosterior() }} mm
      </div>
      <button type="button" class="plus" (click)=" femurAdjustmentService.plusResectionAnteriorPosterior() ">
        +
      </button>
    </div>
  </div>
  <div class="separator"></div>
  <div class="adjustment-item">
    <div class="title">
      {{femurAdjustmentService.getVarusValgus() | femurVarusValgusDisplay}}
    </div>
    <div class="adjustment-row">
      <button type="button" class="minus" (click)=" femurAdjustmentService.minusVarusValgus() ">
        {{femurAdjustmentService.getVarusValgus() | buttonLeftDisplay : this.femurAdjustmentService.femurMortaiseService.sideModifier}}
      </button>
      <div class="value">
        {{ femurAdjustmentService.getAbsoluteVarusValgus() }}°
      </div>
      <button type="button" class="plus" (click)=" femurAdjustmentService.plusVarusValgus() ">
        {{femurAdjustmentService.getVarusValgus() | buttonRightDisplay : this.femurAdjustmentService.femurMortaiseService.sideModifier}}
      </button>
    </div>
  </div>
  <div class="separator"></div>
  <div class="adjustment-item">
    <div class="title">
      Résection distale
    </div>
    <div class="adjustment-row">
      <button type="button" class="minus" (click)=" femurAdjustmentService.minusResectionDistal() ">
        -
      </button>
      <div class="value">
        {{ femurAdjustmentService.getResectionDistal() }} mm
      </div>
      <button type="button" class="plus" (click)=" femurAdjustmentService.plusResectionDistal() ">
        +
      </button>
    </div>
  </div>
  <div class="separator"></div>
  <div class="adjustment-item">
    <div class="title">
      {{femurAdjustmentService.getFlexionExtension() | femurFlexionExtensionDisplay}}
    </div>
    <div class="adjustment-row">
      <button type="button" class="minus" (click)=" femurAdjustmentService.minusFlexionExtension() ">
        {{femurAdjustmentService.getFlexionExtension() | buttonLeftDisplay : 1}}
      </button>
      <div class="value">
        {{ (femurAdjustmentService.getAbsoluteFlexionExtension()) }}°
      </div>
      <button type="button" class="plus" (click)=" femurAdjustmentService.plusFlexionExtension() ">
        {{femurAdjustmentService.getFlexionExtension() | buttonRightDisplay : 1}}
      </button>
    </div>
  </div>
  <!--externalRotation: {{ externalRotation }}<br>
  posteriorResection: {{ posteriorResection }}<br>
  varusValgus: {{ varusValgus }}<br>
  distalResection: {{ distalResection }}<br>
  flexionExtension: {{ flexionExtension }}-->
</div>

import {Injectable} from '@angular/core';
import {Bone} from '../class/bone';
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {ManagerService} from "@app/services/three/manager.service";
import {MaterialService} from "@app/services/three/material.service";

@Injectable({
  providedIn: 'root'
})

/**
 * Service used to load the patella bone
 */
export class PatellaService extends Bone {
  constructor( dataSymfonyService: DataSymfonyService,   manager: ManagerService,
               public  materialService: MaterialService) {
    super(manager, dataSymfonyService);
    this.name = 'patella';
    this.url =`${location.origin}/${this.dir}/${this.name}${this.addSimplifiee}.obj`;
    this.material = this.materialService.createBoneMaterial()
  }
}

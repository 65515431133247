import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  FemurViewDistalService
} from "@app/ui/screens/planif/pl-panel-right/femur-view-distal/femur-view-distal.service";
import {OverviewService} from "@app/ui/screens/planif/pl-panel-left/overview/overview.service";

//TODO
/**
 * Component that displays a synthesis of the 3D model
 */

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  @ViewChild('viewOverview', {static: true})
  public viewOverview: ElementRef<HTMLCanvasElement>;

  direction = "";
  plans = false;
  femur = true;
  tibia = true;

  constructor(private overviewService: OverviewService) { }


  ngOnInit(): void {
    this.overviewService.createScene(this.viewOverview);
    this.overviewService.loadObject();
    this.overviewService.loadImplants();
    this.overviewService.animate();
  }

  ngOnChanges(): void {
    this.overviewService.resize();
  }
}

import {Injectable} from '@angular/core';
import * as THREE from 'three';
import {
  Color, DoubleSide, FrontSide,
  MeshBasicMaterial,
  MeshPhongMaterial, MeshStandardMaterial,
  Plane, RawShaderMaterial, ShaderMaterial,
  Side,
} from 'three';
import {FEMUR_COLOR} from "@app/shared/constant/femur.constant";
import {TIBIA_COLOR} from "@app/shared/constant/tibia.constant";
import {PATELLA_COLOR} from "@app/shared/constant/patella.constant";


@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  constructor() {
  }

  createStandardMaterial() {
    return new THREE.MeshLambertMaterial({
      color: 0x00ffd5
    });
  }

  /**
   * @return {THREE.MeshPhongMaterial} material for the bones.
   */
  createBoneMaterial() {
    return new MeshPhongMaterial({
      color: 0xe6dbcb,
      side: THREE.FrontSide,
      clippingPlanes: [],
      clipIntersection: false,
      clipShadows: true,
      transparent: true,
      opacity: 0.8
    });
  }

  createBoneBackMaterial() {
      return new ShaderMaterial(
        {
          uniforms: {
            // @ts-ignore
            p: {type: 'f', value: 1},
            // @ts-ignore
            glowColor: {type: 'c', value: new THREE.Color(0xffffff)},
          },
          vertexShader: `
              uniform float p;
              varying float intensity;
              void main()
              {
                  #include <begin_vertex>
                  vec3 vNormal = normalize( normalMatrix * normal );
                  intensity = pow(1.0 - abs(dot(vNormal, vec3(0, 0, 1))), p);
                  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1 );
                  #include <project_vertex>
              }
          `,
          fragmentShader: `
             uniform vec3 glowColor;
              varying float intensity;
              void main()
              {
                  vec3 glow = glowColor * intensity;
                  gl_FragColor = vec4( glow, 0.93 );
              }
            `,
          side: THREE.BackSide,
          blending: THREE.SubtractiveBlending,
          transparent: true,
          depthWrite: false,
          clippingPlanes: []
        });
  }

  /**
   * @return {THREE.MeshStandardMaterial} material for the mortaise. Invisible material
   */
  createMortaiseMaterial() {
    return new MeshStandardMaterial({
      color: 0xffffff,
      side: THREE.FrontSide,
      visible: false,
    });
  }

  /**
   * @return {THREE.MeshStandardMaterial} material for the points, if they are displayed
   */
  createPointVisibleMaterial() {
    return new MeshStandardMaterial({
      color: 0x00ffd5,
      side: THREE.FrontSide,
    });
  }

  /**
   * @return {THREE.MeshStandardMaterial} material for the points, if they are not displayed
   */
  createPointInvisibleMaterial() {
    return new MeshStandardMaterial({
      color: 0x00ffd5,
      side: THREE.FrontSide,
      visible: false
    });
  }

  /**
   * @return {THREE.MeshStandardMaterial} material for the condyle implant.
   */
  createCondyleMaterial() {
    return new MeshStandardMaterial({
      color: 0xa9a9a9,
      side: THREE.FrontSide,
      visible: true,
    });
  }

  /**
   * @return {THREE.MeshStandardMaterial} material for the plate implant
   */
  createPlateMaterial() {
    return new MeshStandardMaterial({
      color: 0xa9a9a9,
      side: THREE.FrontSide,
      visible: true,
    });
  }

  /**
   * @return {THREE.MeshStandardMaterial} material for the patella implant
   */
  createPatellaImplantMaterial() {
    return new MeshStandardMaterial({
      color: 0xa9a9a9,
      side: THREE.FrontSide,
      visible: true,
    });
  }

  /**
   * @return {THREE.MeshStandardMaterial} material for the femoral lines reference. Set the color to fit to the femoral buttons
   */
  createFemurReferenceMaterial() {
    return new THREE.LineBasicMaterial({
      color: FEMUR_COLOR,
    });
  }

  /**
   * @return {THREE.MeshStandardMaterial} material for the femoral points reference. Set the color to fit to the femoral buttons
   */
  createFemurPointMaterial() {
    return new MeshStandardMaterial({
      color: FEMUR_COLOR,
      side: THREE.FrontSide,
    });
  }

  /**
   * @return {THREE.MeshStandardMaterial} material for the tibial lines reference. Set the color to fit to the tibial buttons
   */
  createTibiaReferenceMaterial() {
    return new THREE.LineBasicMaterial({
      color: TIBIA_COLOR,
    });
  }

  /**
   * @return {THREE.MeshStandardMaterial} material for the tibial points reference. Set the color to fit to the tibial buttons
   */
  createTibiaPointMaterial() {
    return new MeshStandardMaterial({
      color: TIBIA_COLOR,
      side: THREE.FrontSide,
    });
  }

  /**
   * @return {THREE.MeshStandardMaterial} material for the patella points reference. Set the color to fit to the patella buttons
   */
  createPatellaPointMaterial() {
    return new MeshStandardMaterial({
      color: PATELLA_COLOR,
      side: THREE.FrontSide,
    });
  }

  setMaterialColor(color: number | Color): MeshPhongMaterial {
    return new THREE.MeshPhongMaterial({
      color,
      transparent: true,
      depthTest: false
    });
  }
  // TODO Partie shader par @Houssk
  createShader9() {
    return new ShaderMaterial(
      {
        uniforms: {
          // @ts-ignore
          p: {type: 'f', value: 1},
          // @ts-ignore
          glowColor: {type: 'c', value: new THREE.Color(0xffffff)},
        },
        vertexShader: `
              uniform float p;
              varying float intensity;
              void main()
              {
                  #include <begin_vertex>
                  vec3 vNormal = normalize( normalMatrix * normal );
                  intensity = pow(1.0 - abs(dot(vNormal, vec3(0, 0, 1))), p);
                  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1 );
                  #include <project_vertex>
              }
          `,
        fragmentShader: `
             uniform vec3 glowColor;
              varying float intensity;
              void main()
              {
                  vec3 glow = glowColor * intensity;
                  gl_FragColor = vec4( glow, 0.93 );
              }
            `,
        side: THREE.DoubleSide,
        blending: THREE.NormalBlending,
        transparent: true,
        depthWrite: true,
      });
  }
}

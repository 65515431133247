import * as THREE from 'three';
import {Mesh} from "three";

const PLANES = ['yz', 'xz', 'xy'];

/** @class OrthoViewer representing a 3-View Nifti Volumic image. */
export class OrthoViewer {
  worker;
  mesh;
  yz;
  xz;
  xy;
  dims;
  header;
  running;
  plane_mesh;


  constructor(blob, cb, plane = 'xz') {
    this.worker = new Worker(new URL('../../app.worker', import.meta.url), {type: 'module'});
    /*  this.worker.onmessage = ({data}) => {
        console.log(`page got message: ${data}`);
      };
      this.worker.postMessage('hello');*/
    this.running = false

    this.worker.onmessage = (e) => {
      const type = e.data.shift();
      const data = e.data.shift();
      if (type === 'sliced') {
        const plane = data[0];
        const slice = data[1];
        const imgData = data[2];
        // exeption du cas d'un custom plane
        if (plane !== 'custom_plane') this.after_slice(plane, slice, imgData);
      } else if (type == "sliced_oriented") {
        var points = data[0];
        var object = data[1];
        this.after_slice_oriented(points, object);
        this.running = false
      } else if (type === 'initialized') {
        this.dims = data[0];
        this.header = data[1];
        this.create_mesh(plane);
        for (let i = 0; i < 3; i++) {
          this.slice(PLANES[i], Math.round((this.dims[i] - 1) / 2));
        }
        cb(this);
      }
    };

    this.worker.postMessage(['init', [blob]]);
  }

  /**
   * Send order to worker to slice {plane} at index {value}
   *
   * @param {string} plane The desired plane to slice
   * @param {integer} value The index of the desired slice
   */
  slice(plane, value) {
    this.worker.postMessage(['slice', [plane, value]]);
  }

  /**
   * Send order to worker to slice {plane}
   *
   * @param {array} points The Three points to define the plane to slice
   * @param distance
   */
  slice_plane(points, distance) {
    if (this.running) return
    var local_points = points.map(v => new THREE.Vector3(v[0], v[1], v[2]).applyMatrix4(this.mesh.matrixWorld.clone().invert()).toArray());
    this.worker.postMessage(["slice_plane", [local_points, distance]]);
  }

  create_mesh(plane = 'xz') {
    const mesh = new THREE.Mesh();
    mesh.name = plane;
    this.yz = this.createSliceMesh(PLANES[0]);
    this.xz = this.createSliceMesh(PLANES[1]);
    this.xy = this.createSliceMesh(PLANES[2]);

    /*    this.mesh.add(this.yz);*/
    if (plane === 'xz') {
      mesh.add(this.xz);
    }
    if (plane === 'xy') {
      mesh.add(this.xy);
    }
    if (plane === 'yz') {
      mesh.add(this.yz);
    }
    if (plane === 'custom_plane') {
      mesh.add(new Mesh());
    }
    // Warning : Nifti image often respect this standard (sform>0 have priority on qform==0)
    if (this.header.sform_code > 0) {
      mesh.matrixAutoUpdate = false;
      mesh.matrix.set(
        this.header.affine[0][0], this.header.affine[0][1], this.header.affine[0][2], this.header.affine[0][3],
        this.header.affine[1][0], this.header.affine[1][1], this.header.affine[1][2], this.header.affine[1][3],
        this.header.affine[2][0], this.header.affine[2][1], this.header.affine[2][2], this.header.affine[2][3],
        this.header.affine[3][0], this.header.affine[3][1], this.header.affine[3][2], this.header.affine[3][3]
      );
    } else {
      mesh.scale.set(this.header.pixDims[1], this.header.pixDims[2], this.header.pixDims[3]);
    }
    var width = Math.max(this.dims[0], this.dims[1], this.dims[2])

    var height = width
    var geometry = new THREE.PlaneBufferGeometry(1, 1);
    var texture = new THREE.DataTexture(new Uint8Array(width * height), width, height, THREE.LuminanceFormat);
    texture.needsUpdate = true;
    var alpha = new THREE.DataTexture(new Uint8Array(width * height), width, height, THREE.LuminanceFormat);
    alpha.needsUpdate = true;
    var material = new THREE.MeshBasicMaterial({
      map: texture,
      alphaMap: alpha,
      transparent: true,
      side: THREE.DoubleSide,
      polygonOffsetFactor: 5,
      polygonOffset: true,
    });
    material.needsUpdate = true;
    this.plane_mesh = new THREE.Mesh(geometry, material);
    this.plane_mesh.visible = false
    mesh.add(this.plane_mesh);
    this.mesh = mesh;
  }

  after_slice(plane, value, imgData) {
    const mesh = this[plane];
    const texture = mesh.material.map;
    texture.image.data = imgData;
    texture.needsUpdate = true;
    const index = PLANES.indexOf(plane);
    // tslint:disable-next-line:radix
    mesh.position.setComponent(index, parseInt(value) + 0.5);
  }

  /**
   * Called after custom slicing to update texture and position
   *
   * @param {array} points The 3 points used to define the plane (unused)
   * @param {object} object The object return by slice_oriented_plane in niftiSlicer
   */

  after_slice_oriented(points, object) {
    const v = object.vertices
    var vertices = this.plane_mesh.geometry.attributes.position;
    //update vertices positions
    for (var i = 0; i < 4; i++) {
      vertices.setXYZ(i, v[3 * i + 0], v[3 * i + 1], v[3 * i + 2]);
    }

    vertices.needsUpdate = true;

    //update plane appearance
    let texture = this.plane_mesh.material
    texture.map.image.width = object.width
    texture.map.image.height = object.width
    texture.map.image.data = object.texture;
    texture.alphaMap.image.width = object.width
    texture.alphaMap.image.height = object.width
    texture.alphaMap.image.data = object.alpha;

    //update mesh
    texture.map.needsUpdate = true;
    texture.alphaMap.needsUpdate = true;
    this.plane_mesh.geometry.computeVertexNormals();
    this.plane_mesh.geometry.computeBoundingSphere();
    this.plane_mesh.visible = true
    this.plane_mesh.name = 'plane_custom';
  }

  createSliceMesh(plane) {
    const geometry = new THREE.PlaneBufferGeometry(1, 1);

    let coord;
    let width;
    let height;
    //   with respect to chosen plane and Nifti header
    // @ts-ignore
    const vertices = geometry.attributes.position;
    if (plane === 'xy') {
      coord = [0, this.dims[1], 0,
        this.dims[0], this.dims[1], 0,
        0, 0, 0,
        this.dims[0], 0, 0];
      width = this.dims[0];
      height = this.dims[1];
    } else if (plane === 'xz') {
      coord = [0, 0, this.dims[2],
        this.dims[0], 0, this.dims[2],
        0, 0, 0,
        this.dims[0], 0, 0,];
      width = this.dims[0];
      height = this.dims[2];
    } else if (plane === 'yz') {
      coord = [0, 0, this.dims[2],
        0, this.dims[1], this.dims[2],
        0, 0, 0,
        0, this.dims[1], 0,];
      width = this.dims[1];
      height = this.dims[2];
    }
    for (let i = 0; i < 4; i++) {
      vertices.setXYZ(i, coord[3 * i], coord[3 * i + 1], coord[3 * i + 2]);
    }
    const texture = new THREE.DataTexture(new Uint8Array(width * height), width, height, THREE.LuminanceFormat);
    texture.needsUpdate = true;
    geometry.computeVertexNormals();
    geometry.computeBoundingSphere();
    const material = new THREE.MeshBasicMaterial(
      {
        map: texture,
        polygonOffsetFactor: 50,
        polygonOffset: true,
      });
    material.name = 'nifti';
    material.onBeforeCompile = function( shader ) {

      shader.fragmentShader = shader.fragmentShader.replace(

        `#include <alphamap_fragment>`,

        `#ifdef USE_ALPHAMAP
			diffuseColor.a *= texture2D( alphaMap, vUv ).r;   // read red channel, instead
		#endif`

      );

    };
    material.side = THREE.DoubleSide;
    material.needsUpdate = true;
    const mesh = new THREE.Mesh(geometry, material);
    mesh.name = 'nifti';
    return mesh;
  }
}

import { Component, OnInit } from '@angular/core';
import { SettingsService } from './settings.service';
import {SelectFemurService} from "@app/shared/component/select-femur/select-femur.service";
import {SelectTibiaService} from "@app/shared/component/select-tibia/select-tibia.service";
import {SelectPatellaService} from "@app/shared/component/select-patella/select-patella.service";
import {SelectFemurComponent} from "@app/shared/component/select-femur/select-femur.component";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {PlanifSlicerService} from "@app/ui/screens/planif/planif-slicer.service";
import {
  FemurViewSagittalService
} from "@app/ui/screens/planif/pl-panel-right/femur-view-sagittal/femur-view-sagittal.service";
import {
  FemurViewDistalService
} from "@app/ui/screens/planif/pl-panel-right/femur-view-distal/femur-view-distal.service";
import {
  TibiaViewProximalService
} from "@app/ui/screens/planif/pl-panel-right/tibia-view-proximal/tibia-view-proximal.service";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";

/**
 * Component used to set parameters
 */

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  light;
  maintain;
  page;
  femurRef;
  femurRot;
  femurAxe;
  femurRange;
  femurSize;
  patellaRef;
  patellaAxe;
  patellaSize;
  tibiaRef;
  tibiaAxe;
  tibiaRange;
  tibiaSize;

  constructor( public settingsService: SettingsService, public selectFemurService: SelectFemurService,
               public selectTibiaService: SelectTibiaService, public selectPatellaService: SelectPatellaService,
               private planifViewManagerService: PlanifViewManagerService, private femurMortaiseService: FemurMortaiseService,
               private tibiaMortaiseService: TibiaMortaiseService, private patellaMortaiseService: PatellaMortaiseService,
               private planifSlicerService: PlanifSlicerService, private femurViewSagittalService: FemurViewSagittalService,
               private femurViewDistalService: FemurViewDistalService, private tibiaViewProximalService: TibiaViewProximalService,
               private planifViewReferencesService: PlanifViewReferencesService) {
    this.light = settingsService.light;
    this.maintain = settingsService.maintain;
    this.page = settingsService.page;
    this.femurRef = this.selectFemurService.getRef()
    this.femurRot = this.selectFemurService.getRot()
    this.femurAxe = this.selectFemurService.getAxe()
    this.femurRange = this.selectFemurService.getRange()
    this.femurSize = this.selectFemurService.getSize()
    this.patellaRef = this.selectPatellaService.getRef()
    this.patellaAxe = this.selectFemurService.getAxe()
    this.patellaSize = this.selectPatellaService.getSize()
    this.tibiaRef = this.selectTibiaService.getRef()
    this.tibiaAxe = this.selectTibiaService.getAxe()
    this.tibiaRange = this.selectTibiaService.getRange()
    this.tibiaSize = this.selectTibiaService.getSize()
  }

  ngOnInit(): void {
  }

  save() {
    this.settingsService.light = this.light;
    this.settingsService.maintain = this.maintain;
    this.settingsService.page = this.page;
    this.settingsService.femurRef = this.femurRef;
    this.settingsService.femurRot = this.femurRot
    this.settingsService.femurAxe = this.femurAxe;
    this.settingsService.femurRange = this.femurRange;
    this.settingsService.femurSize = this.femurSize;
    this.settingsService.tibiaRef = this.tibiaRef;
    this.settingsService.tibiaAxe = this.tibiaAxe;
    this.settingsService.tibiaRange = this.tibiaRange;
    this.settingsService.tibiaSize = this.tibiaSize;
  }

  femurRefChange() {
    this.planifViewReferencesService.setCurrentFemurRefPoint(this.femurRef.value, this.femurViewSagittalService.scene)
    this.femurMortaiseService.refAnteriorPosterior = this.femurRef.value
    this.femurMortaiseService.posteriorResection = 8;
    this.femurMortaiseService.notchingAnterior = 0;
    this.planifSlicerService.updateFemurCut()
  }

  femurMeasureChange() {
    this.planifViewReferencesService.setCurrentFemurMeasureAxis(this.femurAxe.value, this.femurViewDistalService.scene)
  }

  femurRotChange() {
    this.planifViewReferencesService.setCurrentFemurRotationAxis(this.femurRot.value, this.femurViewDistalService.scene)
    this.planifSlicerService.updateFemurCut()
  }

  patellaRefChange() {
    this.patellaMortaiseService.refAnteriorPosterior = this.patellaRef.value
    this.planifViewReferencesService.setCurrentPatellaRefPoint(this.patellaRef.value, this.femurViewSagittalService.scene)
    this.patellaMortaiseService.posteriorResection = 8;
    this.patellaMortaiseService.anteriorWidth = 10;
    this.planifSlicerService.updatePatellaCut()
  }

  patellaMeasureChange() {
    this.planifViewReferencesService.setCurrentFemurMeasureAxis(this.patellaAxe.value, this.femurViewDistalService.scene)
  }

  tibiaRefChange() {
    this.tibiaMortaiseService.refAkagiPosterior= this.tibiaRef.value
    this.tibiaMortaiseService.internalExternalRotation= 0;
    this.planifSlicerService.updateTibiaCut()
  }

  tibiaMeasureChange() {
    this.planifViewReferencesService.setCurrentTibiaMeasureAxis(this.tibiaAxe.value, this.tibiaViewProximalService.scene)
  }
}

<div class="dialog-title">
  <div class="title">
    <i class="icon icon-settings"></i> <span>Préférences</span>
  </div>
  <div class="close" [mat-dialog-close]>
    <i class="icon icon-close"></i>
  </div>
</div>
<div class="spacer-20">
<h2>Divers</h2>
<div class="spacer-20">
<div class="sub-part embed">
  <label for="settings_light">
    Thème clair
    <input type="checkbox"  [(ngModel)]=" light " id="settings_light">
    <div class="toggle-input" >
    </div>
  </label>
  <div class="spacer-20"></div>
  <label>
    Étape de lancement de l’application
    <mat-select [(value)]=" page.value " class="dialog-select pages-select" [panelClass]=" 'dialog-select' ">
      <mat-option *ngFor=" let p of settingsService.pageList " [value]=" p.value ">
          {{ p.display }}
      </mat-option>
    </mat-select>
  </label>
  <button type="button" class="dialog-register" [mat-dialog-close] (click)=" save() ">
    <i class="icon icon-save"></i>
    <span>Sauvegarder</span>
  </button>
</div>
<div class="spacer-20">
<h2>Implants</h2>
<div class="sub-part">
  <label for="settings_maintain">
    Maintenir les paramètres de planification lors du changement de sous-gamme
    <input type="checkbox"  [(ngModel)]=" maintain " id="settings_maintain">
    <div class="toggle-input" >
    </div>
  </label>
  <div class="settings-row">
    <div class="settings-col">
      <div>
        <div class="sc-title">
          <i class="icon icon-femur"></i>
          <span>Fémur</span>
        </div>
      </div>
      <div class="underline bold">
        Les valeurs par défaut
      </div>
      <div class="spacer-20"></div>
      <div class="sc-inner-row">
        <div>
          Référence :
        </div>
        <div>
          <mat-select [(value)]=" femurRef.value " class="dialog-select" [panelClass]=" 'dialog-select' " (selectionChange)="femurRefChange()">
            <mat-option *ngFor=" let r of selectFemurService.getRefList() " [value]=" r.value ">
              {{ r.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="sc-inner-row">
        <div>
          Axe de rot :
        </div>
        <div>
          <mat-select [(value)]=" femurRot.value " class="dialog-select" [panelClass]=" 'dialog-select' " (selectionChange)="femurRotChange()">
            <mat-option *ngFor=" let r of selectFemurService.getRotList() " [value]=" r.value ">
              {{ r.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="sc-inner-row">
        <div>
          Référence de mesure :
        </div>
        <div>
          <mat-select [(value)]=" femurAxe.value " class="dialog-select" [panelClass]=" 'dialog-select' " (selectionChange)="femurMeasureChange()">
            <mat-option *ngFor=" let a of selectFemurService.getAxeList() " [value]=" a.value ">
              {{ a.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="sc-inner-row">
        <div>
          Gamme d’implant fémoral :
        </div>
        <div>
          <mat-select [(value)]=" femurRange.value " class="dialog-select" [panelClass]=" 'dialog-select' ">
            <mat-option *ngFor=" let r of selectFemurService.getRangeList() " [value]=" r.value ">
              {{ r.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="sc-inner-row">
        <div>
          Taille :
        </div>
        <div>
          <mat-select [(value)]=" femurSize.value " class="dialog-select" [panelClass]=" 'dialog-select' ">
            <mat-option *ngFor=" let s of selectFemurService.getSizeList() " [value]=" s.value ">
              {{ s.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="settings-col">
      <div>
        <div class="sc-title">
          <i class="icon icon-patella"></i>
          <span>Rotule</span>
        </div>
      </div>
      <div class="underline bold">
        Les valeurs par défaut
      </div>
      <div class="spacer-20"></div>
      <div class="sc-inner-row">
        <div>
          Référence :
        </div>
        <div>
          <mat-select [(value)]=" patellaRef.value " class="dialog-select" [panelClass]=" 'dialog-select' " (selectionChange)="patellaRefChange()">
            <mat-option *ngFor=" let r of selectPatellaService.getRefList() " [value]=" r.value ">
              {{ r.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="sc-inner-row">
        <div>
          Référence de mesure :
        </div>
        <div>
          <mat-select [(value)]=" patellaAxe.value " class="dialog-select" [panelClass]=" 'dialog-select' " (selectionChange)="patellaMeasureChange()">
            <mat-option *ngFor=" let a of selectFemurService.getAxeList() " [value]=" a.value ">
              {{ a.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="sc-inner-row">
        <div>
          Taille :
        </div>
        <div>
          <mat-select [(value)]=" patellaSize.value " class="dialog-select" [panelClass]=" 'dialog-select' ">
            <mat-option *ngFor=" let s of selectPatellaService.getSizeList() " [value]=" s.value ">
              {{ s.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="settings-col">
      <div>
        <div class="sc-title">
          <i class="icon icon-tibia"></i>
          <span>Tibia</span>
        </div>
      </div>
      <div class="underline bold">
        Les valeurs par défaut
      </div>
      <div class="spacer-20"></div>
      <div class="sc-inner-row">
        <div>
          Référence :
        </div>
        <div>
          <mat-select [(value)]=" tibiaRef.value " class="dialog-select" [panelClass]=" 'dialog-select' " (selectionChange)="tibiaRefChange()">
            <mat-option *ngFor=" let r of selectTibiaService.getRefList() " [value]=" r.value ">
              {{ r.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="sc-inner-row">
        <div>
          Référence de mesure :
        </div>
        <div>
          <mat-select [(value)]=" tibiaAxe.value " class="dialog-select" [panelClass]=" 'dialog-select' " (selectionChange)="tibiaMeasureChange()">
            <mat-option *ngFor=" let a of selectTibiaService.getAxeList() " [value]=" a.value ">
              {{ a.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="sc-inner-row">
        <div>
          Gamme d’implant tibial :
        </div>
        <div>
          <mat-select [(value)]=" tibiaRange.value " class="dialog-select" [panelClass]=" 'dialog-select' ">
            <mat-option *ngFor=" let r of selectTibiaService.getRangeList() " [value]=" r.value ">
              {{ r.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="sc-inner-row">
        <div>
          Taille :
        </div>
        <div>
          <mat-select [(value)]=" tibiaSize.value " class="dialog-select" [panelClass]=" 'dialog-select' ">
            <mat-option *ngFor=" let s of selectTibiaService.getSizeList() " [value]=" s.value ">
              {{ s.display }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>
</div>
<!--light: {{ light }}-->

import {Injectable} from '@angular/core';
import {Observable, zip} from "rxjs";
import {FemurService} from "@app/services/bone/femur.service";
import {PointService} from "@app/services/bone/point.service";
import {PatellaService} from "@app/services/bone/patella.service";
import {Utils} from "@app/services/class/Utils";
import {ELEMENTS_FEMUR} from "@app/shared/constant/femur.constant";
import {ELEMENTS_PATELLA} from "@app/shared/constant/patella.constant";
import {ELEMENTS_TIBIA} from "@app/shared/constant/tibia.constant";
import {CondyleService} from "@app/services/implant/condyle.service";
import {TibiaService} from "@app/services/bone/tibia.service";
import {PlateService} from "@app/services/implant/plate.service";
import {PatellaImplantService} from "@app/services/implant/patella-implant.service";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";
import {CSG} from "three-csg-ts";
import {FEMUR_REF} from "@app/shared/constant/ref_name.constant";

@Injectable({
  providedIn: 'root'
})

/**
 * Service used to load the objects on the scenes (bones, points, implants, mortaises and cuts)
 */
export class PlanifLoaderService {

  constructor(private femurService: FemurService, private pointService: PointService,
              private patellaService: PatellaService, private condyleService: CondyleService,
              private tibiaService: TibiaService, private plateService: PlateService,
              private patellaImplantService: PatellaImplantService, private femurMortaiseService: FemurMortaiseService,
              private patellaMortaiseService: PatellaMortaiseService, private tibiaMortaiseService: TibiaMortaiseService) {
  }

  /**
   * This method loads all the objects of the femur (femur bone, femur mortaise, femur anatomical points)
   * @return {Observable} The mesh of the objects loaded
   */
  getSourceViewFemur(): Observable<any> {
    return zip(
      this.femurService.load(),
      this.femurMortaiseService.loadMortaise('_t1'),
      this.patellaMortaiseService.loadMortaise('_10'),
      ...this.getElement(ELEMENTS_FEMUR)
    );
  }

  /**
   * This method loads all the objects of the tibia(tibia bone, tibia mortaise, tibia anatomical points)
   * @return {Observable} The mesh of the objects loaded
   */
  getSourceViewTibia(): Observable<any> {
    return zip(
      this.tibiaService.load(),
      this.tibiaMortaiseService.loadMortaise(''),
      ...this.getElement(ELEMENTS_TIBIA)
    )
  }

  /**
   * This method loads the initial tibia cut
   * @param {THREE.Scene} scene The current scene
   */
  loadTibiaCut(scene) {
    const name = 'tibia';
    const nameCut = 'tibia_cut'
    let meshMortaise = this.tibiaMortaiseService.mesh
    meshMortaise.updateMatrix()
    const meshTibia= Utils.getMeshByName(scene, name)
    const tibiaCut = CSG.subtract(meshTibia, meshMortaise)
    tibiaCut.name = nameCut
    meshTibia.visible = false
    scene.add(tibiaCut)
    }

  /**
   * This method loads the initial patella cut
   * @param {THREE.Scene} scene The current scene
   */
  loadPatellaCut(scene) {
    const name = 'patella';
    const nameCut = 'patella_cut'
    let meshMortaise = this.patellaMortaiseService.mesh
    meshMortaise.updateMatrix()
    const meshPatella= Utils.getMeshByName(scene, name)
    const patellaCut = CSG.subtract(meshPatella, meshMortaise)
    patellaCut.name = nameCut
    meshPatella.visible = false
    scene.add(patellaCut)
  }

  /**
   * This method loads the initial femur cut
   */
  loadFemurCut(scene) {
    const name = 'femur';
    const nameCut = 'femur_cut'
    let meshMortaise = this.femurMortaiseService.mesh
    meshMortaise.updateMatrix()
    const meshFemur= Utils.getMeshByName(scene, name)
    const femurCut = CSG.subtract(meshFemur, meshMortaise)
    femurCut.name = nameCut
    meshFemur.visible = false
    scene.add(femurCut)
  }

  /**
   * This method loads all the objects of the patella (patella bone, fpatella mortaise, patella anatomical points)
   * @return {Observable} The mesh of the objects loaded
   */
  getSourceViewPatella(): Observable<any> {
    return zip(
      this.patellaService.load(),
      ...this.getElement(ELEMENTS_PATELLA)
    )
  }

  /**
   * This method loads all the objects of the condyle (condyle implant, condyle points)
   * @return {Observable} The mesh of the objects loaded
   */
  getSourceViewCondyle(): Observable<any> {
    return zip(
      this.condyleService.load(),
      this.condyleService.loadCondylePoints('post', true),
      this.condyleService.loadCondylePoints('dist', false),
    )
  }

  /**
   * This method loads all the objects of the plate (plate implant)
   * @return {Observable} The mesh of the objects loaded
   */
  getSourceViewPlate(): Observable<any> {
    return zip(
      this.plateService.load()
    )
  }

  /**
   * This method loads all the objects of the patella implant (patella implant)
   * @return {Observable} The mesh of the objects loaded
   */
  getSourceViewPatellaImplant(): Observable<any> {
    return zip(
      this.patellaImplantService.load()
    )
  }

  /**
   * This method loads all the points described by the input array
   * @param {array} array An array with the names of the points to load
   * @return {Observable} The mesh of the objects loaded
   */
  getElement(array): Array<Observable<any>> {
    const table = [];
    array.map(el => {
      table.push(this.pointService.load(el, false));
    })
    return table;
  }
}

import { Injectable } from '@angular/core';
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {PlanifSlicerService} from "@app/ui/screens/planif/planif-slicer.service";
import {MathUtils} from "three";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {SelectPatellaService} from "@app/shared/component/select-patella/select-patella.service";
import {SelectFemurService} from "@app/shared/component/select-femur/select-femur.service";
import {FEMUR_REF, PATELLA_REF} from "@app/shared/constant/ref_name.constant";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";

@Injectable({
  providedIn: 'root'
})
export class PatellaAdjustmentService {

  constructor(public patellaMortaiseService: PatellaMortaiseService, private planifSlicerService: PlanifSlicerService,
              private planifViewManagerService: PlanifViewManagerService, private selectPatellaService: SelectPatellaService,
              private selectFemurService: SelectFemurService, private planifViewReferencesService: PlanifViewReferencesService) { }

  /**
   * Method that return the value of externalRotation, depending on the axe rot ref chosen, a delta is added to the rotation
   * displayed. The angle is calculated from the X axis (which is the condyle posterior axis)
   */
  getRotationInternalExternal() {
    if (this.selectFemurService.getAxe().value===FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.value) {
      return (this.patellaMortaiseService.externalRotation);
    }
    else if (this.selectFemurService.getAxe().value===FEMUR_REF.MEASURE.EPICONDYLE_ANATOMICAL.value) {
      const condAxis = this.planifViewReferencesService.getCondylePosteriorAxis()
      const epicondAnatAxis = this.planifViewReferencesService.getEpicondyleAnatomicalAxis()
      return (this.patellaMortaiseService.externalRotation+Math.round(MathUtils.radToDeg(condAxis.angleTo(epicondAnatAxis))));
    }
    else if (this.selectFemurService.getAxe().value===FEMUR_REF.MEASURE.EPICONDYLE_SURGICAL.value) {
      const condAxis = this.planifViewReferencesService.getCondylePosteriorAxis()
      const epicondSurgAxis = this.planifViewReferencesService.getEpicondyleSurgicalAxis()
      return (this.patellaMortaiseService.externalRotation+Math.round(MathUtils.radToDeg(condAxis.angleTo(epicondSurgAxis))));
    }
    else {
      const condAxis = this.planifViewReferencesService.getCondylePosteriorAxis()
      const whitesideAxis = this.planifViewReferencesService.getAPWhitesideAxis()
      return (this.patellaMortaiseService.externalRotation+Math.round(MathUtils.radToDeg(condAxis.angleTo(whitesideAxis))));
    }
  }

  /**
   * Method that return the absolute value of externalRotation
   */
  getAbsoluteRotationInternalExternal() {
      return Math.abs(this.getRotationInternalExternal())
  }

  /**
   * method that decrease the value of externalRotation by 1
   */
  minusRotationInternalExternal() {
    this.patellaMortaiseService.externalRotation -= (this.patellaMortaiseService.sideModifier);
    this.planifSlicerService.updatePatellaCut();
  }

  /**
   * method that increase the value of externalRotation by 1
   */
  plusRotationInternalExternal() {
    this.patellaMortaiseService.externalRotation += (this.patellaMortaiseService.sideModifier);
    this.planifSlicerService.updatePatellaCut();
  }

  /**
   * method that return the value of posteriorResection
   */
  getResectionAnteriorPosterior() {
    if (this.patellaMortaiseService.refAnteriorPosterior==PATELLA_REF.REF.ANTERIOR.value) {
      return this.patellaMortaiseService.anteriorWidth;
    }
    else {
      return this.patellaMortaiseService.posteriorResection;
    }
  }

  /**
   * method that decrease the value of posteriorResection by 1
   */
  minusResectionAnteriorPosterior() {
    if (this.patellaMortaiseService.refAnteriorPosterior==PATELLA_REF.REF.ANTERIOR.value) {
      this.patellaMortaiseService.anteriorWidth -= 1;
    }
    else {
      this.patellaMortaiseService.posteriorResection -= 1;
    }
    this.planifSlicerService.updatePatellaCut();
  }

  /**
   * method that increase the value of posteriorResection by 1
   */
  plusResectionAnteriorPosterior() {
    if (this.patellaMortaiseService.refAnteriorPosterior==PATELLA_REF.REF.ANTERIOR.value) {
      this.patellaMortaiseService.anteriorWidth +=1;
    }
    else {
      this.patellaMortaiseService.posteriorResection += 1;
    }
    this.planifSlicerService.updatePatellaCut();
  }

  /**
   * method that return the value of flexionExtension
   */
  getFlexionExtension() {
    return this.patellaMortaiseService.flexionExtension;
  }

  /**
   * method that return the absolute value of flexionExtension
   */
  getAbsoluteFlexionExtension() {
    return Math.abs(this.getFlexionExtension());
  }

  /**
   * method that decrease the value of flexionExtension by 1
   */
  minusFlexionExtension() {
    this.patellaMortaiseService.flexionExtension -= 1;
    this.planifSlicerService.updatePatellaCut();
  }

  /**
   * method that increase the value of flexionExtension by 1
   */
  plusFlexionExtension() {
    this.patellaMortaiseService.flexionExtension += 1;
    this.planifSlicerService.updatePatellaCut();
  }
}

import { Component, OnInit } from '@angular/core';

/**
 * Component used to reset the planification
 */

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import {FEMUR_REF} from "@app/shared/constant/ref_name.constant";

/**
 * Service that contains methods for the reference selection and implant choice for the femur
 */
@Injectable({
  providedIn: 'root'
})
export class SelectFemurService {

  constructor() { }

  private refList = [
    {
      value: FEMUR_REF.REF.ANTERIOR.value,
      display: FEMUR_REF.REF.ANTERIOR.display
    },
    {
      value: FEMUR_REF.REF.POSTERIOR.value,
      display: FEMUR_REF.REF.POSTERIOR.display
    }
  ];

  private ref = {
    value: FEMUR_REF.REF.POSTERIOR.value,
    display: FEMUR_REF.REF.POSTERIOR.display
  };

  /**
   * method that returns the value of ref. ref design the referential (anterior/posterior)
   */
  getRef() {
    return this.ref;
  }

  /**
   * method that returns the list of referential : anterior and posterior
   */
  getRefList() {
    return this.refList;
  }

  private rotList = [
    {
      value: FEMUR_REF.ROT.MECA.value,
      display: FEMUR_REF.ROT.MECA.display
    },
    {
      value: FEMUR_REF.ROT.POST.value,
      display: FEMUR_REF.ROT.POST.display
    }
  ]

  private rot = {
    value: FEMUR_REF.ROT.MECA.value,
    display: FEMUR_REF.ROT.MECA.display
  }

  /**
   * method that returns the value of rot. rot design the rotation axis for external/internal rotation
   */
  getRot() {
    return this.rot
  }

  /**
   * method that returns the list of rotation axis : femoral meca axis or condyle posterior internal axis
   */
  getRotList() {
    return this.rotList
  }

  private axeList = [
    {
      value: FEMUR_REF.MEASURE.EPICONDYLE_ANATOMICAL.value,
      display: FEMUR_REF.MEASURE.EPICONDYLE_ANATOMICAL.display
    },
    {
      value: FEMUR_REF.MEASURE.EPICONDYLE_SURGICAL.value,
      display: FEMUR_REF.MEASURE.EPICONDYLE_SURGICAL.display
    },
    {
      value: FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.value,
      display: FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.display
    },
    {
      value: FEMUR_REF.MEASURE.WHITESIDE.value,
      display: FEMUR_REF.MEASURE.WHITESIDE.display
    }
  ];

  private axe = {
    value: FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.value,
    display: FEMUR_REF.MEASURE.CONDYLE_POSTERIOR.display
  };

  /**
   * method that returns the value of axe. axe design the axe on which the femur is lined
   */
  getAxe() {
    return this.axe;
  }

  /**
   * method that returns the list of the axes : whiteside line, posterior condyle line, epicondyle line
   */
  getAxeList() {
    return this.axeList;
  }

  private rangeList = [
    {
      value: "madison_ps_evo",
      display: "MADISON PS EVO"
    }
  ];

  private range = {
    value: "madison_ps_evo",
    display: "MADISON PS EVO"
  };

  /**
   * method that returns the value of range. range represents the type of femoral implant chosen.
   */
  getRange() {
    return this.range;
  }

  /**
   * method that returns the list of the femoral implants.
   */
  getRangeList() {
    return this.rangeList;
  }

  private sizeList = [
    {
      value: "t1",
      display: "T1"
    },
    {
      value: "t2",
      display: "T2"
    },
    {
      value: "t3",
      display: "T3"
    },
    {
      value: "t4",
      display: "T4"
    },
    {
      value: "t5",
      display: "T5"
    },
    {
      value: "t6",
      display: "T6"
    },
    {
      value: "t7",
      display: "T7"
    },
    {
      value: "t8",
      display: "T8"
    }
  ];

  private size = {
    value: "t1",
    display: "T1"
  };

  /**
   * method that returns the value of size. size reprents the size of the femoral implant
   */
  getSize() {
    return this.size;
  }

  /**
   * method that returns the list of sizes available for the femoral implant.
   */
  getSizeList() {
    return this.sizeList;
  }
}

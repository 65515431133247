import { Component, OnInit } from '@angular/core';

/**
 * Component used to describe the size of the displayed bones
 */

@Component({
  selector: 'app-po-measure',
  templateUrl: './po-measure.component.html',
  styleUrls: ['./po-measure.component.scss']
})
export class PoMeasureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  measure = 744;

}

import {ElementRef, Injectable, NgZone} from '@angular/core';
import * as THREE from 'three';
import {MathUtils, Vector3} from "three";
import CameraControls from 'camera-controls';
import {ViewerNii} from "@app/services/nii/viewerNii";
import {Utils} from "@app/services/class/Utils";
import {SceneService} from "@app/services/three/scene.service";
import {ControlsService} from "@app/services/three/controls.service";
import {CameraService} from "@app/services/three/camera.service";
import {RendererService} from "@app/services/three/renderer.service";
import {LightService} from "@app/services/three/light.service";
import {FemurService} from "@app/services/bone/femur.service";
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {PlanifLoaderService} from "@app/ui/screens/planif/planif-loader.service";
import {Init} from "@app/services/class/Init";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";
import {PlateService} from "@app/services/implant/plate.service";
import {environment} from "@environments/environment";

declare global {
  interface Window {
    sceneTibiaTransversal: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TibiaViewTransversalService {

  canvas: HTMLCanvasElement;
  camera;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  frameId: number = null;
  controls;
  clock: THREE.Clock;
  delta;
  viewerNii;
  axis;

  constructor(private ngZone: NgZone, private sceneService: SceneService, private controlsService: ControlsService,
              private cameraService: CameraService, private rendererService: RendererService,
              private lightService: LightService, private femurService: FemurService,
              private dataSymfonyService: DataSymfonyService, private planifLoaderService: PlanifLoaderService,
              private planifViewManagerService: PlanifViewManagerService, private planifViewReferencesService: PlanifViewReferencesService,
              private tibiaMortaiseService : TibiaMortaiseService, private plateService: PlateService) {
  }

  createScene(canvas: ElementRef<HTMLCanvasElement>) {
    Init.createTibiaTransversalScene(this, canvas);
    this.controls.mouseButtons.wheel = CameraControls.ACTION.NONE;
  }

  loadObject() {
    this.planifLoaderService.getSourceViewTibia().subscribe({
      next: value => {
        this.scene.add(...value);
        this.toTransversalView();
        this.planifViewReferencesService.displayTibiaMecaAxis(this.scene)
        this.tibiaMortaiseService.setMortaiseTibiaInitialPosition(this.scene)
        this.loadTransversalRef()
      }
    })
  }

  loadImplants() {
    this.planifLoaderService.getSourceViewPlate().subscribe({
      next: value => {
        this.scene.add(...value);
        this.plateService.setPlateInitialPosition(this.scene)
        this.planifLoaderService.loadTibiaCut(this.scene)
      }
    })
  }

  loadImages(scope) {
    this.viewerNii = new ViewerNii();
    this.viewerNii.init(this.scene, 'yz').then(el => {
      const {x, y, z} = this.dataSymfonyService.getNiiTranslation();
      const rotation = this.dataSymfonyService.getNiiRotation();
      const pixDimX = this.viewerNii.viewer.header.pixDims[1]
      const pointVect = Utils.getPointPositionByName(this.scene, scope.targetPoint)

      // @ts-ignore
      scope.count = (x+el.x+pointVect.x)/pixDimX

      Utils.getMeshByName(this.scene, 'viewer-nifti').position.set(x, y, z)
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(0,0,1), MathUtils.degToRad(rotation.z))
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(0,1,0), MathUtils.degToRad(rotation.y))
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(1,0,0), MathUtils.degToRad(rotation.x))

      this.viewerNii.viewer.slice('yz', scope.count)
    })
  }

  scroll(deltaX: number) {
    this.viewerNii.viewer.slice('yz', deltaX);
  }

  /**
   * Loads all the reference points in the transervsal view
   */
  loadTransversalRef() {
    this.planifViewReferencesService.displayTibiaPoint(this.scene)
  }


  animate() {
    Init.animate(this);
    window.THREE = THREE;
    if (environment.production === false) {
      window.sceneTibiaTransversal = this.scene;
    }
  }

  public render(): void {
    Init.render(this);
  }

  public resize(): void {
    Init.resize(this);
    this.planifViewManagerService.fitToTibia(this.scene, this.controls);
  }

  toTransversalView() {
    this.controls.rotateAzimuthTo(-90 * THREE.MathUtils.DEG2RAD, false);
    const v1 = this.planifViewReferencesService.getTibiaMecaAxis(this.scene)
    this.camera.up.set(v1.x, v1.y, v1.z)
    this.controls.updateCameraUp()
    this.planifViewManagerService.fitToTibia(this.scene, this.controls);
  }

  toTransversalViewReverse() {
    this.controls.rotateAzimuthTo(90 * THREE.MathUtils.DEG2RAD, false);
    const v1 = this.planifViewReferencesService.getTibiaMecaAxis(this.scene)
    this.camera.up.set(v1.x, v1.y, v1.z)
    this.controls.updateCameraUp()
    this.planifViewManagerService.fitToTibia(this.scene, this.controls);
  }

}

<div id="femur_switch_views">
  <div class="toggle-all" (click)=" viewsChoiceService.clickFemurs() ">
    <i class="icon icon-femur"></i>
    <span>FÉMUR</span>
    <i class="icon icon-quit"></i>
  </div>
  <div class="toggle-ones">
    <div class="toggle-one" [class.active]=" viewsChoiceService.getFemurDist() && viewsChoiceService.getFemurTibias() != 'all' " (click)=" viewsChoiceService.clickFemurDist() ">
      <img src="../../../../assets/madison/img/mini_femur_1.png">
    </div>
    <div class="toggle-one" [class.active]=" viewsChoiceService.getFemurFront() && viewsChoiceService.getFemurTibias() != 'all' " (click)=" viewsChoiceService.clickFemurFront() ">
      <img src="../../../../assets/madison/img/mini_femur_2.png">
    </div>
    <div class="toggle-one" [class.active]=" viewsChoiceService.getFemurSag() && viewsChoiceService.getFemurTibias() != 'all' " [class.inverted]=" viewsChoiceService.getSagIsInverted() " (click)=" viewsChoiceService.clickFemurSag() ">
      <img src="../../../../assets/madison/img/mini_femur_3.png">
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {ManagerService} from "@app/services/three/manager.service";
import {MaterialService} from "@app/services/three/material.service";
import * as MEASURE_JSON from '@app/shared/constant/mortaise_measure';
import {Mortaise} from "@app/services/class/mortaise";
import * as THREE from "three";
import {Utils} from "@app/services/class/Utils";
import {PATELLA_REF} from "@app/shared/constant/ref_name.constant";
import {MathUtils} from "three";

@Injectable({
  providedIn: 'root'
})

/**
 * Service used to load and manipulate the patella mortaise
 */
export class PatellaMortaiseService extends Mortaise {

  data = MEASURE_JSON.MORTAISE_MEASURE.patella;
  refAnteriorPosterior= PATELLA_REF.REF.POSTERIOR.value;
  externalRotation = 0;
  posteriorResection = 8;
  anteriorWidth = 10;
  flexionExtension = 0;
  sideModifier= this.dataSymfonyService.getSide()=='right' ? -1 : 1
  private measuredPosteriorResection = 8;
  private measuredAnteriorWidth = 10;
  private measurePatellaFemurCut= 0;

  constructor(dataSymfonyService: DataSymfonyService, manager: ManagerService,
              public materialService: MaterialService) {
    super(manager, dataSymfonyService);
    this.material = this.materialService.createMortaiseMaterial()
    this.name = 'mortaise_patella'
  }

  //TODO : service Position ou ce service
  /**
   * This set the initial position of the patella mortaise (cut = 0.0mm) in the scene called and group the initial points
   * of the patella on it for measures. Then it reset the position to fit to the initial cut parameters (cut = 8.0mm)
   * @param {THREE.scene} scene The scene on which the method is called
   */
  setMortaisePatellaInitialPosition(scene) {
    let mortaise =  Utils.getMeshByName(scene, 'mortaise_patella')

    //set position
    mortaise.position.copy(this.getInitialPosition(scene))

    //set so the plane get orthogonal to patella orthogonal axis
    const initRot = this.getInitialRotation(scene)
    mortaise.rotation.setFromQuaternion( initRot )

    mortaise.updateMatrix()
    this.groupPointOnMortaise(scene)

    this.updatePatellaPosition([mortaise], scene)
  }

  /**
   * This method creates a point : midpoint of the patella projected on the cutting plane
   * @param {THREE.scene} scene The scene on which the method is called
   */
  groupPointOnMortaise(scene) {
    //create a new point
    const geometry = new THREE.SphereGeometry( 7, 32, 16 );
    const material = this.materialService.createPointInvisibleMaterial()
    let pointMid = new THREE.Mesh( geometry, material )

    //set the point in the plane Oxz in the middle of the patella, and on the cutting plane
    const pointInf = Utils.getPositionByObjectName(scene, 'patella_distal')
    const pointSup = Utils.getPositionByObjectName(scene, 'patella_proximal')
    pointMid.position.x = (pointInf.x + (pointSup.x-pointInf.x)/2)
    pointMid.position.y = Utils.getPositionByObjectName(scene, 'patella_posterior').y
    pointMid.position.z = (pointInf.z + (pointSup.z-pointInf.z)/2)
    pointMid.name='patella_mid'
    //scene.add(pointMid)
    Utils.getMeshByName(scene, 'mortaise_patella').attach(pointMid)
  }

  /**
   * This method returns the initial position vector of the patella mortaise
   * @param {THREE.scene} scene The scene on which the method is called
   * @return {THREE.Vector3} The position vector of the mortaise
   */
  getInitialPosition(scene) {
    let vect = new THREE.Vector3()
    vect.x = Utils.getPositionByObjectName(scene,   'patella_anterior').x
    if (this.refAnteriorPosterior==PATELLA_REF.REF.ANTERIOR.value) {
      vect.y = Utils.getPositionByObjectName(scene, PATELLA_REF.REF.ANTERIOR.point).y - this.getPatellaDeltaY()
    }
    else {
      vect.y = Utils.getPositionByObjectName(scene, PATELLA_REF.REF.POSTERIOR.point).y - this.getPatellaDeltaY()
    }
    vect.z= Utils.getPositionByObjectName(scene, 'patella_posterior').z - (this.getPatellaHZ()/2) + this.getPatellaDeltaZ()

    return vect
  }

  //TODO Service position ou dans ce service
  /**
   * This method updates position of the patella cuts and implant
   * @param {THREE.Mesh} mesh A group composed of mesh that will undergo the translations made to the patellacuts :
   * mortaise, implant, points mortaise, points implant
   * @param {THREE.Scene} scene the current scene
   */
  updatePatellaPosition(mesh, scene) {
    const newX = this.getInitialPosition(scene).x
    const newY = this.getInitialPosition(scene).y + ((this.refAnteriorPosterior == PATELLA_REF.REF.ANTERIOR.value) ? -this.getAnteriorWidth() : this.getPosteriorResection());
    const newZ =  this.getInitialPosition(scene).z
    for (let i = 0; i < mesh.length; i = i + 1) {
      mesh[i].position.set(newX, newY, newZ)
    }
  }

  //TODO Service position ou dans ce service
  /**
   * This method updates rotation of the patella cuts and implant
   * @param {THREE.Mesh} mesh A group composed of mesh that will undergo the rotations made to the patella cuts : mortaise,
   * implant, points mortaise, points implant. Each rotation depends on an axis and a rotation point
   * @param {THREE.Scene} scene the current scene
   */
  updatePatellaRotation(mesh, scene) {

    const initRot = this.getInitialRotation(scene)
    const axeX = new THREE.Vector3(1,0,0)
    const axeZ = new THREE.Vector3(0,0,1)

    let pointRotX = Utils.getPositionByObjectName(scene, 'patella_mid')

    for (let i = 0; i < mesh.length; i = i + 1) {
      //reset the initial rotation before applying rotations from the beginning
      mesh[i].rotation.setFromQuaternion( initRot )
      //redefine the rotation point after it has been affected
      pointRotX = Utils.getPositionByObjectName(scene, 'patella_mid')
      Utils.rotateAroundWorldAxis( mesh[i], pointRotX, axeX, MathUtils.degToRad(-this.getFlexionExtension()))
      pointRotX = Utils.getPositionByObjectName(scene, 'patella_mid')
      Utils.rotateAroundWorldAxis( mesh[i], pointRotX, axeZ, MathUtils.degToRad(this.getExternalRotation()))
    }
  }

  /**
   * This method returns the rotation of the patella mortaise, the cut must be parallel to the transverse axis (x axis)
   * @param {THREE.scene} scene The scene on which the method is called
   * @return {THREE.Quaternion} The quaternion describing the rotation of the mortaise
   */
  getInitialRotation(scene) {
    let quaternion = new THREE.Quaternion();
    return quaternion
  }

  /**
   * @return {number} The distance in Y between the center of the reference and the inside face of the mortaise
   */
  getPatellaDeltaY(){
    return this.data.deltaY
  }

  /**
   * @return {number} The distance in Z between the center of the reference and the down side of the mortaise
   */
  getPatellaDeltaZ(){
    return this.data.deltaZ
  }

  /**
   * @return {number} The size of the mortaise along z
   */
  getPatellaHZ(){
    return this.data.hZ
  }

  /**
   * @return {number} The size of the mortaise along y
   */
  getPatellaHY(){
    return this.data.hY
  }

  getPatellaHX () {
    return this.data.hX
  }

  /**
   * @return {number} The value of the external rotation of the patella, set by the user
   */
  getExternalRotation() {
    return this.externalRotation
  }

  /**
   * @return {number} The value of the posterior resection of the patella, set by the user
   */
  getPosteriorResection () {
    return this.posteriorResection
  }

  /**
   * @return {number} The value of the remaining patella width, set by the user
   */
  getAnteriorWidth() {
    return this.anteriorWidth
  }

  /**
   * @return {number} The value of the flexion extension angle of the patella, set by the user
   */
  getFlexionExtension() {
    return this.flexionExtension
  }

  /**
   * @return {number} The calculated value of the posterior resection (delta between midPoint and posterior point)
   */
  getMeasuredPosteriorResection() {
    return this.measuredPosteriorResection
  }

  /**
   * This method sets the new value of the posterior resection (only if the patella is in anterior reference)
   * @param {number} n The calculated value of the posterior resection (delta between midPoint and posterior point)
   */
  setMeasuredPosteriorResection(n: number ) {
    this.measuredPosteriorResection= n
  }

  /**
   * @return {number} The calculated value of the anterior patella width remaining (delta between midPoint and anterior point)
   */
  getMeasuredAnteriorWidth() {
    return this.measuredAnteriorWidth
  }

  /**
   * This method sets the new value of the anterior patella width remaining  (only if the patella is in posterior
   * reference)
   * @param {number} n The calculated value of the anterior patella width remaining  (delta between midPoint and anterior point)
   */
  setMeasuredAnteriorWidth(n: number ) {
    this.measuredAnteriorWidth = n
  }

  /**
   * @return {number} The calculated value between the patella cut and the femur anterior cut
   */
  getMeasurePatellaFemurCut() {
    return this.measurePatellaFemurCut
  }

  /**
   * This method sets the new value between the patella cut and the femur anterior cut
   * @param {number} n The calculated value between the patella cut and the femur anterior cut
   */
  setMeasurePatellaFemurCut(n: number ) {
    this.measurePatellaFemurCut = n
  }
}

import {ElementRef, Injectable, NgZone} from '@angular/core';
import * as THREE from 'three';
import CameraControls from 'camera-controls';
import {SceneService} from "@app/services/three/scene.service";
import {ControlsService} from "@app/services/three/controls.service";
import {CameraService} from "@app/services/three/camera.service";
import {RendererService} from "@app/services/three/renderer.service";
import {LightService} from "@app/services/three/light.service";
import {FemurService} from "@app/services/bone/femur.service";
import {DataSymfonyService} from "@app/api/data-symfony.service";
import {PlanifLoaderService} from "@app/ui/screens/planif/planif-loader.service";
import {Init} from "@app/services/class/Init";
import {ViewerNii} from "@app/services/nii/viewerNii";
import {Utils} from "@app/services/class/Utils";
import {MathUtils, Vector3} from "three";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";
import {PlateService} from "@app/services/implant/plate.service";
import {environment} from "@environments/environment";

declare global {
  interface Window {
    sceneTibiaFrontal: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TibiaViewFrontService {

  canvas: HTMLCanvasElement;
  camera;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  controls;
  clock: THREE.Clock;
  delta;
  axis;
  viewerNii: ViewerNii;

  constructor(private ngZone: NgZone, private sceneService: SceneService, private controlsService: ControlsService,
              private cameraService: CameraService, private rendererService: RendererService,
              private lightService: LightService, private femurService: FemurService,
              private dataSymfonyService: DataSymfonyService, private planifLoaderService: PlanifLoaderService,
              private planifViewManagerService: PlanifViewManagerService, private tibiaMortaiseService: TibiaMortaiseService,
              private planifViewReferencesService: PlanifViewReferencesService, private plateService: PlateService) {
  }

  createScene(canvas: ElementRef<HTMLCanvasElement>) {
    Init.createTibiaFrontScene(this, canvas);
    this.controls.mouseButtons.wheel = CameraControls.ACTION.NONE;
  }

  loadObject() {
    this.planifLoaderService.getSourceViewTibia().subscribe({
      next: value => {
        this.scene.add(...value);
        this.toFrontView();
        this.planifViewReferencesService.displayTibiaMecaAxis(this.scene)
        this.tibiaMortaiseService.setMortaiseTibiaInitialPosition(this.scene)
        this.loadFrontRef()
      }
    })
  }

  loadImplants() {
    this.planifLoaderService.getSourceViewPlate().subscribe({
      next: value => {
        this.scene.add(...value);
        this.plateService.setPlateInitialPosition(this.scene)
        this.planifLoaderService.loadTibiaCut(this.scene)
      }
    })
  }

  loadImages(scope) {
    this.viewerNii = new ViewerNii();
    this.viewerNii.init(this.scene, 'xz').then(el => {
      const {x, y, z} = this.dataSymfonyService.getNiiTranslation();
      const rotation = this.dataSymfonyService.getNiiRotation();
      const pixDimY = this.viewerNii.viewer.header.pixDims[2]
      const pointVect = Utils.getPointPositionByName(this.scene, scope.targetPoint)

      // @ts-ignore
      scope.count = (y+el.y+pointVect.y)/pixDimY

      Utils.getMeshByName(this.scene, 'viewer-nifti').position.set(x, y, z)
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(0,0,1), MathUtils.degToRad(rotation.z))
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(0,1,0), MathUtils.degToRad(rotation.y))
      Utils.rotateAroundWorldAxis(Utils.getMeshByName(this.scene, 'viewer-nifti'), new Vector3(0,0,0), new Vector3(1,0,0), MathUtils.degToRad(rotation.x))

      this.viewerNii.viewer.slice('xz', scope.count)
    })
  }

  scroll(deltaY: number) {
    this.viewerNii.viewer.slice('xz', deltaY);
  }

  /**
   * Loads all the reference points in the front view
   */
  loadFrontRef() {
    this.planifViewReferencesService.displayTibiaPoint(this.scene)
  }

  animate() {
    Init.animate(this);
    window.THREE = THREE;
    if (environment.production === false) {
      window.sceneTibiaFrontal = this.scene;
    }
  }

  public render(): void {
    Init.render(this);
  }

  public resize(): void {
    Init.resize(this);
    this.planifViewManagerService.fitToTibia(this.scene, this.controls);
  }

  toFrontView() {
    const v1 = this.planifViewReferencesService.getTibiaMecaAxis(this.scene)
    this.camera.up.set(v1.x, v1.y, v1.z)
    this.controls.updateCameraUp()
    this.planifViewManagerService.fitToTibia(this.scene, this.controls);
  }

  /**
   * This method displays the tibial resection on the left side of the screen. Depending on the operated knee (right or
   * left), this value will be the internal (left) or external resection (right)
   * @return {number} Tibial resection value of the left side displayed.
   */
  getLeftValue() {
    if (this.tibiaMortaiseService.side == 'right') {
      return Math.round(this.tibiaMortaiseService.getExternalResectionTibial()*10)/10
    } else {
      return Math.round(this.tibiaMortaiseService.getInternalResectionTibial()*10)/10
    }
  }

  /**
   * This method displays the tibial resection on the right side of the screen. Depending on the operated knee (right or
   * left), this value will be the internal (right) or external resection (left)
   * @return {number} Tibial resection value of the right side displayed.
   */
  getRightValue() {
    if (this.tibiaMortaiseService.side == 'right') {
      return Math.round(this.tibiaMortaiseService.getInternalResectionTibial()*10)/10
    } else {
      return Math.round(this.tibiaMortaiseService.getExternalResectionTibial()*10)/10
    }
  }
}

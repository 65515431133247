<div id="pl_panel_right" [class.panel-is-open]=" viewsChoiceService.getPanelIsOpen() ">
  <!--<app-selects-container *ngIf=" !viewsChoiceService.getPanelIsOpen() "></app-selects-container>-->
  <app-selects-container></app-selects-container>
  <div class="femur-notice" [class.not-all]=" viewsChoiceService.getFemurTibias() != 'all' " *ngIf=" viewsChoiceService.getFemurDist() || viewsChoiceService.getFemurFront() || viewsChoiceService.getFemurSag() ">
    <span>Déplacer l’implant</span> <i class="icon icon-two-direction"></i>
  </div>
  <div class="tibia-notice" [class.not-all]=" viewsChoiceService.getFemurTibias() != 'all' " *ngIf=" viewsChoiceService.getTibiaDist() || viewsChoiceService.getTibiaFront() || viewsChoiceService.getTibiaSag() ">
    <span>Déplacer l’implant</span> <i class="icon icon-four-direction"></i>
  </div>
  <div class="diff-tibia-femur" *ngIf=" viewsChoiceService.getFemurTibias() == 'all' ">
    <div>{{ getLeftFlexionMeasure().toFixed( 1 )  }} mm</div>
    <div>{{ getRightFlexionMeasure().toFixed( 1 )  }} mm</div>
    <div>{{ getLeftExtensionMeasure().toFixed( 1 ) }} mm</div>
    <div>{{ getRightExtensionMeasure().toFixed( 1 ) }} mm</div>
  </div>
  <div class="diff-kneecap-one" *ngIf=" viewsChoiceService.getFemurTibias() == 'all' && viewsChoiceService.getPatella() ">
    <div class="value">{{ getAnteriorWidth().toFixed(1)  }} mm</div>
    <div class="diff">{{ getPatellaFemurDif().toFixed(1)  }} mm</div>
    <div class="value">{{ getPosteriorResection().toFixed(1)  }} mm</div>
  </div>
  <div class="diff-kneecap-two" [class.right]=" viewsChoiceService.getSagIsInverted() " *ngIf=" viewsChoiceService.getFemurTibias() == 'all' && viewsChoiceService.getPatella() ">
    <div class="value">{{ getAnteriorWidth().toFixed(1)  }} mm</div>
    <div class="diff">{{ getPatellaFemurDif().toFixed(1)  }} mm</div>
    <div class="value">{{ getPosteriorResection().toFixed(1)  }} mm</div>
  </div>
  <div class="views" [class]=" viewsChoiceService.getFemurTibias() ">
    <app-femur-view-distal *ngIf=" viewsChoiceService.getFemurDist() "></app-femur-view-distal>
    <app-femur-view-front *ngIf=" viewsChoiceService.getFemurFront() "></app-femur-view-front>
    <app-femur-view-sagittal *ngIf=" viewsChoiceService.getFemurSag() "></app-femur-view-sagittal>
    <app-tibia-view-proximal *ngIf=" viewsChoiceService.getTibiaDist() "></app-tibia-view-proximal>
    <app-tibia-view-front *ngIf=" viewsChoiceService.getTibiaFront() "></app-tibia-view-front>
    <app-tibia-view-transversal *ngIf=" viewsChoiceService.getTibiaSag() "></app-tibia-view-transversal>
  </div>
  <app-femur-adjustment *ngIf=" ( viewsChoiceService.getFemurDist() || viewsChoiceService.getFemurFront() || viewsChoiceService.getFemurSag() ) && !viewsChoiceService.getPatella() "></app-femur-adjustment>
  <app-patella-adjustment *ngIf=" ( viewsChoiceService.getFemurDist() || viewsChoiceService.getFemurFront() || viewsChoiceService.getFemurSag() ) && viewsChoiceService.getPatella() "></app-patella-adjustment>
  <app-tibia-adjustment *ngIf=" viewsChoiceService.getTibiaDist() || viewsChoiceService.getTibiaFront() || viewsChoiceService.getTibiaSag() "></app-tibia-adjustment>
  <app-views-choice></app-views-choice>
  <!--panelIsOpen: {{ viewsChoiceService.getPanelIsOpen() }}-->
</div>

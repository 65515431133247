<div id="femur_view_one">
  <canvas #femurViewDistal style="text-align:center;">FEMUR 3</canvas>
  <span class="value" id="f_v_1">{{ this.femurViewDistalService.planifMeasureService.getAngleBetweenEpicondyleSurgAndWhiteside().toFixed( 1 ) }} °</span>
  <span class="value" id="f_v_2">{{ this.femurViewDistalService.planifMeasureService.getAngleBetweenCondyleAndEpicondyleSurg().toFixed( 1 ) }} ° / {{ this.femurViewDistalService.planifMeasureService.getAngleBetweenCondyleAndEpicondyleAnat().toFixed( 1 ) }} ° </span>
  <span class="value" id="f_v_3">{{ this.femurViewDistalService.getLeftValue()}} mm</span>
  <span class="value" id="f_v_4">{{ this.femurViewDistalService.getRightValue()}} mm</span>
  <div class="axe">
    <span>{{this.getLeftSideIndicator()}}</span>
    <span>{{this.getRightSideIndicator()}}</span>
  </div>
  <app-loader></app-loader>
  <!--scanner: {{ viewsChoiceService.getScanner() }}<br>
  patella: {{ viewsChoiceService.getPatella() }}<br>
  cut: {{ viewsChoiceService.getCut() }}<br>
  implant: {{ viewsChoiceService.getImplant() }}<br>
  rotationExterne: {{ femurAdjustmentService.getRotationExterne() }}<br>
  resectionPosterieure: {{ femurAdjustmentService.getResectionPosterieure() }}<br>
  varusValgus: {{ femurAdjustmentService.getVarusValgus() }}<br>
  resectionDistable: {{ femurAdjustmentService.getResectionDistable() }}<br>
  flexionExtension: {{ femurAdjustmentService.getFlexionExtension() }}<br>-->
</div>

import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { FemurViewDistalService} from "@app/ui/screens/planif/pl-panel-right/femur-view-distal/femur-view-distal.service";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {PlanifViewManagerService} from "@app/ui/screens/planif/planif-view-manager.service";

/**
 * Component that defines the distal view (n°1) of the femur
 */

@Component({
  selector: 'app-femur-view-distal',
  templateUrl: './femur-view-distal.component.html',
  styleUrls: ['./femur-view-distal.component.scss']
})
export class FemurViewDistalComponent implements OnInit {

  @ViewChild('femurViewDistal', {static: true})
  public femurViewDistal: ElementRef<HTMLCanvasElement>;
  count = 0;
  value1 = 92.5
  value2 = 5.0

  targetPoint= 'femur_mid';


  constructor(public femurViewDistalService: FemurViewDistalService, private femurMortaiseService: FemurMortaiseService) {
  }

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    console.log(event);
    if (event.deltaY > 0) {
      this.count++
    } else {
      this.count--;
    }
    console.log(this.count);
    this.femurViewDistalService.scroll(this.count);
  }

  ngOnInit(): void {
    this.femurViewDistalService.createScene(this.femurViewDistal);
    this.femurViewDistalService.loadObject();
    this.femurViewDistalService.loadImplants();
    //this.femurViewDistalService.loadImages(this);
    this.femurViewDistalService.animate();
  }

  ngOnChanges(): void {
    this.femurViewDistalService.resize();
  }

  /**
   * This method indicates where the lateral and medial sides are, which depends on the operated knee side. The medial
   * side is displayed on the left for a left knee, on the right for a right knee
   * @return {string} Returns 'L' if the case is a right knee, 'M' otherwise.
   */
  getLeftSideIndicator() {
    if (this.femurMortaiseService.side == 'right') {
      return 'L'
    }
    else {
      return 'M'
    }
  }

  /**
   * This method indicates where the lateral and medial sides are, which depends on the operated knee side
   * @return {string} Returns 'M' if the case is a right knee, 'L' otherwise.
   */
  getRightSideIndicator() {
    if (this.femurMortaiseService.side == 'right') {
      return 'M'
    }
    else {
      return 'L'
    }
  }

}

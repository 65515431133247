import {Utils} from "@app/services/class/Utils";

export const ELEMENTS_FEMUR = ['femur_mid',
  'femur_shaft',
  'condyle_anterior_external',
  'condyle_anterior_internal',
  'condyle_distal_external',
  'condyle_distal_internal',
  'condyle_posterior_external',
  'condyle_posterior_internal',
  'cortical_anterior',
  'trochlea_base',
  'epicondyle_anatomical_internal',
  'epicondyle_surgical_internal',
  'epicondyle_external',
  'hip_head',
  'trochlea_base_concavity',
  'notch_posterior_concavity'];

export const ELEMENTS_FEMUR_2_DISPLAY = [
  'femur_mid',
  'condyle_distal_external',
  'condyle_distal_internal',
  'condyle_posterior_external',
  'condyle_posterior_internal',
  'epicondyle_anatomical_internal',
  'epicondyle_surgical_internal',
  'epicondyle_external',
  'cortical_anterior'
]

export const ELEMENTS_FEMUR_EPIPHYSIS = [
  'femur_mid',
  'condyle_anterior_external',
  'condyle_anterior_internal',
  'condyle_distal_external',
  'condyle_distal_internal',
  'condyle_posterior_external',
  'condyle_posterior_internal',
  'cortical_anterior',
  'trochlea_base',
  'epicondyle_anatomical_internal',
  'epicondyle_surgical_internal',
  'epicondyle_external',
  'trochlea_base_concavity',
  'notch_posterior_concavity'];

export const FEMUR_COLOR = 0x920e6f


import {Injectable} from '@angular/core';
import * as THREE from 'three';
import {OrthographicCamera} from "three";

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor() {
  }

  createAnonymous(canvas: HTMLCanvasElement): THREE.OrthographicCamera {
    const camera = new THREE.OrthographicCamera(
      canvas.offsetWidth / -2,
      canvas.offsetWidth / 2,
      canvas.offsetHeight / 2,
      canvas.offsetHeight / -2,
      -500,
      1000
    );
    camera.position.set(0, 2, 0);
    camera.up.set(0, 0, 1);
    return camera;
  }

  createIdentity(): OrthographicCamera {
    return new THREE.OrthographicCamera(
      -1, // left
      1, // right
      1, // top
      -1, // bottom
      -1, // near,
      1 // far
    );
  }

  createCameraMeasure(canvas) {
    const camera = new THREE.OrthographicCamera(
      canvas.nativeElement.offsetWidth / -2,
      canvas.nativeElement.offsetWidth / 2,
      canvas.nativeElement.offsetHeight / 2,
      canvas.nativeElement.offsetHeight / -2,
      1,
      1000
    );
    camera.position.set(0, 200, 0);
    camera.up.set(0, 0, 1);
    return camera;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectPatellaService {

  constructor() { }

  private refList = [
    {
      value: "anterior",
      display: "Antérieure"
    },
    {
      value: "posterior",
      display: "Postérieure"
    }
  ];

  private ref = {
    value: "posterior",
    display: "Postérieure"
  };

  /**
   * method that returns the value of ref. ref design the referential (anterior/posterior)
   */
  getRef() {
    return this.ref;
  }

  /**
   * method that returns the list of referential : anterior and posterior
   */
  getRefList() {
    return this.refList;
  }

  private sizeList = [
    {
      value: "t1",
      display: "T1"
    },
    {
      value: "t2",
      display: "T2"
    },
    {
      value: "t3",
      display: "T3"
    },
    {
      value: "t4",
      display: "T4"
    },
    {
      value: "t5",
      display: "T5"
    },
    {
      value: "t6",
      display: "T6"
    },
    {
      value: "t7",
      display: "T7"
    },
    {
      value: "t8",
      display: "T8"
    }
  ];

  private size = {
    value: "t1",
    display: "T1"
  };

  /**
   * method that returns the value of size. size reprents the size of the femoral implant
   */
  getSize() {
    return this.size;
  }

  /**
   * method that returns the list of sizes available for the femoral implant.
   */
  getSizeList() {
    return this.sizeList;
  }
}

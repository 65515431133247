import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {TibiaViewFrontService} from "@app/ui/screens/planif/pl-panel-right/tibia-view-front/tibia-view-front.service";

/**
 * Component that defines the front view (n°2) of the tibia
 */

@Component({
  selector: 'app-tibia-view-front',
  templateUrl: './tibia-view-front.component.html',
  styleUrls: ['./tibia-view-front.component.scss']
})
export class TibiaViewFrontComponent implements OnInit {

  @ViewChild('tibiaViewFront', {static: true})
  public tibiaViewFront: ElementRef<HTMLCanvasElement>;
  count = 0;
  targetPoint = 'tibia_spine_center'

  constructor(public tibiaViewFrontService: TibiaViewFrontService) {
  }

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    console.log(event);
    if (event.deltaY > 0) {
      this.count++
    } else {
      this.count--;
    }
    console.log(this.count);
    this.tibiaViewFrontService.scroll(this.count);
  }

  ngOnInit(): void {
    this.tibiaViewFrontService.createScene(this.tibiaViewFront);
    this.tibiaViewFrontService.loadObject();
    this.tibiaViewFrontService.loadImplants();
    //this.tibiaViewFrontService.loadImages(this);
    this.tibiaViewFrontService.animate();
  }

  ngOnChanges(): void {
    this.tibiaViewFrontService.resize();

  }

}

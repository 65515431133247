import { Injectable } from '@angular/core';
import {MathUtils} from "three";
import {PlanifViewReferencesService} from "@app/ui/screens/planif/planif-view-references.service";
import * as THREE from "three";
import {PatellaMortaiseService} from "@app/services/bone/patella-mortaise.service";
import {TibiaMortaiseService} from "@app/services/bone/tibia-mortaise.service";
import {PATELLA_REF} from "@app/shared/constant/ref_name.constant";
import {FemurMortaiseService} from "@app/services/bone/femur-mortaise.service";
import {TibiaService} from "@app/services/bone/tibia.service";

@Injectable({
  providedIn: 'root'
})

/**
 * Service service used to perform dynamic measurements of the different bone sections, as well as to store the
 * measurements of the different angles
 */
export class PlanifMeasuresService {

  private angleCondEpiAnat = 0;
  private angleCondEpiSurg = 0;
  private angleEpiSurgWhiteside = 0;

  constructor(private planifViewReferencesService: PlanifViewReferencesService, private patellaMortaiseService: PatellaMortaiseService,
              private tibiaMortaiseService: TibiaMortaiseService, private femurMortaiseService: FemurMortaiseService,
              private tibiaService: TibiaService) { }

  /**
   * Set the value of the angle between the condyle and epicondyle anatomical axis
   */
  setAngleBetweenCondyleAndEpicondyleAnat() {
    const condAxis = this.planifViewReferencesService.getCondylePosteriorAxis()
    const epicondAnatAxis = this.planifViewReferencesService.getEpicondyleAnatomicalAxis()
    this.angleCondEpiAnat = MathUtils.radToDeg(condAxis.angleTo(epicondAnatAxis))
  }

  /**
   * @return {number} the value of the angle between the condyle and epicondyle anatomical axis that will be display
   * on the planif page
   */
  getAngleBetweenCondyleAndEpicondyleAnat() {
    return this.angleCondEpiAnat
  }

  /**
   *Set the value of the angle between the condyle  and epicondyle surgical axis
   */
  setAngleBetweenCondyleAndEpicondyleSurg() {
    const condAxis = this.planifViewReferencesService.getCondylePosteriorAxis()
    const epicondSurgAxis = this.planifViewReferencesService.getEpicondyleSurgicalAxis()
    this.angleCondEpiSurg = MathUtils.radToDeg(condAxis.angleTo(epicondSurgAxis)) ;
  }

  /**
   * @return {number} the value of the angle between the condyle  and epicondyle surgical axis that will be display
   * on the planif page
   */
  getAngleBetweenCondyleAndEpicondyleSurg() {
    return this.angleCondEpiSurg
  }

  /**
   * Set the value of the angle between the epicondyle surgical and the whiteside axis to display it on the planif page
   */
  setAngleBetweenEpicondyleSurgAndWhiteside() {
    const epicondSurgAxis = this.planifViewReferencesService.getEpicondyleSurgicalAxis()
    const whitesideAxis = this.planifViewReferencesService.getAPWhitesideAxis()
    this.angleEpiSurgWhiteside = MathUtils.radToDeg(epicondSurgAxis.angleTo(whitesideAxis))
  }

  /**
   * @return {number} the value of the angle between the epicondyle surgical and the whiteside axis that will be display
   * on the planif page
   */
  getAngleBetweenEpicondyleSurgAndWhiteside() {
    return this.angleEpiSurgWhiteside
  }

  /**
   * Update the distal cuts measure, saved in the mortaise service
   * @param {THREE.Vector3} pointIntMortaise the copy of the internal condyle distal point, moving with the cuts
   * @param {THREE.Vector3} pointIntInitial the initial internal condyle distal point, will be compared with the copy on
   * the cut
   * @param {THREE.Vector3} pointExtMortaise the copy of the external condyle distal point, moving with the cuts
   * @param {THREE.Vector3} pointExtInital the initial external condyle distal point, will be compared with the copy
   * on the cut
   */
  updateFemurResectionDistal(pointIntMortaise, pointIntInitial, pointExtMortaise, pointExtInital) {
    const measureInterneDistal = pointIntMortaise.z-pointIntInitial.z
    this.femurMortaiseService.setInternalResectionDistal(measureInterneDistal)
    const measureExterneDistal = pointExtMortaise.z-pointExtInital.z
    this.femurMortaiseService.setExternalResectionDistal(measureExterneDistal)
  }

  /**
   * Update the posterior cuts measure, saved in the mortaise service
   * @param {THREE.Vector3} pointIntMortaise the copy of the internal condyle posterior point, moving with the cuts
   * @param {THREE.Vector3} pointIntInitial the initial internal condyle posterior point, will be compared with the
   * copy on the cut
   * @param {THREE.Vector3} pointExtMortaise the copy of the external condyle posterior point, moving with the cuts
   * @param {THREE.Vector3} pointExtInital the initial external condyle posterior point, will be compared with the copy
   * on the cut
   */
  updateFemurResectionPosterior(pointIntMortaise, pointIntInitial, pointExtMortaise, pointExtInital) {
    const measureInternePosterior = pointIntMortaise.y-pointIntInitial.y
    this.femurMortaiseService.setInternalResectionPosterior(measureInternePosterior)
    const measureExternePosterior = pointExtMortaise.y-pointExtInital.y
    this.femurMortaiseService.setExternalResectionPosterior(measureExternePosterior)
  }

  /**
   * Update the patella cuts measure, saved in the mortaise service
   * @param {THREE.Vector3} pointPatellaMid the point in the middle of the patella cut, moving with the cuts
   * @param {THREE.Vector3} pointPatellaRefAnterior the most anterior point, used to calculate the remaining anterior
   * width of the patella
   * @param {THREE.Vector3} pointPatellaRefPosterior the most posterior point, used to calculate the posterior resection
   */
  updatePatellaResection(pointPatellaMid, pointPatellaRefAnterior, pointPatellaRefPosterior) {
    if (this.patellaMortaiseService.refAnteriorPosterior===PATELLA_REF.REF.ANTERIOR.value) {
      const measurePosterior = pointPatellaMid.y-pointPatellaRefPosterior.y
      this.patellaMortaiseService.setMeasuredPosteriorResection(measurePosterior)
      this.patellaMortaiseService.setMeasuredAnteriorWidth(this.patellaMortaiseService.getAnteriorWidth())
    }
    else {
      const measureAnterior = pointPatellaRefAnterior.y-pointPatellaMid.y
      this.patellaMortaiseService.setMeasuredAnteriorWidth(measureAnterior)
      this.patellaMortaiseService.setMeasuredPosteriorResection(this.patellaMortaiseService.getPosteriorResection())
    }
  }

  /**
   * Update the tibia resection measure, saved in the mortaise service
   * @param {THREE.Vector3} pointIntMortaise the copy of the internal cup base point, moving with the cuts
   * @param {THREE.Vector3} pointIntInitial the initial internal cup base point, will be compared with the copy on the cut
   * @param {THREE.Vector3} pointExtMortaise the copy of the external cup base point, moving with the cuts
   * @param {THREE.Vector3} pointExtInital the initial external cup base point, will be compared with the copy on the cut
   */
  updateTibiaResection (pointIntMortaise, pointIntInitial, pointExtMortaise, pointExtInital) {
    const measureInterne = this.tibiaService.measureInTibiaWorldZ(pointIntMortaise, pointIntInitial)
    this.tibiaMortaiseService.setInternalResectionTibial(measureInterne)
    const measureExterne = this.tibiaService.measureInTibiaWorldZ(pointExtMortaise, pointExtInital)
    this.tibiaMortaiseService.setExternalResectionTibial(measureExterne)
  }

  /**
   * Measure the distance between the patella cut and the femoral anterior cut. To do so, the method converts the anterior
   * plane (attached to the femoral mortaise into a mathematical plane), in order to project the center of the patella
   * onto this plane.
   * The distance between the point and its projection is then calculated.
   * @param {THREE.Mesh} femurCutPlan the anterior cut plane of the femur
   * @param {THREE.Vector3} femurCutPoint the position of the anterior cut plane of the femur
   * @param {THREE.Vector3} patellaCutPoint the position of the patella mid point
   */
  updateMeasurePatellaFemurCut(femurCutPlan: THREE.Mesh, femurCutPoint: THREE.Vector3, patellaCutPoint: THREE.Vector3) {
    const femurPlan = new THREE.Plane();
    const femurPlanNormal = new THREE.Vector3();
    const femurPlanPoint = new THREE.Vector3();

    femurPlanNormal.set( 0, 0, 1 ).applyQuaternion( femurCutPlan.quaternion );

    femurPlanPoint.copy( femurCutPoint );

    femurPlan.setFromNormalAndCoplanarPoint( femurPlanNormal, femurPlanPoint );

    const patellaPointProjection = new THREE.Vector3()
    femurPlan.projectPoint(patellaCutPoint, patellaPointProjection )
    const measure = patellaPointProjection.distanceTo(patellaCutPoint)
    this.patellaMortaiseService.setMeasurePatellaFemurCut(measure)
  }

}

import {Injectable} from '@angular/core';
import CameraControls from 'camera-controls';
import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
@Injectable({
  providedIn: 'root'
})
export class ControlsService {
  constructor() {
  }

  createAnonymousCameraControl(camera: THREE.PerspectiveCamera | THREE.OrthographicCamera, renderer) {
    CameraControls.install({THREE});
    return  new CameraControls(camera, renderer.domElement);
  }
}

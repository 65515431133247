import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {PreopComponent} from "./preop.component";
import {PoMeasureComponent} from "./po-measure/po-measure.component";
import {PoToggleTooltipsComponent} from "./po-toggle-tooltips/po-toggle-tooltips.component";
import {PoViewFrontComponent} from "./po-view-front/po-view-front.component";
import {PoViewLateralComponent} from "./po-view-lateral/po-view-lateral.component";
import {PoWarningComponent} from "./po-warning/po-warning.component";

@NgModule({
  declarations: [PreopComponent, PoMeasureComponent, PoToggleTooltipsComponent, PoViewLateralComponent, PoViewFrontComponent, PoWarningComponent],
  imports: [
    CommonModule
  ]
})
export class PreopModule { }

import {FrontSide, LessEqualDepth, MathUtils, Mesh, Quaternion, Scene, Vector3} from 'three';
import {PositionUtil} from '@masatomakino/threejs-position-util';
import * as THREE from 'three';

export class Utils {

  static getPositionByObjectName(scene: Scene, name: string): Vector3 {
    return PositionUtil.getGeometryCenterInWorld(scene.getObjectByName(name) as Mesh)
  }

  static setRotationAroundPoint(obj, point, axis, theta){
    obj.position.sub(point); // remove the offset
    obj.position.applyAxisAngle(axis, theta);; // rotate the POSITION
    obj.position.add(point); // re-add the offset
    obj.setRotationFromAxisAngle(axis, theta); // rotate the OBJECT
  }

  //imported

  static cloneObjectChildrenAndMaterial(mesh) {
    const meshClone = mesh.clone();
    meshClone.material = mesh.material.clone();
    meshClone.add(mesh.children[0]);
    meshClone.children[0].material = mesh.children[0].material.clone();
    return meshClone;
  }

  static setTransparencyIntersection(scene, name) {
    // @ts-ignore
    Utils.getMeshByName(scene, name).material.depthWrite = false;
    // @ts-ignore
    Utils.getMeshByName(scene, name).material.opacity = 0.6;
    // @ts-ignore
    Utils.getMeshByName(scene, name).material.side = FrontSide;
  }

  static getDistanceByObjectName(a: string, b: string, scene: Scene): number {
    return Utils.getPositionByObjectName(scene, a).distanceTo(Utils.getPositionByObjectName(scene, b));
  }

  static getDistanceXByObjectName(a: string, b: string, scene: Scene): number {
    return Math.abs(Utils.getPositionByObjectName(scene, a).clone().sub(Utils.getPositionByObjectName(scene, b)).x);
  }

  static getMeshByName(scene: Scene, name: string): Mesh {
    return scene.getObjectByName(name) as Mesh;
  }

  static getPointPositionByName(scene: Scene, name: string): Vector3 {
    return Utils.getMeshByName(scene, name).geometry.boundingSphere.center
  }
  /**
   *  alpha = atan a / b
   */
  static getAtan(a: number, b: number): number {
    return MathUtils.radToDeg(Math.atan(a / b));
  }

  static setAxisFromAngleAndCoplanarPoint(angle: number, point: Vector3, material) {
    const geometry = new THREE.CylinderBufferGeometry(0.2, 0.2, 600);
    const plane = new THREE.Mesh(geometry, material);
    plane.position.copy(point);
    plane.rotation.set(0, -angle, Math.PI / 2);
    plane.name = 'parralel';
    return plane;
  }

  /**
   *
   * @param mesh
   * @param point
   * @param axis
   * @param angle
   */
  static rotateAroundWorldAxis(mesh, point, axis, angle) {
    const q = new Quaternion();
    q.setFromAxisAngle(axis, angle);
    mesh.applyQuaternion(q);
    mesh.position.sub(point);
    mesh.position.applyQuaternion(q);
    mesh.position.add(point);
    return mesh;
  }



  static rotateAround(mesh, point, euler) {
    this.rotateAroundWorldAxis(mesh, point, new Vector3(1, 0, 0), euler.x);
    this.rotateAroundWorldAxis(mesh, point, new Vector3(0, 1, 0), euler.y);
    return this.rotateAroundWorldAxis(mesh, point, new Vector3(0, 0, 1), euler.z);
  }

  static rotateAroundWorldAxisFromEuler(mesh, point, euler) {
    const q = new Quaternion();
    q.setFromEuler(euler);
    mesh.applyQuaternion(q);
    mesh.position.sub(point);
    mesh.position.applyQuaternion(q);
    mesh.position.add(point);
    return mesh;
  }

  static display(count, toClass, name, scene) {
    switch (count) {
      case 1 :
        toClass = 'transparency';
        Utils.getMeshByName(scene, name).visible = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.transparent = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.opacity = 0.28;
        // @ts-ignore
        if (name !== 'plan') Utils.getMeshByName(scene, name).material.depthTest = false;
        // @ts-ignore
        if (name === 'Cup') Utils.getMeshByName(scene, name).renderOrder = 65;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.depthWrite = false;
        // @ts-ignore
        break;
      case 2:
        toClass = 'noVisible';
        Utils.getMeshByName(scene, name).visible = false;
        break;
      case 3:
        toClass = 'visible';
        Utils.getMeshByName(scene, name).visible = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.visible = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.opacity = 1;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.depthFunc = LessEqualDepth;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.depthTest = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.depthWrite = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.transparent = false;
        break;
    }
    return toClass;
  }

  static displayIntersection(count, toClass, name, scene) {
    switch (count) {
      case 1 :
        toClass = 'transparency';
        Utils.getMeshByName(scene, name).visible = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.transparent = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.opacity = 0.6;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.depthWrite = false;
        // @ts-ignore
        Utils.getMeshByName(scene, name).children[0].visble = true;
        break;
      case 2:
        toClass = 'noVisible';
        // @ts-ignore
        Utils.getMeshByName(scene, name).visible = false;
        break;
      case 3:
        toClass = 'visible';
        Utils.getMeshByName(scene, name).visible = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.transparent = false;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.visible = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.opacity = 1;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.depthFunc = LessEqualDepth;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.depthTest = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.depthWrite = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).children[0].visble = false;
        break;
    }
    return toClass;
  }

  static displayShader(count, toClass, name, scene) {
    switch (count) {
      case 1 :
        toClass = 'transparency';
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.side = FrontSide;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.transparent = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.opacity = 0.6;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.depthWrite = false;
        break;
      case 2:
        toClass = 'noVisible';
        // @ts-ignore
        Utils.getMeshByName(scene, `${name}_clone`).visible = false;
        // @ts-ignore
        Utils.getMeshByName(scene, name).visible = false;
        break;
      case 3:
        toClass = 'visible';
        // @ts-ignore
        Utils.getMeshByName(scene, `${name}_clone`).visible = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).visible = true;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.transparent = false;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.opacity = 1;
        // @ts-ignore
        Utils.getMeshByName(scene, name).material.depthWrite = true;
        break;
    }
    return toClass;
  }

  static cloneOriginalCup(scene: Scene, isMaterial: boolean, geometry): Mesh {
    const cupOriginal = this.getMeshByName(scene, 'Cup');
    let material;
    if (isMaterial) {
      material = new THREE.MeshStandardMaterial({
        color: 0x048b9a,
        clipIntersection: true,
        transparent: false,
        clippingPlanes: [],
      })
    } else {
      // @ts-ignore
      material = cupOriginal.material.clone();
    }
    const cup = new Mesh(geometry, material);
    cup.name = 'Cup'
    cup.position.copy(cupOriginal.position);
    cup.rotation.copy(cupOriginal.rotation);
    return cup;
  }

  static cloneOriginalStem(scene: Scene, isMaterial: boolean, geometry): Mesh {
    console.warn('Warning application stem not load', scene.getObjectByName('Stem'))
    const stemOriginal = scene.getObjectByName('Stem');
    let material;
    if (isMaterial) {
      material = new THREE.MeshStandardMaterial({
        color: 0x048b9a,
        clipIntersection: true,
        transparent: false,
        clippingPlanes: [],
      });
      material.name = 'Stem';
    } else {
      // @ts-ignore
      material = stemOriginal.material.clone();
    }
    const stem = new Mesh(geometry, material);
    stem.name = 'Stem'
    stem.position.copy(stemOriginal.position);
    stem.rotation.copy(stemOriginal.rotation);
    stem.renderOrder = 1;
    return stem;
  }

  static cloneOriginalStemCenter(scene: Scene, stem): Mesh {
    const stemCenterOriginal = scene.getObjectByName('stem-center-clone').clone();
    return stem.add(stemCenterOriginal);
  }

  static updateOriginalStemCenter(scene: Scene, sceneToRemove: Scene, stem): Mesh {
    stem.clear();
    return this.cloneOriginalStemCenter(scene, stem);
  }

  /**
   * Delete Abs( P1 - P2 ) Pour signer les valeurs
   * M2 : Si le plan de coupe se trouve au dessus du petit trochanter on signe la valeur avec + sinon -
   * M4 : Si le centre de la tête prothétique est en dessous de la droite passant par le grand troch et perpendiculaire sur l’axe diaphysaire on signe la valeur avec un + sinon -
   * @param vecteurA
   * @param vecteurB
   * @param angle
   */
  static getPerpendicularDistance(vecteurA, vecteurB, angle) {
    const m = Math.tan(angle)
    let p1 = vecteurA.z - m * vecteurA.x;
    let p2 = vecteurB.z - m * vecteurB.x;
    const distance = (p1 - p2) / Math.sqrt(1 + Math.pow(m, 2));
    return distance;
  }

  /**
   *
   * @param nameA
   * @param nameB
   */
  static getDistanceM3ByName(nameA: Mesh, nameB: Mesh) {
    return PositionUtil.getGeometryCenterInWorld(nameA)
      .sub(nameB.position).z;
  }

  static createPlane(a, b, c) {
    const plane = new THREE.Plane();
    plane.setFromCoplanarPoints(a, b, c);
    const helper = new THREE.PlaneHelper(plane, 100, 0xff0000);
    helper.updateMatrixWorld(true);
    const geometry = new THREE.PlaneGeometry(300, 300, 32);
    const material = new THREE.MeshBasicMaterial({
      color: 0xff0000,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.8
    });
    const planeMesh = new THREE.Mesh(geometry, material);
    planeMesh.name = 'plan';
    planeMesh.position.copy(a);
    planeMesh.rotation.copy(helper.rotation);
    return planeMesh;
  }

  static getPlaneFrom(a, b, c) {
    const plane = new THREE.Plane();
    plane.setFromCoplanarPoints(a, b, c);
    return plane;
  }

  static alwaysRender(mesh) {
    mesh.renderOrder = 999;
    // @ts-ignore
    mesh.material.depthTest = false;
    // @ts-ignore
    mesh.material.depthWrite = false;
    mesh.onBeforeRender = function (renderer) {
      renderer.clearDepth();
    };
  }
}
